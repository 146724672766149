import React from 'react';
import {
  Button,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Col, Row } from 'reactstrap';
import styles from '../../styles/UpdateAvailability.module.css';

const AddAvailability = ({
  open,
  handleClose,
  selectedDuration,
  handleChangeDuration,
  durations,
  MenuProps,
  type,
  selectedStartDate,
  setSelectedStartDate,
  setSelectedHoldCreator,
  setHoldForClient,
  setHoldCreationDate,
  setHoldExpirationDateTime,
  setType,
  service,
  setService,
  setting,
  handleChangeSetting,
  selectedLocation,
  handleChangeLocation,
  selectedFrequency,
  holdCreationDate,
  holdExpirationDateTime,
  holdForClient,
  setOpen,
  handleChangeFrequency,
  locations,
  frequencies,
  note,
  setNote,
  selectedHoldCreator,
  setShowCustomAlert,
  shouldShowAddButton,
  handleAddAvailability,
  holdCreators,
  showCustomAlert,
  // The below are the two states for manging testingFloaterType
  testingFloaterType,
  setTestingFloaterType,
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalBox}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Availability Details
          </Typography>

          <FormControl sx={{ width: 320, margin: 2 }}>
            <InputLabel id="demo-simple-select-label">Duration</InputLabel>

            <Select
              labelId="demo-multiple-checkbox-label"
              name="frequency"
              value={selectedDuration}
              onChange={handleChangeDuration}
              input={<OutlinedInput label="duration" />}
              renderValue={(selected) =>
                selected?.map((v) => durations[v]).join(', ')
              }
              MenuProps={MenuProps}
            >
              {Object.keys(durations).map((key, index) => (
                <MenuItem key={index} value={key}>
                  <Checkbox checked={selectedDuration.indexOf(key) > -1} />
                  <ListItemText primary={durations[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: 320,
              margin: 2,
            }}
          >
            <div className="form-group">
              <input
                type="datetime-local"
                className="form-control"
                name="appointmentDate"
                value={selectedStartDate}
                onChange={(event) => setSelectedStartDate(event.target.value)}
                style={{
                  border: selectedStartDate
                    ? '1px solid #ced4da'
                    : '1px solid red',
                  borderRadius: '4px',
                  padding: '8px',
                }}
              />
            </div>
          </FormControl>

          <FormControl sx={{ width: 320, margin: 4 }}>
            <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
            <RadioGroup
              row
              name="type"
              value={type}
              onChange={(e) => {
                if (e.target.value === 'Open') {
                  setSelectedHoldCreator('');
                  setHoldForClient('');
                  setHoldCreationDate(null);
                  setHoldExpirationDateTime(null);
                }
                setType(e.target.value);
              }}
              // renderValue={(selected) => selected.join(', ')}
            >
              {['Open', 'Hold'].map((s, index) => (
                <FormControlLabel
                  value={s}
                  control={<Radio />}
                  label={s}
                  key={index}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <FormControl sx={{ width: 320, margin: 2 }}>
            <InputLabel id="demo-multiple-checkbox-label">Service</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              name="location"
              value={service}
              onChange={(e) => setService(e.target.value)}
              input={<OutlinedInput label="apptTypes" />}
              // renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {['Therapy', 'Testing'].map((s, index) => (
                <MenuItem value={s} key={index}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: 320,
              margin: 2,
              border: setting ? '1px solid #ced4da' : '1px solid red',
              borderRadius: '4px',
            }}
          >
            <InputLabel id="demo-multiple-checkbox-label">Setting</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              name="Setting"
              value={setting}
              onChange={handleChangeSetting}
              input={<OutlinedInput label="apptTypes" />}
              // renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                (s, index) => (
                  <MenuItem value={s} key={index}>
                    {s}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          {setting && setting !== 'Remote Only' && (
            <FormControl
              sx={{
                width: 320,
                margin: 2,
                border:
                  selectedLocation.length > 0
                    ? '1px solid #ced4da'
                    : '1px solid red',
                borderRadius: '4px',
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Location
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                name="location"
                value={selectedLocation}
                onChange={handleChangeLocation}
                input={<OutlinedInput label="apptTypes" />}
                // renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {locations.map((masterObj, index) => (
                  <MenuItem key={index} value={masterObj}>
                    <Checkbox
                      checked={selectedLocation.indexOf(masterObj) > -1}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
              </Select>
              {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
            </FormControl>
          )}

          <FormControl
            sx={{
              width: 320,
              margin: 2,
              border:
                selectedFrequency.length > 0
                  ? '1px solid #ced4da'
                  : '1px solid red',
              borderRadius: '4px',
            }}
          >
            <InputLabel id="demo-multiple-checkbox-label">Frequency</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              name="frequency"
              value={selectedFrequency}
              onChange={handleChangeFrequency}
              input={<OutlinedInput label="frequency" />}
              MenuProps={MenuProps}
            >
              {Object.keys(frequencies).map((key, index) => (
                <MenuItem key={key + index} value={key}>
                  <Checkbox checked={selectedFrequency.indexOf(key) > -1} />
                  <ListItemText primary={frequencies[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* The below Option is getting added for dealing with Testing
           Floater Weekly/ Every other week. And this gets appeared when
           serviceType is Testing and We have selected Floater
           */}
          {service === 'Testing' && selectedFrequency.includes('floater') && (
            <FormControl sx={{ width: 320, margin: 2 }}>
              <FormLabel component="legend">Select an Option</FormLabel>
              <RadioGroup
                aria-label="floater-options"
                value={testingFloaterType}
                /*
                Setting the values here backend will be receiving
                floater-weekly or floater-every-other-week
                */
                onChange={(e) => setTestingFloaterType(e.target.value)}
              >
                <FormControlLabel
                  value={'floater_regular'} // These are the values that are going to be sent to BE
                  control={
                    <Radio
                      // The below sx prop for changing the color if it is not filled
                      sx={{
                        color: testingFloaterType ? 'default' : 'red',
                        '&.Mui-checked': {
                          color: testingFloaterType ? 'primary.main' : 'red',
                        },
                      }}
                    />
                  }
                  label="Floater - 1 Time"
                />
                <FormControlLabel
                  value="floater_weekly" // These are the values that are going to be sent to BE
                  control={
                    <Radio
                      // The below sx prop for changing the color if it is not filled
                      sx={{
                        color: testingFloaterType ? 'default' : 'red',
                        '&.Mui-checked': {
                          color: testingFloaterType ? 'primary.main' : 'red',
                        },
                      }}
                    />
                  }
                  label="Floater - Weekly"
                />
                <FormControlLabel
                  value="floater_every_other_week" // These are the values that are going to be sent to BE
                  control={
                    <Radio
                      // The below sx prop for changing the color if it is not filled
                      sx={{
                        color: testingFloaterType ? 'default' : 'red',
                        '&.Mui-checked': {
                          color: testingFloaterType ? 'primary.main' : 'red',
                        },
                      }}
                    />
                  }
                  label="Floater - Every Other Week"
                />
              </RadioGroup>
            </FormControl>
          )}

          <FormControl
            sx={{ width: 320, margin: 2 }}
            // error={formValues['insurances'].length>0?false:true}
          >
            <TextField
              // required
              autoComplete="off"
              fullWidth
              variant="outlined"
              id="note"
              label="Note"
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </FormControl>

          {type === 'Hold' && (
            <div>
              <FormControl
                sx={{
                  width: 320,
                  margin: 2,
                  border:
                    selectedHoldCreator?.length === 0
                      ? '1px solid red'
                      : '1px solid #ced4da',
                  borderRadius: '4px',
                  '&:hover': {
                    border: '1px solid #ced4da',
                  },
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Person Creating Hold
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="holdCreators"
                  value={selectedHoldCreator}
                  onChange={(e) => setSelectedHoldCreator(e.target.value)}
                  input={<OutlinedInput label="HoldCreators" />}
                  MenuProps={MenuProps}
                >
                  {holdCreators.map((obj, index) => (
                    <MenuItem key={index} value={obj.care_coordinator_name}>
                      <Checkbox
                        checked={
                          selectedHoldCreator?.indexOf(
                            obj.care_coordinator_name
                          ) > -1
                        }
                      />
                      <ListItemText primary={obj.care_coordinator_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  width: 320,
                  margin: 2,
                  border:
                    holdForClient?.length === 0
                      ? '1px solid red'
                      : '1px solid #ced4da',
                  borderRadius: '4px',
                  '&:hover': {
                    border: '1px solid #ced4da',
                  },
                }}
              >
                <TextField
                  required
                  autoComplete="off"
                  placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                  fullWidth
                  variant="outlined"
                  id="Initials-of-Client"
                  label="Initials of Client"
                  onChange={(event) => {
                    setHoldForClient(event.target.value);
                  }}
                  value={holdForClient}
                  InputProps={{
                    inputProps: {
                      maxLength: 8, // Maximum length of 8 characters
                    },
                  }}
                />
              </FormControl>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    margin: '0 -16px',
                  }}
                >
                  {/* Hold Creation Date */}
                  <div
                    style={{
                      flex: '1 1 300px',
                      padding: '0 16px',
                      minWidth: '50%',
                      maxWidth: '100%',
                    }}
                  >
                    <label style={{ marginBottom: '8px' }}>
                      Hold Creation Date
                    </label>
                    <DatePicker
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={holdCreationDate}
                      name="custom_date_docs_due"
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={(date) => setHoldCreationDate(date)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        width: '100%', // Make it full width relative to its container
                        border: holdCreationDate
                          ? '1px solid #ced4da'
                          : '1px solid red',
                        borderRadius: '4px',
                      }}
                    />
                  </div>

                  {/* Hold Expiration Date and Time */}
                  <div
                    style={{
                      flex: '1 1 300px',
                      padding: '0 16px',
                      minWidth: '50%',
                      maxWidth: '100%',
                    }}
                  >
                    <FormLabel style={{ marginBottom: '8px' }}>
                      Hold Expiration Date and Time
                    </FormLabel>
                    <TextField
                      type="datetime-local"
                      fullWidth
                      variant="outlined"
                      value={holdExpirationDateTime}
                      onChange={(event) =>
                        setHoldExpirationDateTime(event.target.value)
                      }
                      componentsProps={{
                        label: {
                          shrink: true,
                        },
                      }}
                      style={{
                        width: '100%',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <Row style={{ margin: 14 }} xs="12">
            <Col xs="6">
              <Row
                style={{
                  right: '50px',
                  position: 'absolute',
                  display: 'flex',
                  columnGap: 6,
                }}
              >
                <Col xs="6">
                  <Button
                    variant="contained"
                    onClick={async (e) => {
                      setOpen(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>

                <Col xs="6">
                  {shouldShowAddButton() && (
                    <Button variant="contained" onClick={handleAddAvailability}>
                      ADD
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          {showCustomAlert && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10, // Ensure it's on top of other modal content
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <p style={{ color: 'red', fontSize: '1.5rem' }}>
                  Availability already exists for this provider at the specified
                  date and time.
                </p>
                <button
                  onClick={() => setShowCustomAlert(false)}
                  style={{
                    padding: '10px 20px',
                    fontSize: '1rem',
                    margin: '10px auto',
                    display: 'block',
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddAvailability;
