import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { hasSuperAdminAccess } from '../../common/utility';
import {
  userService,
  careCoordinatorService,
  providerService,
} from '../../services';

function compareCareCoordinatorFullName(a, b) {
  // Safely split the careCoordinator_name, default to empty string if provider_name is missing
  const lastNameA = a.care_coordinator_name
    ? a.care_coordinator_name.split(' ')[1] || ''
    : '';
  const lastNameB = b.care_coordinator_name
    ? b.care_coordinator_name.split(' ')[1] || ''
    : '';

  return lastNameA.localeCompare(lastNameB);
}

const formatDays = (daysArray) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'];
  const indices = daysArray
    .map((day) => daysOfWeek.indexOf(day))
    .sort((a, b) => a - b);

  if (indices.length === 5 && indices[0] === 0 && indices[4] === 4) {
    return 'Weekdays'; // Sunday to Thursday
  } else if (indices.length > 1) {
    let consecutive = true;
    for (let i = 1; i < indices.length; i++) {
      if (indices[i] !== indices[i - 1] + 1) {
        consecutive = false;
        break;
      }
    }
    if (consecutive) {
      return `${daysOfWeek[indices[0]]}-${
        daysOfWeek[indices[indices.length - 1]]
      }`;
    }
  }
  return daysArray.join(', '); // Default to joining the array
};

export default function CareCoordinatorPracticeInfoTable({
  showActiveCareCoordinators,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [care_coordinators, setCareCoordinators] = useState([]);
  const [locations, setLocations] = useState([]);
  const [, setLocationSet] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { user: currentUser } = useSelector((state) => state.auth);

  const formatScheduleForDisplay = (schedule) => {
    if (!schedule || !Array.isArray(schedule)) return 'No schedule';

    return schedule.map((sch, index) => {
      // Use formatDays to format the days array
      const days = formatDays(sch.days); // Adjust this line
      // Ensure dates are properly handled
      const startTime =
        sch.startTime instanceof Date
          ? sch.startTime.toLocaleTimeString([], { timeStyle: 'short' })
          : new Date(sch.startTime).toLocaleTimeString([], {
              timeStyle: 'short',
            });
      const endTime =
        sch.endTime instanceof Date
          ? sch.endTime.toLocaleTimeString([], { timeStyle: 'short' })
          : new Date(sch.endTime).toLocaleTimeString([], {
              timeStyle: 'short',
            });
      const scheduleString = `${days} from ${startTime} to ${endTime} (${sch.setting})`;
      return (
        <React.Fragment key={index}>
          {index > 0 && <br />}{' '}
          {/* Add line break before each schedule except the first one */}
          {scheduleString}
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    const status = showActiveCareCoordinators ? 'active' : 'inactive';

    careCoordinatorService
      .getCareCoordinators(status)
      .then((response) => {
        console.log('API Response for CareCoordinators:', response.data); // Log the CareCoordinator data here
        // Assuming the response structure is like the first JSON you've provided
        const fetchedCareCoordinators =
          response.data.filter(
            (care_coordinators) =>
              care_coordinators.care_coordinator_name != null
          ) ?? [];

        setCareCoordinators(fetchedCareCoordinators);

        return userService.getAllUsers().then((usersResponse) => {
          // Assuming the response structure is like the second JSON you've provided
          const users = usersResponse.data.users ?? [];

          // Enrich care_coordinators with user details including imageUrl
          const careCoordinatorsWithImages = fetchedCareCoordinators.map(
            (care_coordinators) => {
              const user = users.find(
                (user) => user.id === care_coordinators.id
              );
              return {
                ...care_coordinators,
                imageUrl:
                  user?.imageUrl ||
                  'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
              };
            }
          );

          // Now proceed with fetching additional details for each care_coordinators and updating state
          // Convert each care_coordinators detail fetching into a promise
          const fetchDetailsPromises = careCoordinatorsWithImages.map(
            (care_coordinators) =>
              getCareCoordinatorsDetail(care_coordinators.id, care_coordinators)
                .then((detail) => ({
                  ...care_coordinators,
                  ...detail,
                  hipaa: care_coordinators.hipaa ? 'Yes' : 'No', // Convert boolean HIPAA to 'Yes' or 'No'
                })) // Merge care_coordinators with its details
                .catch((error) => {
                  console.error(
                    'Failed to fetch details for care_coordinators:',
                    care_coordinators.id,
                    error
                  );
                  return {
                    ...care_coordinators,
                    hipaa: 'Unavailable', // Provide default or error value if needed
                  }; // Return care_coordinators as is in case of error
                })
          );

          Promise.all(fetchDetailsPromises).then(
            (completedCareCoordinators) => {
              // Further filter out any enriched careCoordinatorss if needed
              const validCareCoordinators = completedCareCoordinators.filter(
                (care_coordinators) =>
                  care_coordinators.care_coordinator_name != null
              );
              setLocations(validCareCoordinators); // Update locations with all fetched and valid details
              setLocationSet(true); // Indicate completion
            }
          );
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveCareCoordinators]); // Include alert if used within the effect

  const getCareCoordinatorsDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // ...

  const displayedCareCoordinators = useMemo(() => {
    // Sort CareCoordinators just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareCareCoordinatorFullName)
      .map((care_coordinators) => ({
        ...care_coordinators,
        schedule: formatScheduleForDisplay(care_coordinators.schedule), // Ensure schedule is a string
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, page, rowsPerPage]);

  return (
    <div>
      <TableContainer component={Paper} id="child">
        <Table
          sx={{
            tableLayout: 'fixed',
            minWidth: 650,
          }}
          size="medium"
          aria-label="Care_Coordinators_table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  backgroundColor: '#F5F5F5',
                }}
              >
                Full Name
              </TableCell>
              <TableCell
                style={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  backgroundColor: '#F5F5F5',
                }}
              >
                {' '}
                Schedule & Location
              </TableCell>
              <TableCell
                style={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  backgroundColor: '#F5F5F5',
                }}
              >
                Start Date
              </TableCell>
              {!showActiveCareCoordinators && (
                <TableCell
                  style={{
                    borderBottom: '0.5px solid black',
                    fontWeight: '900',
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  End Date
                </TableCell>
              )}
              <TableCell
                style={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  backgroundColor: '#F5F5F5',
                }}
              >
                HIPAA WorkForce
              </TableCell>
              <TableCell
                style={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  backgroundColor: '#F5F5F5',
                }}
              >
                Hours Per Week
              </TableCell>
              {hasSuperAdminAccess(currentUser) && (
                <TableCell
                  style={{
                    borderBottom: '0.5px solid black',
                    fontWeight: '900',
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  Password
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {displayedCareCoordinators?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  style={{
                    borderBottom: '0.1px solid black',
                  }}
                >
                  {row?.care_coordinator_name}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: '0.1px solid black',
                  }}
                >
                  {' '}
                  {row?.schedule}{' '}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: '0.1px solid black',
                  }}
                >
                  {row?.date_start}{' '}
                </TableCell>
                {!showActiveCareCoordinators && (
                  <TableCell
                    style={{
                      borderBottom: '0.1px solid black',
                    }}
                  >
                    {' '}
                    {row?.date_end}{' '}
                  </TableCell>
                )}
                <TableCell
                  style={{
                    borderBottom: '0.1px solid black',
                  }}
                >
                  {' '}
                  {row?.hipaa}{' '}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: '0.1px solid black',
                  }}
                >
                  {' '}
                  {row?.hrs_p_week}{' '}
                </TableCell>
                {hasSuperAdminAccess(currentUser) && (
                  <TableCell
                    style={{
                      borderBottom: '0.1px solid black',
                    }}
                  >
                    {' '}
                    {row?.pw}{' '}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={care_coordinators.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
