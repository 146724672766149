import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { login, loginWithGoogle } from '../actions/auth';
import { Box, Card, CardContent, Alert } from '@mui/material';

const Login = (props) => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const questionnaireValue = urlParams.get('questionnaire');
    const isQuestionnaire = questionnaireValue?.toString() === 'true';

    if (isQuestionnaire) {
      localStorage.setItem('isQuestionnaire', true);
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(login(data.username, data.password)).then(() => {
      props.history.push(
        localStorage.getItem('isQuestionnaire')?.toString() === 'true'
          ? '/clients'
          : '/personal_info'
      );
    });
  };

  const handleLoginResponse = (response) => {
    const { credential } = response;
    dispatch(loginWithGoogle(credential)).then(() => {
      if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
        props.history.push('/clients');
      } else {
        props.history.push('/personal_info');
      }

      let uniqueId = sessionStorage.getItem('uniqueId');
      if (!uniqueId) {
        uniqueId = Math.random().toString(36).substr(2, 9);
        sessionStorage.setItem('uniqueId', uniqueId);
      }
      const loginChannel = new BroadcastChannel('loginChannel');
      loginChannel.postMessage({ type: 'login', senderId: uniqueId });
      loginChannel.close();
    });
  };

  if (isLoggedIn) {
    return (
      <Redirect
        to={
          localStorage.getItem('isQuestionnaire')?.toString() === 'true'
            ? '/clients'
            : '/personal_info'
        }
      />
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            marginTop: '25vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            width: '100%',
            maxWidth: 400,
            backgroundColor: '#f7f7f7',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <CardContent
            sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '225px',
                height: '100px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 2,
                overflow: 'hidden', // Added to ensure the image stays within the container
              }}
            >
              <img
                src="app_logo.png"
                alt="profile-img"
                style={{
                  width: '100%', // Ensures the image fits within the Box
                  height: '100%', // Ensures the image height adjusts to the Box
                  objectFit: 'cover',
                }}
              />
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{ textAlign: 'center', width: '100%', padding: '0 50px' }}
              >
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign in as Levi"
                  onSuccess={handleLoginResponse}
                  onFailure={() => {
                    console.log('Login Failed');
                  }}
                  cookiePolicy={'single_host_origin'}
                  style={{
                    width: '100%',
                    padding: '12px 20px',
                    fontSize: '16px',
                  }}
                />
              </Box>

              {message && (
                <Box mt={2}>
                  <Alert
                    severity={
                      message ===
                      'You have been logged out due to inactivity. Please click on the login button to login again!'
                        ? 'info'
                        : 'error'
                    }
                    sx={{ width: '100%' }}
                  >
                    {message}
                  </Alert>
                </Box>
              )}
            </form>
          </CardContent>
        </Card>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default Login;
