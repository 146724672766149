import * as MUIv6 from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, memo } from 'react';
import { additionalLanguagesSpokenOptions } from '../../common/utility';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import styles from '../../styles/ProviderConfig.module.css';
import { careCoordinatorService } from '../../services';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const relationshipOptions = ['Parent', 'Spouse', 'Sibling', 'Friend', 'Other'];

const CareCoordinatorPersonalInfo = (props) => {
  const [dob, setDob] = React.useState();
  const [additionalLanguagesSpoken, setAdditionalLanguagesSpoken] =
    React.useState([]);
  const [gender, setGender] = React.useState('');
  const [ss_num, setSS_num] = React.useState('');
  const [personalEmail, setPersonalEmail] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [ext, setExt] = React.useState('');
  const [race, setRace] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [zip, setZip] = React.useState('');

  const [emergencyContactName, setEmergencyContactName] = React.useState('');
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    React.useState('');
  const [emergencyContactPhone, setEmergencyContactPhone] = React.useState('');

  const [customRelationship, setCustomRelationship] = React.useState('');

  const [careCoordinatorName, setCareCoordinatorName] = React.useState('');
  const [careCoordinatorEmail, setCareCoordinatorEmail] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const rawDOB = props.selectedUserDetail?.date_of_birth?.split('T')[0];
    if (rawDOB) {
      const formattedDOB = format(parseISO(rawDOB), 'yyyy-MM-dd'); // Use `format` and `parseISO` from date-fns
      setDob(formattedDOB);
    } else {
      setDob('');
    }

    setSS_num(props.selectedUserDetail?.ss_num ?? '');

    setAdditionalLanguagesSpoken(
      props?.selectedUserDetail?.additional_Languages_spoken ?? []
    );
    setGender(
      props.selectedUserDetail?.gender === 'm' ||
        props.selectedUserDetail?.gender === 'M'
        ? 'Male'
        : 'Female' ?? ''
    );

    setRace(props.selectedUserDetail?.race ?? '');

    setCareCoordinatorName(
      props.selectedUserDetail?.care_coordinator_name ??
        props.selectedUserDetail?.username ??
        ''
    );

    setCareCoordinatorEmail(
      props.selectedUserDetail?.care_coordinator_email ?? ''
    );
    setPersonalEmail(props.selectedUserDetail?.personal_email ?? '');
    setMobileNumber(props.selectedUserDetail?.mobile_number ?? '');
    setExt(props.selectedUserDetail?.ext ?? '');
    setAddress1(props.selectedUserDetail?.address1 ?? '');
    setAddress2(props.selectedUserDetail?.address2 ?? '');
    setCity(props.selectedUserDetail?.city ?? '');
    setState(props.selectedUserDetail?.state ?? '');
    setZip(props.selectedUserDetail?.zip ?? '');
    setEmergencyContactName(
      props?.selectedUserDetail?.emergencyContactName ?? ''
    );
    setEmergencyContactRelationship(
      props?.selectedUserDetail?.emergencyContactRelationship ?? ''
    );
    setEmergencyContactPhone(
      props?.selectedUserDetail?.emergencyContactPhone ?? ''
    );
  }, [props.selectedUserDetail]);

  const handleLanguagesChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdditionalLanguagesSpoken(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    let missingFields = [];

    // Check for the presence of care coordinator email
    if (!careCoordinatorEmail) {
      missingFields.push('Care Coordinator Email');
    }

    // Assuming dob, gender, race, etc., are state variables in your component
    if (!dob || dob === 'Invalid date') {
      missingFields.push('Date of Birth');
    }

    if (!gender) {
      missingFields.push('Gender');
    }

    if (!race) {
      missingFields.push('Race');
    }

    if (!personalEmail) {
      missingFields.push('Personal Email');
    }

    if (!ext) {
      missingFields.push('Extension');
    }

    if (!address1) {
      missingFields.push('Address 1');
    }

    if (!city) {
      missingFields.push('City');
    }

    if (!state) {
      missingFields.push('State');
    }

    if (!mobileNumber) {
      missingFields.push('Mobile Number');
    }

    if (careCoordinatorName.length === 0) {
      missingFields.push('Care Coordinator Name');
    }

    // If there are any missing fields, show an alert and log the missing fields
    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill out the required fields: ${missingFields.join(', ')}.`,
        {
          variant: 'error',
          timeout: 3000,
        }
      );
      return; // Stop the function here to prevent submitting the form with missing fields
    }

    // If all fields are present, proceed with form submission
    try {
      props.showLoading();
      const response =
        await careCoordinatorService.updateCareCoordinatorDetails(
          props.selectedUser.id,
          {
            email: careCoordinatorEmail,
            dob: dob !== 'Invalid date' ? dob : null, // Process dob for valid date or set as null
            gender,
            race,
            personalEmail,
            mobileNumber,
            ext,
            ss_num,
            careCoordinatorName,
            careCoordinatorEmail,
            address1,
            address2,
            city,
            state,
            zip,
            additionalLanguagesSpoken,
            emergencyContactName,
            emergencyContactRelationship:
              emergencyContactRelationship !== 'Other'
                ? emergencyContactRelationship
                : customRelationship,
            emergencyContactPhone,
            // Add other fields as needed
          }
        );

      if (
        response &&
        response.data &&
        response.data.message ===
          'Care coordinator details updated successfully.'
      ) {
        enqueueSnackbar('Care Coordinator details updated successfully.', {
          variant: 'success',
          timeout: 3000,
        });
        props.updateCareCoordinatorDetail(); // Assuming this updates the care coordinator detail in your application
      } else {
        // Handle case where the response does not indicate success
        enqueueSnackbar(
          'Failed to update care coordinator details. Please try again.',
          {
            variant: 'error',
            timeout: 3000,
          }
        );
      }
    } catch (error) {
      console.error('handleOnSubmit.error', error);
      const serverMessage =
        error.response?.data?.message || 'Unknown error occurred';
      enqueueSnackbar(
        `An error occurred while updating care coordinator details: ${serverMessage}`,
        {
          variant: 'error',
          timeout: 3000,
        }
      );
    } finally {
      props.hideLoading();
    }
  };

  return (
    <React.Fragment>
      {props.isCareCoordinator && (
        <MUIv6.Typography variant="body1" className={styles.providerNotice1}>
          All sections of the care coordinator configuration is read-only.
          Please reach out to Levi or Victoria if there is any information that
          you would like to update.
        </MUIv6.Typography>
      )}

      <div className={styles.tabsContentContainer}>
        <MUIv6.Grid2 container spacing={3} className={styles.gridItemTextField}>
          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="care_coordinator_name"
                label="Care Coordinator Name"
                value={careCoordinatorName}
                onChange={(e) => {
                  setCareCoordinatorName(e.target.value);
                }}
                // disabled={props.isProvider}
              />
            </MUIv6.Grid2>
          }

          <MUIv6.Grid2 item xs={3}>
            <DatePicker
              className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              autoOk
              autoComplete="off"
              name="date_of_birth"
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="Date of Birth"
              format="M/d/yy"
              value={dob ? parseISO(dob) : null} // parseISO might not be needed depending on how `dob` is set
              InputAdornmentProps={{ position: 'start' }}
              onChange={(date) => {
                const updatedDate = moment(date).format('yyyy-MM-DD');
                setDob(updatedDate);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage="Enter a valid date"
            />
          </MUIv6.Grid2>

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ss_num"
                label="SS #"
                value={ss_num}
                onChange={(event) => {
                  setSS_num(event.target.value);
                }}
              />
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <MUIv6.InputLabel id="demo-multiple-name-label">
                  Gender
                </MUIv6.InputLabel>

                <MUIv6.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  // disabled={props.isProvider}
                  onChange={(event) => {
                    setGender(event.target.value);
                  }}
                >
                  <MUIv6.MenuItem value={'Male'}>Male</MUIv6.MenuItem>
                  <MUIv6.MenuItem value={'Female'}>Female</MUIv6.MenuItem>
                </MUIv6.Select>
              </MUIv6.FormControl>
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <MUIv6.InputLabel id="demo-simple-select-label">
                  Race
                </MUIv6.InputLabel>
                <MUIv6.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={race}
                  label="Race"
                  onChange={(event) => {
                    setRace(event.target.value);
                  }}
                >
                  <MUIv6.MenuItem value={'White'}>White</MUIv6.MenuItem>
                  <MUIv6.MenuItem value={'African American'}>
                    African American
                  </MUIv6.MenuItem>
                  <MUIv6.MenuItem value={'Asian'}>Asian</MUIv6.MenuItem>
                </MUIv6.Select>
              </MUIv6.FormControl>
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="care_coordinator_email"
                label="Care Coordinator Email"
                value={careCoordinatorEmail}
                onChange={(event) => {
                  setCareCoordinatorEmail(event.target.value);
                }}
              />
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="personal_email"
                label="Personal Email"
                value={personalEmail}
                onChange={(event) => {
                  setPersonalEmail(event.target.value);
                }}
              />
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="mobile_number"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(event) => {
                  setMobileNumber(event.target.value);
                }}
              />
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ext"
                label="Ext"
                value={ext}
                onChange={(event) => {
                  setExt(event.target.value);
                }}
              />
            </MUIv6.Grid2>
          }

          {
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <MUIv6.InputLabel id="demo-multiple-name-label">
                  Additional Languages Spoken
                </MUIv6.InputLabel>
                <MUIv6.Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={
                    Array.isArray(additionalLanguagesSpoken)
                      ? additionalLanguagesSpoken
                      : []
                  }
                  onChange={handleLanguagesChange}
                  input={
                    <MUIv6.OutlinedInput label="Additional Languages Spoken" />
                  }
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                  disabled={props.isProvider}
                >
                  {additionalLanguagesSpokenOptions.map((name) => (
                    <MUIv6.MenuItem key={name} value={name}>
                      <MUIv6.Checkbox
                        checked={additionalLanguagesSpoken.indexOf(name) > -1}
                      />
                      <MUIv6.ListItemText primary={name} />
                    </MUIv6.MenuItem>
                  ))}
                </MUIv6.Select>
              </MUIv6.FormControl>
            </MUIv6.Grid2>
          }
        </MUIv6.Grid2>

        <div className={styles.addressSection}>
          <label htmlFor="address1">Home Address</label>

          <MUIv6.Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          >
            <MUIv6.Grid2 item xs={5}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address1"
                label="Address"
                value={address1}
                onChange={(event) => {
                  setAddress1(event.target.value);
                }}
              />
            </MUIv6.Grid2>

            <MUIv6.Grid2 item xs={6}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address2"
                label="e.g Apt"
                value={address2}
                onChange={(event) => {
                  setAddress2(event.target.value);
                }}
              />
            </MUIv6.Grid2>

            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="city"
                label="City"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
            </MUIv6.Grid2>

            <MUIv6.Grid2 item xs={3}>
              <MUIv6.FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <MUIv6.InputLabel id="state-label">State</MUIv6.InputLabel>
                <MUIv6.Select
                  labelId="state-label"
                  id="state"
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  label="State"
                >
                  {states.map((state) => (
                    <MUIv6.MenuItem key={state} value={state}>
                      {state}
                    </MUIv6.MenuItem>
                  ))}
                </MUIv6.Select>
              </MUIv6.FormControl>
            </MUIv6.Grid2>

            <MUIv6.Grid2 item xs={3}>
              <MUIv6.TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="zip"
                label="Zip Code"
                value={zip}
                onChange={(event) => setZip(event.target.value)}
              />
            </MUIv6.Grid2>
          </MUIv6.Grid2>
        </div>

        <div>
          <MUIv6.Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          >
            <MUIv6.Grid2 item xs={4} sm={4}>
              <MUIv6.TextField
                fullWidth
                variant="outlined"
                id="emergencyContactName"
                label="Emergency Contact Name"
                value={emergencyContactName}
                onChange={(e) => setEmergencyContactName(e.target.value)}
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </MUIv6.Grid2>

            <MUIv6.Grid2 item xs={4}>
              <MUIv6.FormControl
                fullWidth
                variant="outlined"
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              >
                <MUIv6.InputLabel>Relationship</MUIv6.InputLabel>
                <MUIv6.Select
                  value={emergencyContactRelationship}
                  onChange={(e) =>
                    setEmergencyContactRelationship(e.target.value)
                  }
                  label="Relationship"
                >
                  {relationshipOptions.map((option) => (
                    <MUIv6.MenuItem key={option} value={option}>
                      {option}
                    </MUIv6.MenuItem>
                  ))}
                </MUIv6.Select>
              </MUIv6.FormControl>
            </MUIv6.Grid2>

            {emergencyContactRelationship === 'Other' && (
              <MUIv6.Grid2 item xs={4}>
                <MUIv6.TextField
                  fullWidth
                  variant="outlined"
                  id="customRelationship"
                  label="Specify Relationship"
                  value={customRelationship}
                  onChange={(e) => setCustomRelationship(e.target.value)}
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                />
              </MUIv6.Grid2>
            )}

            {/* Emergency Contact Phone Number Field */}
            <MUIv6.Grid2 item xs={4} sm={4}>
              <MUIv6.TextField
                fullWidth
                variant="outlined"
                id="emergencyContactPhone"
                label="Emergency Contact Phone"
                value={emergencyContactPhone}
                onChange={(e) => setEmergencyContactPhone(e.target.value)}
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </MUIv6.Grid2>
          </MUIv6.Grid2>
        </div>

        <MUIv6.Grid2 container spacing={3} className={styles.gridItemTextField}>
          <MUIv6.Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          ></MUIv6.Grid2>
          <MUIv6.Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          >
            <MUIv6.Grid2 item xs={3}>
              <MUIv6.Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '40px' }}
                onClick={handleOnSubmit}
              >
                Save
              </MUIv6.Button>
            </MUIv6.Grid2>
          </MUIv6.Grid2>
        </MUIv6.Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(CareCoordinatorPersonalInfo);
