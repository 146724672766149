import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { memo, useRef } from 'react';
import React from 'react';
import { useSnackbar } from 'notistack';

const RiskQuestionDialog = ({
  open1,
  handleClose1,
  reason,
  handleCloseConfirm1,
  formValues,
  setFormValues,
  setReason,
}) => {
  const inputRef = useRef(); // This Ref has been created for performance and to not allowing re-rendering
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={open1}
      disableEscapeKeyDown={true}
      onClose={() => handleClose1()}
    >
      <DialogTitle>Note</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To opt to this option, please enter qualifying criteria here.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="Note"
          // type="note"
          fullWidth
          variant="standard"
          ref={inputRef}
          onChange={(event) => (inputRef.current.text = event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCloseConfirm1()}>Cancel</Button>

        <Button
          onClick={() => {
            if (inputRef.current?.text?.length > 0) {
              let questionId = localStorage.getItem('questionId');
              let riskQuestions = formValues.riskQuestions;

              let updatedRiskQuestions = riskQuestions.map(
                (riskQuestionsObj) => {
                  return parseInt(riskQuestionsObj.id) === parseInt(questionId)
                    ? {
                        id: parseInt(questionId),
                        question: riskQuestionsObj.question,
                        answer: riskQuestionsObj.answer,
                        reason: inputRef.current.text,
                      }
                    : riskQuestionsObj;
                }
              );

              setReason(inputRef.current.text);
              riskQuestions = [...updatedRiskQuestions];

              setFormValues({
                ...formValues,
                riskQuestions: riskQuestions,
              });
              handleCloseConfirm1(true);
            } else {
              enqueueSnackbar('Please fill out the reason', {
                variant: 'error',
                timeout: 3000,
              });
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(RiskQuestionDialog);
