import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../styles/TabLabelWithBadge.module.css';
const TabLabelWithBadge = ({ title, description }) => {
  // Access the badge numbers from the Redux store
  const { blackBadgeNumber, redBadgeNumber } = useSelector(
    (state) => state.clients.dipBadgeNumbers
  );

  return (
    <div style={{ textAlign: 'center' }}>
      <div className={styles.badgeContainer}>
        <span className={`${styles.badge} ${styles.blackBadge}`}>
          {blackBadgeNumber}
        </span>
        <span className={`${styles.badge} ${styles.redBadge}`}>
          {redBadgeNumber}
        </span>
      </div>
      <div style={{ fontSize: '1rem' }}>{title}</div>
      <div className={styles.tabDescription}>{description}</div>
    </div>
  );
};

export default TabLabelWithBadge;
