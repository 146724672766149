import { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from '@mui/material';

const UpdateImageDialog = ({
  imageUpdateModalOpen,
  setImageUpdateModalOpen,
  selectedUserForImageUpdate,
  handleUpdateImageURL,
  setImageUrl,
}) => {
  return (
    <Dialog
      open={imageUpdateModalOpen}
      onClose={() => setImageUpdateModalOpen(false)}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle>Update Image URL</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="text"
          variant="outlined"
          defaultValue={
            selectedUserForImageUpdate
              ? selectedUserForImageUpdate.imageUrl
              : ''
          }
          onChange={(e) => setImageUrl(e.target.value)} // Update your state or a temporary variable accordingly
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setImageUpdateModalOpen(false)}>Cancel</Button>
        <Button onClick={() => handleUpdateImageURL()}>Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UpdateImageDialog);
