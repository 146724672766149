import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// Get All the Users
const getAllUsers = () => {
  return axios.get(API_URL + 'users/getAllUsers', { headers: authHeader() }); // Updated to use the new endpoint
};

const getTeamMembers = (body) => {
  return axios.post(`${API_URL}users/getTeamMembers`, body, {
    headers: authHeader(),
  });
};

const getUserByEmail = (email) => {
  // eslint-disable-next-line no-useless-concat
  return axios.get(API_URL + 'users' + `/${email}`, { headers: authHeader() });
};

const createUser = (body) =>
  axios.post(`${API_URL}users`, body, { headers: authHeader() });

const updateUser = (body) =>
  axios.put(`${API_URL}users`, body, { headers: authHeader() });

const deleteUser = (body) =>
  axios.post(`${API_URL}delete_user`, body, { headers: authHeader() });

// userId is the id of the user for which I am checking here
// loginUserId is the id of the user login
const isSelectedUserSupervisee = async (userId) => {
  return await axios.get(`${API_URL}users/checkUserIsSupervisee/${userId}`, {
    headers: authHeader(),
  });
};

const updateUserImage = (userId, imageUrl) => {
  return axios.put(
    `${API_URL}users/updateImage`,
    { userId, imageUrl },
    { headers: authHeader() }
  );
};

// eslint-disable-next-line
export default {
  getAllUsers,
  getTeamMembers,
  getUserByEmail,
  createUser,
  updateUser,
  deleteUser,
  updateUserImage,
  isSelectedUserSupervisee,
};
