import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { useRef } from 'react';
import React, { memo } from 'react';
import { useSnackbar } from 'notistack';

const TherapyConfigDialog = ({
  open,
  handleClose,
  setReason,
  selectedField,
  setFormValues,
  setChanges,
  formValues,
  changes,
  handleCloseConfirm,
  reason,
}) => {
  const inputRef = useRef(); // This Ref has been created for performance and to not allowing re-rendering
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={() => handleClose()}
    >
      <DialogTitle>Note</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To opt to this option, please enter qualifying criteria here.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="Note"
          fullWidth
          variant="standard"
          ref={inputRef}
          onChange={(event) => (inputRef.current.text = event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCloseConfirm()}>Cancel</Button>

        <Button
          onClick={() => {
            if (
              inputRef?.current?.text &&
              inputRef?.current?.text?.length > 0
            ) {
              setReason(inputRef.current.text);
              let value = inputRef.current.text;
              let name = `${selectedField}_reason`;
              let theValue =
                typeof value === 'string' ? value.split(',') : value;
              setFormValues({
                ...formValues,
                [name]: theValue,
              });
              setChanges({
                ...changes,
                [name]: theValue,
              });

              const fieldName = `${selectedField}_reason`;
              setFormValues({
                ...formValues,
                [fieldName]: inputRef.current.text,
              });
              handleCloseConfirm(true);
            } else {
              enqueueSnackbar('Please fill out the reason', {
                variant: 'error',
                timeout: 3000,
              });
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(TherapyConfigDialog);
