import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const CustomTick = (props) => {
  const { x, y, payload } = props;

  const words = payload.value.split(' ');
  const maxWordsPerLine = 3;
  const lines = [];

  for (let i = 0; i < words.length; i += maxWordsPerLine) {
    lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor="middle"
        fill="#666"
        style={{ fontSize: '14px' }}
      >
        {lines.map((line, index) => (
          <tspan x={0} y={`${20 + index * 15}`} key={index}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
};

function BarCharts({
  firstBarColor,
  secondBarColor,
  thirdBarColor,
  forthBarColor,
  data,
  CustomTooltip,
  secondBar,
  thirdBar,
  forthBar,
  yAxisLabel,
  barWidth,
  isResponsive,
  showXaxis = true,
}) {
  const calculatedWidth = (data?.length || 0) * (barWidth || 200); // calculate the width based on the data length and bar width

  return (
    <div style={{ width: '100%', overflowX: 'auto', height: 300 }}>
      <Responsive isResponsive={isResponsive}>
        <BarChart
          width={calculatedWidth} // use calculatedWidth here
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 20,
          }}
          barGap={20} // add this line
          barCategoryGap={30} // and this line
        >
          <CartesianGrid strokeDasharray="1 1" />
          {showXaxis && (
            <XAxis
              dataKey="name"
              height={100}
              interval={0}
              padding={{ left: 10, right: 10 }}
              tick={<CustomTick />}
            />
          )}
          <YAxis />
          <Tooltip content={CustomTooltip || null} />
          {/* <Legend /> */}
          <Bar dataKey="value" barSize={barWidth} fill="#8884d8">
            {yAxisLabel}
            {data?.length > 0 &&
              data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    firstBarColor
                      ? firstBarColor
                      : COLORS[index % COLORS.length]
                  }
                />
              ))}
          </Bar>

          {secondBar && (
            <Bar dataKey="value1" barSize={barWidth} fill="#8884d8">
              {data?.length > 0 &&
                data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      secondBarColor
                        ? secondBarColor
                        : COLORS[index % COLORS.length]
                    }
                  />
                ))}
            </Bar>
          )}
          {thirdBar && (
            <Bar dataKey="value2" barSize={barWidth} fill="#8884d8">
              {data?.length > 0 &&
                data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      thirdBarColor
                        ? thirdBarColor
                        : COLORS[index % COLORS.length]
                    }
                  />
                ))}
            </Bar>
          )}
          {forthBar && (
            <Bar dataKey="value3" barSize={barWidth} fill="#8884d8">
              {data?.length > 0 &&
                data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      forthBarColor
                        ? forthBarColor
                        : COLORS[index % COLORS.length]
                    }
                  />
                ))}
            </Bar>
          )}
        </BarChart>
      </Responsive>
    </div>
  );
}

export default BarCharts;

const Responsive = ({ isResponsive, children }) => {
  return (
    <>
      {isResponsive ? (
        <ResponsiveContainer>{children}</ResponsiveContainer>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#ff80ed',
  '#065535',
  '#ffd700',
  '#ff7373',
  '#003366',
  '#8a2be2',
  '#800000',
  '#008000',
  '#ccff00',
  '#794044',
  '#cc0000',
  '#b4eeb4',
  '#800080',
];
