import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const viewFiles = (body) => {
  return axios.post(API_URL + `drive/viewfiles/${body?.folder}`, body, {
    headers: authHeader(),
  });
};

const deleteFiles = (body) =>
  axios.post(API_URL + `drive/deleteFile/${body?.fileId}`, body, {
    headers: authHeader(),
  });

const uploadFiles = (body) =>
  axios.post(API_URL + `drive/upload`, body, { headers: authHeader() });

const moveToExpire = (body) =>
  axios.post(API_URL + `drive/movefileToExpiredFolder`, body, {
    headers: authHeader(),
  });

const getDriveDetail = (body) => {
  const headers = authHeader();

  return axios.post(`${API_URL}getDriveDetails/${body?.provider_id}`, body, {
    headers: headers,
  });
};

// New function to copy the provider folder
const copyProviderFolder = (providerId) => {
  return axios.post(
    `${API_URL}drive/copy-provider-folder`,
    { providerId },
    {
      headers: authHeader(),
    }
  );
};

const moveProviderFolder = (providerId) => {
  return axios.post(
    `${API_URL}drive/move-provider-folder`,
    { providerId },
    {
      headers: authHeader(),
    }
  );
};

const updateDriveDetail = (body) =>
  axios.post(`${API_URL}updateDriveDetails`, body, { headers: authHeader() });

// eslint-disable-next-line
export default {
  viewFiles,
  deleteFiles,
  uploadFiles,
  moveToExpire,
  moveProviderFolder,
  copyProviderFolder,
  updateDriveDetail,
  getDriveDetail,
};
