import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { hasSuperAdminAccess } from '../../common/utility';
import { userService, adminService, providerService } from '../../services';

function compareAdminFullName(a, b) {
  // Safely split the Admin_name, default to empty string if admin_name is missing
  const lastNameA = a.admin_name ? a.admin_name.split(' ')[1] || '' : '';
  const lastNameB = b.admin_name ? b.admin_name.split(' ')[1] || '' : '';

  return lastNameA.localeCompare(lastNameB);
}

export default function AdminGeneralTable({ showActiveAdmins }) {
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [locationSet, setLocationSet] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const status = showActiveAdmins ? 'active' : 'inactive';

    adminService
      .getAdmins(status)
      .then((response) => {
        console.log('API Response for Admins:', response.data); // Log the admin data here
        // Assuming the response structure is like the first JSON you've provided
        const fetchedAdmins =
          response.data.filter((admin) => admin.admin_name != null) ?? [];

        return userService.getAllUsers().then((usersResponse) => {
          // Assuming the response structure is like the second JSON you've provided
          const users = usersResponse.data.users ?? [];

          // Enrich admins with user details including imageUrl
          const adminsWithImages = fetchedAdmins.map((admin) => {
            const user = users.find((user) => user.id === admin.id);
            return {
              ...admin,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          // Now proceed with fetching additional details for each admin and updating state
          // Convert each admin detail fetching into a promise
          const fetchDetailsPromises = adminsWithImages.map((admin) =>
            getAdminDetail(admin.id, admin)
              .then((detail) => ({ ...admin, ...detail })) // Merge admin with its details
              .catch((error) => {
                console.error(
                  'Failed to fetch details for admin:',
                  admin.id,
                  error
                );
                return admin; // Return admin as is in case of error
              })
          );

          Promise.all(fetchDetailsPromises).then((completedAdmins) => {
            // Further filter out any enriched admins if needed
            const validAdmins = completedAdmins.filter(
              (admin) => admin.admin_name != null
            );
            setLocations(validAdmins); // Update locations with all fetched and valid details
            setLocationSet(true); // Indicate completion
          });
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
  }, [showActiveAdmins, enqueueSnackbar]); // Include alert if used within the effect

  // Adjust getAdminDetail to return details instead of setting state
  const getAdminDetail = async (id, admin) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching admin details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  const displayedAdmins = useMemo(() => {
    // Sort admins just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareAdminFullName);
  }, [locations, page, rowsPerPage]);

  return (
    <div>
      <TableContainer
        component={Paper}
        id="child"
        sx={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        <Table
          sx={{ minWidth: 650, width: '100%', tableLayout: 'fixed' }}
          size="medium"
          aria-label="Admins_table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Full Name
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Image
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                PsychCare Email
              </TableCell>
              {hasSuperAdminAccess(currentUser) && (
                <TableCell
                  sx={{
                    borderBottom: '0.5px solid black',
                    fontWeight: '900',
                    position: 'sticky',
                    top: '20px',
                    zIndex: 100,
                    backgroundColor: 'white',
                  }}
                >
                  Personal Email
                </TableCell>
              )}

              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Ext
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Mobile #
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Date of Birth (Age)
              </TableCell>
              {hasSuperAdminAccess(currentUser) && (
                <TableCell
                  sx={{
                    borderBottom: '0.5px solid black',
                    fontWeight: '900',
                    position: 'sticky',
                    top: '20px',
                    zIndex: 100,
                    backgroundColor: 'white',
                  }}
                >
                  SS#
                </TableCell>
              )}
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '20px',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                Home Address
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationSet &&
              displayedAdmins?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      <div
                        style={{
                          width: '140px',
                        }}
                      >
                        {row?.admin_name}
                      </div>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      <img
                        src={
                          row.imageUrl ||
                          'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                        } // Use your default image URL
                        alt="Admin"
                        style={{ width: '100px', height: '100px' }} // Adjust size as needed
                      />
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      {row?.admin_email}
                    </TableCell>
                    {hasSuperAdminAccess(currentUser) && (
                      <TableCell
                        style={{
                          borderBottom: '0.1px solid black',
                        }}
                      >
                        {row?.personal_email}
                      </TableCell>
                    )}

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      {row?.ext}
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      {row?.mobile_number}
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      {moment.utc(row?.date_of_birth ?? '').format('M/D/YY')}
                      <br />(
                      {moment().diff(moment(row?.date_of_birth), 'years')} yrs)
                    </TableCell>

                    {hasSuperAdminAccess(currentUser) && (
                      <TableCell style={{ borderBottom: '0.1px solid black' }}>
                        {row?.ss_num}
                      </TableCell>
                    )}

                    <TableCell
                      style={{
                        borderBottom: '0.1px solid black',
                      }}
                    >
                      {`${row?.address1} ${row?.address2}`.trim()}
                      <br />
                      {`${row?.city}, ${row?.state} ${row?.zip}`.trim()}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={locations.length} // Ensure this is defined
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
