import React, { useEffect, useCallback } from 'react';
import {
  TextField,
  Autocomplete,
  Switch,
  Grid2,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import EventBus from '../common/EventBus';
import { userService } from '../services';
import { clearLoading, setLoading } from '../actions/loading';
import DeleteUserPopup from './UI/DeleteUserPopup';
import styles from '../styles/UserManagement.module.css';
import { NewUserDialog, UpdateImageDialog } from './Dialogs';

const UserManagement = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState([]); // Initialize as an empty array
  const [selectedUserId, setSelectedUserId] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [usersMaster, setUsersMaster] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredUsers, setFilteredUsers] = React.useState([]); // Filtered and/or sorted users
  const [filterType, setFilterType] = React.useState('all'); // Default filter type
  const [sortType, setSortType] = React.useState('lastName'); // Default sort type
  const [shouldShow, setShouldShow] = React.useState(false); // This state is for restricting access to the user
  const [open, setOpen] = React.useState(false);
  const [imageUpdateModalOpen, setImageUpdateModalOpen] = React.useState(false);
  const [selectedUserForImageUpdate, setSelectedUserForImageUpdate] =
    React.useState(null);
  const [showActiveUsersOnly, setShowActiveUsersOnly] = React.useState(true);
  const { user: currentUser } = useSelector((state) => state.auth);

  const { enqueueSnackbar } = useSnackbar();

  // The below useEffect checks if user has access to view the logs or user management
  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    userService
      .getAllUsers()
      .then(
        (response) => {
          const aggregatedUsers = aggregateUsers(response?.data?.users);
          setUsers(aggregatedUsers);
          setUsersMaster(aggregatedUsers); // If you use this for some other purpose
        },

        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // setContent(_content);
          console.log(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      )
      .finally(() => {
        hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // The below filter is for filtering active and inActive users
  useEffect(() => {
    showLoading();
    if (showActiveUsersOnly) {
      setUsers(
        aggregateUsers(
          usersMaster.filter((userMasterObj) => userMasterObj.is_active === 1)
        )
      );
    } else {
      setUsers(aggregateUsers(usersMaster));
    }
    // added a delay of 1 second
    setTimeout(() => {
      hideLoading();
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveUsersOnly]);

  useEffect(() => {
    let filtered = [...users];

    // Filtering based on roleId
    if (filterType !== 'all') {
      filtered = filtered.filter((user) =>
        user.roleId.includes(parseInt(filterType))
      );
    }

    filtered.sort((a, b) => {
      let aValue = a[sortType];
      let bValue = b[sortType];

      // Special handling for firstName
      if (sortType === 'firstName') {
        aValue = a.username.split(' ')[0];
        bValue = b.username.split(' ')[0];
      }

      // Special handling for lastName
      if (sortType === 'lastName') {
        if (a.username.includes(',')) {
          aValue = a.username.split(',')[0].trim().split(' ').pop();
        } else {
          aValue = a.username.split(' ').pop();
        }

        if (b.username.includes(',')) {
          bValue = b.username.split(',')[0].trim().split(' ').pop();
        } else {
          bValue = b.username.split(' ').pop();
        }
      }

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });

    setFilteredUsers(filtered);

    // Reset the pagination to the first page
    setPage(0);
  }, [users, filterType, sortType]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleImageClick = (user) => {
    setSelectedUserForImageUpdate(user);
    setImageUpdateModalOpen(true);
  };

  const handleUpdateImageURL = async () => {
    if (selectedUserForImageUpdate) {
      try {
        // Call the service function to update the user's image URL
        const response = await userService.updateUserImage(
          selectedUserForImageUpdate.id,
          imageUrl
        );
        if (response.data) {
          // Assuming the response includes some data you might want to check
          console.log('Image URL updated successfully:', response.data);
          // Optionally, refresh the user list here if necessary
          // fetchUsers(); // Assuming you have a function to refresh the user list
        }
      } catch (error) {
        console.error('Failed to update image URL:', error);
        // Handle any errors, e.g., by displaying a notification to the user
      } finally {
        setImageUpdateModalOpen(false); // Close the modal in any case
        window.location.reload();
      }
    }
  };

  // Function to aggregate multiple rows of each user into a single row with multiple roles
  const aggregateUsers = (users) => {
    const userMap = {};

    users.forEach((user) => {
      if (!userMap[user.id]) {
        userMap[user.id] = {
          ...user,
          roleId: [user.roleId], // Initialize as an array
        };
      } else {
        userMap[user.id].roleId.push(user.roleId); // Append to the existing array
      }
    });

    return Object.values(userMap); // Convert back to array
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onTagsChange = (_, values) => {
    if (values) {
      setUsers(
        usersMaster.filter((obj) => {
          return obj.id === values.id;
        })
      );
    } else {
      setUsers(usersMaster);
    }
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
    setEmail('');
  };
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      id: 'id',
      label: 'ID',
      minWidth: 50,
      view: (user) => {
        return <>{user.id}</>;
      },
    },
    {
      id: 'username',
      label: 'User Name',
      minWidth: 100,
      view: (value) => {
        return <>{value['username']}</>;
      },
    },
    {
      id: 'email',
      label: 'Email',
      minWidth: 170,
      view: (value) => {
        return <>{value['email']}</>;
      },
    },
    {
      id: 'imageUrl',
      label: 'Image',
      minWidth: 170,
      view: (value) => {
        // Specify the URL for your default image
        const defaultImageUrl =
          'https://www.supereasy.com/wp-content/uploads/2022/09/doctor-1-768x432.png';
        // Use the user's image URL if available, otherwise use the default image URL
        const imageUrl = value['imageUrl']
          ? value['imageUrl']
          : defaultImageUrl;
        return (
          <>
            <img
              src={imageUrl}
              width={50}
              style={{ cursor: 'pointer' }}
              onClick={() => handleImageClick(value)}
              alt="User"
            />
          </>
        );
      },
    },
    {
      id: 'createdAt',
      label: 'Created At',
      minWidth: 170,
      format: (value) => moment(value).format('MM/DD/YY hh:mmA'),
      view: (value) => {
        return <>{moment(value['createdAt']).format('MM/DD/YY hh:mmA')}</>;
      },
    },
    {
      id: 'updatedAt',
      label: 'Last Modified Date',
      minWidth: 170,
      format: (value) => {
        value.toFixed(2);
      },
      view: (value) => {
        return <>{moment(value['updatedAt']).format('MM/DD/YY hh:mmA')}</>;
      },
    },
    {
      id: 'roleId',
      label: 'Roles',
      view: (value) => {
        return (
          <>
            <span className={styles.messageSupervisee}>
              Supervisee role is not getting shown here. To view it, Please see
              provider config
            </span>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                variant="outlined"
                fullWidth
                className={styles.formControlWide}
              >
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  multiple // Allow multiple selection
                  name={`${value['id']}`}
                  value={
                    Array.isArray(value['roleId'])
                      ? value['roleId']
                      : [value['roleId']]
                  }
                  label="Role"
                  onChange={(event) => {
                    let selectedRoleIds = event.target.value;
                    selectedRoleIds = selectedRoleIds.filter(
                      (selectedRoleId) => selectedRoleId !== null
                    );
                    const currentRoles = value['roleId'];

                    /** 
                    The below logic checks if there is a conflict between the roles
                    Admin, carecoordinator and provider role can exists independently
                     */
                    const hasConflict = (currentRoles, newRoles) => {
                      const combinedRoles = [...currentRoles, ...newRoles];
                      const roleSet = new Set(combinedRoles);
                      return (
                        (roleSet.has(3) && roleSet.has(5)) || // Admin and Provider
                        (roleSet.has(3) && roleSet.has(6)) || // Admin and Care Coordinator
                        (roleSet.has(5) && roleSet.has(6)) // Provider and Care Coordinator
                      );
                    };

                    // If there is a conflict, show an alert and prevent the role from being set
                    if (hasConflict(currentRoles, selectedRoleIds)) {
                      enqueueSnackbar(
                        'Roles (Admin), (Provider) and (Care Coordinator) cannot exist together.',
                        {
                          variant: 'error',
                          timeout: 3000,
                        }
                      );
                      return;
                    }

                    setSelectedUserId(event.target.name);
                    setRole(selectedRoleIds);

                    let usersClone = [...users];
                    usersClone = usersClone.map((user) => {
                      if (parseInt(user.id) === parseInt(event.target.name)) {
                        user.roleId = [...selectedRoleIds]; // This should now be an array
                      }
                      return user;
                    });

                    setUsers(usersClone);
                  }}
                >
                  <MenuItem value={1}>App User</MenuItem>
                  <MenuItem value={3}>Admin</MenuItem>
                  <MenuItem value={4}>Patient</MenuItem>
                  <MenuItem value={5}>Provider</MenuItem>
                  <MenuItem value={6}>Care Coordinator</MenuItem>
                  {/* The below has been commented deliberately */}
                  {/* <MenuItem value={8}>Supervisee</MenuItem> */}
                  <MenuItem value={9}>Credentialer</MenuItem>
                  <MenuItem value={10}>Biller</MenuItem>
                  <MenuItem value={11}>Health Information Manager</MenuItem>
                  <MenuItem value={12}>Auditor</MenuItem>
                  <MenuItem value={13}>Claims Manager</MenuItem>
                  <MenuItem value={14}>Payroll Specialist</MenuItem>
                  <MenuItem value={15}>Extern</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        );
      },
    },

    {
      id: 'action',
      label: 'Action',
      minWidth: 170,
      view: (value) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={async (e) => {
                if (selectedUserId && role) {
                  const response = await userService.updateUser({
                    selectedUserId,
                    role, // Make sure this is an array of role ID
                    actionBy: currentUser.username,
                  });
                  if (response) {
                    window.location.reload();
                  }
                }
              }}
            >
              Update
            </Button>
            <DeleteUserPopup
              name={`${value['username']}`}
              value={value} //passing the whole value object
              className={styles.deleteUserPopup}
              variant="contained"
              onClick={DeleteUserPopup.handleOpen}
              userId={`${value['id']}`}
              showLoading={showLoading}
              hideLoading={hideLoading}
            ></DeleteUserPopup>
            {/* {getApproveButton(value)} */}
          </>
        );
      },
    },
  ];

  return shouldShow ? (
    <div>
      <Grid2 container spacing={2} sx={{ ml: 2, mt: 2 }}>
        {/* Filter Dropdown */}
        <Grid2 container spacing={10} alignItems="center">
          {/* Filter Dropdown */}

          <Grid2 size={{ xs: 3 }}>
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Filter</InputLabel>
              <Select
                label="Filter"
                onChange={(e) => setFilterType(e.target.value)}
                sx={{ width: 200 }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="3">Admin</MenuItem>
                <MenuItem value="5">Provider</MenuItem>
                <MenuItem value="6">Care Coordinator</MenuItem>
                <MenuItem value="7">Supervisor</MenuItem>
                <MenuItem value="8">Supervisee</MenuItem>
                <MenuItem value="9">Credentialer</MenuItem>
                <MenuItem value="10">Biller</MenuItem>
                <MenuItem value="11">Health Information Manager</MenuItem>
                <MenuItem value="12">Auditor</MenuItem>
                <MenuItem value="13">Claims Manager</MenuItem>
                <MenuItem value="14">Payroll Specialist</MenuItem>
                <MenuItem value="15">Extern</MenuItem>
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 3 }}>
            {/* Sort Dropdown */}
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Sort by</InputLabel>
              <Select
                label="Sort by"
                onChange={(e) => setSortType(e.target.value)}
                defaultValue="lastName"
                sx={{ width: 200 }}
              >
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="firstName">First Name</MenuItem>
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 3 }}>
            <FormControl variant="outlined" className={styles.formControl}>
              <Autocomplete
                disablePortal
                id="users_selection"
                options={users}
                onChange={onTagsChange}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    name="test"
                    label="Select user"
                  />
                )}
                getOptionLabel={(option) => option.username || ''}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 3 }}>
            <div className={styles.imgCenter}>
              <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon />
              </Fab>
            </div>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} alignItems="center">
          <Grid2 size={{ xs: 12 }}>
            <div>
              <Switch
                checked={showActiveUsersOnly}
                onChange={(e) => setShowActiveUsersOnly(e.target.checked)}
              />
              <span
                style={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                {showActiveUsersOnly ? (
                  <span> Show All Users</span>
                ) : (
                  <span>Show Active Users</span>
                )}
              </span>
            </div>
          </Grid2>
        </Grid2>
      </Grid2>

      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.view(row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredUsers.length} // <-- Make this change here
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* The below is NewUser Dialog */}
      <NewUserDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        handleClose={handleClose}
        setUsername={setUsername}
        setEmail={setEmail}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setRole={setRole}
        email={email}
        role={role}
        username={username}
        currentUser={currentUser}
        alert={alert}
      />

      {/* The below is UpdateImage Dialog */}
      <UpdateImageDialog
        imageUpdateModalOpen={imageUpdateModalOpen}
        setImageUpdateModalOpen={setImageUpdateModalOpen}
        selectedUserForImageUpdate={selectedUserForImageUpdate}
        handleUpdateImageURL={handleUpdateImageURL}
        setImageUrl={setImageUrl}
      />
    </div>
  ) : (
    <div>
      <p>You don't have permission to view it. </p>
    </div>
  );
};

export default UserManagement;
