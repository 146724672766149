import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// Can get Active or Inactive Admin based on the filter
const getAdmins = (status = 'active') =>
  axios.get(`${API_URL}admins?status=${status}`, { headers: authHeader() });

// Can get all the Admins
const getAllAdmins = () => {
  return axios.get(`${API_URL}admins/getAllAdmins`, {
    headers: authHeader(),
  });
};

const updateAdminDetails = (id, body) =>
  axios.post(`${API_URL}admin/${id}`, body, { headers: authHeader() });

const getAdminDetail = (id, email) =>
  axios.get(`${API_URL}admin/${id}?email=${email}`, {
    headers: authHeader(),
  });

// eslint-disable-next-line
export default {
  getAdmins,
  updateAdminDetails,
  getAdminDetail,
  getAllAdmins,
};
