import React, { useState, useEffect, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import {
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { docsService } from '../../services';
import styles from '../../styles/MyHandsOnTableComponent.module.css';
import { clearLoading, setLoading } from '../../actions/loading';

const serviceGroupOptions = ['Therapy', 'Testing'];
const serviceSubtypeOptions = {
  Therapy: ['Individuals', 'Couples', 'Groups', 'Family'],
  Testing: ['Psychological Testing', 'Forensic Testing'],
};
const clientTypeOptions = ['New', 'Existing', 'Returning'];
const ageOptions = ['18+', 'Under 18']; // Add your actual age ranges here
const licenseStatusOptions = ['Fully Licensed', 'Non-Fully Licensed']; // Add your actual license statuses here
const specialScenarioOptions = [
  'None',
  'Adoptions',
  'Adult ADHD',
  'Adult Autism',
  'Bariatrics - Hopkins',
  'Bariatrics - Other',
  'Couples - Insurance',
  'Couples - PP',
  'Families - Insurance',
  'Families - PP',
  'Pro Bono',
  'Turning 18',
];

const scenarioGroups = {
  add: [
    'Adoptions',
    'Adoptions, Couples - PP, Families - PP',
    'Adoptions, Couples - Insurance, Families - Insurance',
    'Adult ADHD',
    'Adult Autism',
    'Adult ADHD, Adult Autism',
    'Bariatrics',
    'Bariatrics - Hopkins',
    'Bariatrics - Other',
    'Couples, Families',
    'Couples - Insurance, Families - Insurance',
    'Couples - PP, Families - PP ',
    'Pro Bono',
    'Turning 18',
  ],

  replace: [],
};

function MyHandsonTableTestingComponent() {
  const [changes, setChanges] = useState([]); // Store changes locally
  const [idMap, setIdMap] = useState({});
  const { enqueueSnackbar } = useSnackbar(); // This hook provides the enqueueSnackbar method
  const [activeTab, setActiveTab] = useState(0); // 0 for Current, 1 for Archived
  const [currentData, setCurrentData] = useState([]);
  const [archivedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [serviceGroupFilter, setServiceGroupFilter] = useState('');
  const [serviceSubtypeFilter, setServiceSubtypeFilter] = useState('');
  const [clientTypeFilter, setClientTypeFilter] = useState('');
  const [ageRangeFilter, setAgeRangeFilter] = useState('');
  const [licenseStatusFilter, setLicenseStatusFilter] = useState('');
  const [specialScenarioFilter, setSpecialScenarioFilter] = useState('None');

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleServiceGroupFilterChange = (event) => {
    setServiceGroupFilter(event.target.value);
    setServiceSubtypeFilter(''); // Reset the service subtype filter when service group changes
  };

  const handleServiceSubtypeFilterChange = (event) => {
    setServiceSubtypeFilter(event.target.value);
  };

  const handleClientTypeFilterChange = (event) => {
    setClientTypeFilter(event.target.value);
  };

  const handleAgeRangeFilterChange = (event) => {
    setAgeRangeFilter(event.target.value);
  };

  const handleLicenseStatusFilterChange = (event) => {
    setLicenseStatusFilter(event.target.value);
  };

  const handleSpecialScenarioFilterChange = (event) => {
    setSpecialScenarioFilter(event.target.value);
  };

  const resetFilters = () => {
    window.location.reload();
  };

  // Define the custom renderer
  const linkRenderer = function (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);

    // Ensure the cell is cleaned up before adding new content
    Handsontable.dom.empty(td);

    // Create an anchor element
    const a = document.createElement('a');
    a.href = value;
    a.target = '_blank';
    a.textContent = value;

    // Append the anchor to the cell
    td.appendChild(a);

    // Optional: Set cell style
    td.style.color = 'blue';
    td.style.textDecoration = 'underline';
    if (value) {
      td.innerHTML = `<a href="${value}" target="_blank">Link</a>`;
    } else {
      td.innerHTML = '';
    }
    return td;
  };

  useEffect(() => {
    // Below showing the loading
    showLoading();
    docsService
      .getAllDocuments()
      .then((response) => {
        const transformedData = response.data.map((doc, index) => [
          doc.doc_name,
          doc.service_group,
          doc.service_sub_type,
          doc.client_type,
          doc.age_range,
          doc.license_status,
          doc.special_scenario, // Moved next to notes
          doc.notes ? doc.notes.toString() : '',
          doc.link
            ? doc.link.startsWith('http://') || doc.link.startsWith('https://')
              ? doc.link.toString()
              : `http://${doc.link.toString()}`
            : '', // Ensure link is an absolute URL
          // Removed date columns
        ]);

        // Create a map for IDs
        const newIdMap = response.data.reduce((acc, doc, index) => {
          acc[index] = doc.id; // Map the row index to the ID
          return acc;
        }, {});

        setIdMap(newIdMap); // Set the ID map
        setCurrentData(transformedData); // Set current data for display in the table
        setFilteredData(transformedData); // Initialize filtered data to full dataset
        // setArchivedData(transformedArchiveData); // Optionally set archived data if you have criteria to separate them
      })
      .catch((error) => {
        console.error('There was an error fetching the documents:', error);
        hideLoading();
      })
      .finally(() => {
        hideLoading(); // hiding the loading
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      // Apply initial filters without considering the special scenario.
      let filteredData = currentData.filter((row) => {
        return (
          (!serviceGroupFilter ||
            row[1]
              ?.split(',')
              .map((val) => val.trim())
              .includes(serviceGroupFilter) ||
            row[1]?.includes('All')) &&
          (!serviceSubtypeFilter ||
            row[2]
              ?.split(',')
              .map((val) => val.trim())
              .includes(serviceSubtypeFilter) ||
            row[2]?.includes('All')) &&
          (!clientTypeFilter ||
            row[3]
              ?.split(',')
              .map((val) => val.trim())
              .includes(clientTypeFilter) ||
            row[3]?.includes('All')) &&
          (!ageRangeFilter ||
            row[4]
              ?.split(',')
              .map((val) => val.trim())
              .includes(ageRangeFilter) ||
            row[4]?.includes('All')) &&
          (!licenseStatusFilter ||
            row[5]
              ?.split(',')
              .map((val) => val.trim())
              .includes(licenseStatusFilter) ||
            row[5]?.includes('All'))
        );
      });

      // Apply special scenario filter
      if (specialScenarioFilter === 'None') {
        // No special scenario filter applied
      } else if (scenarioGroups.add.includes(specialScenarioFilter)) {
        const baseDataWithNoSpecialScenario = filteredData.filter(
          (row) => !row[6] || row[6].trim() === ''
        );
        const additionalData = currentData.filter((row) => {
          const scenarios = row[6]
            ?.split('&')
            .map((scenario) => scenario.trim());
          return (
            scenarios &&
            shouldIncludeScenario(scenarios, specialScenarioFilter) &&
            (!serviceGroupFilter ||
              row[1]?.includes(serviceGroupFilter) ||
              row[1]?.includes('All')) &&
            (!serviceSubtypeFilter ||
              row[2]?.includes(serviceSubtypeFilter) ||
              row[2]?.includes('All')) &&
            (!clientTypeFilter ||
              row[3]?.includes(clientTypeFilter) ||
              row[3]?.includes('All')) &&
            (!ageRangeFilter ||
              row[4]?.includes(ageRangeFilter) ||
              row[4]?.includes('All')) &&
            (!licenseStatusFilter ||
              row[5]?.includes(licenseStatusFilter) ||
              row[5]?.includes('All'))
          );
        });
        filteredData = [
          ...new Set([...baseDataWithNoSpecialScenario, ...additionalData]),
        ]; // Combine without duplicates
      }

      setFilteredData(filteredData);
    };

    applyFilters();
  }, [
    currentData,
    serviceGroupFilter,
    serviceSubtypeFilter,
    clientTypeFilter,
    ageRangeFilter,
    licenseStatusFilter,
    specialScenarioFilter,
  ]);

  // Enhanced matching function
  function shouldIncludeScenario(scenarios, selectedScenario) {
    const selected = selectedScenario.trim().toLowerCase();
    return scenarios.some((scenario) => {
      const sc = scenario.trim().toLowerCase();
      return (
        sc === selected || sc.startsWith(selected) || selected.startsWith(sc)
      );
    });
  }

  const handleAfterChange = (change, source) => {
    if (source !== 'loadData' && change) {
      setChanges((currentChanges) => [...currentChanges, ...change]);
    }
  };

  const handleSave = async () => {
    try {
      showLoading(); // showing the loading

      let successfulUpdates = 0;
      let successfulCreations = 0;
      let skippedEntries = 0;
      let errors = 0;

      // Use the correct dataset based on the active tab
      const activeData = activeTab === 0 ? currentData : archivedData;

      // Define a Set of indices that represent the rows which have been edited
      const editedRowIndices = new Set(changes.map((change) => change[0]));

      // Iterate over only the edited rows based on the indices in editedRowIndices
      for (const index of editedRowIndices) {
        const newEntry = activeData[index];
        const isValidNewEntry = newEntry
          .slice(0, 3)
          .every((val) => val !== null && val !== '');

        if (!isValidNewEntry) {
          skippedEntries++;
          continue; // Skip creating new entry if data is incomplete
        }
        const docId = idMap[index]; // Get the ID from the map
        const updatedData = {
          doc_name: newEntry[0],
          service_group: newEntry[1],
          service_sub_type: newEntry[2],
          client_type: newEntry[3],
          age_range: newEntry[4],
          license_status: newEntry[5],
          special_scenario: newEntry[6],
          notes: newEntry[7],
          link: newEntry[8],
          // Assuming `created_at` and `updated_at` are managed by your backend on creation/update
        };

        if (docId) {
          try {
            // If the document has an ID, then it's an update.
            await docsService.updateDocument(docId, updatedData);
            successfulUpdates++;
          } catch (error) {
            console.error('There was an error updating the document:', error);
            errors++;
          }
        } else {
          // Create a new document
          try {
            await docsService.createDocument(updatedData);
            successfulCreations++;
          } catch (error) {
            console.error('There was an error creating a new document:', error);
            errors++;
          }
        }
      }

      if (errors > 0) {
        enqueueSnackbar(`There were errors processing some entries.`, {
          variant: 'error',
          timeout: 3000,
        });
      } else if (successfulUpdates > 0 || successfulCreations > 0) {
        let message = `Processed ${successfulUpdates} updates and ${successfulCreations} creations successfully.`;
        if (skippedEntries > 0) {
          message += ` Skipped ${skippedEntries} incomplete entries.`;
        }
        enqueueSnackbar(message, { variant: 'success', timeout: 3000 });
      } else if (skippedEntries > 0) {
        enqueueSnackbar(
          `Skipped ${skippedEntries} incomplete entries. No changes were made.`,
          { variant: 'info', timeout: 3000 }
        );
      }

      setChanges([]); // Reset changes after save
    } catch (error) {
      console.log('error.message: ', error.message);
    } finally {
      hideLoading();
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div className={styles.filterContainer}>
        <div className={styles.filterGroup}>
          <FormControl fullWidth style={{ minWidth: 200 }}>
            <InputLabel id="serviceGroupFilterLabel">
              Filter by Service Group
            </InputLabel>
            <Select
              labelId="serviceGroupFilterLabel"
              id="serviceGroupFilter"
              value={serviceGroupFilter}
              onChange={handleServiceGroupFilterChange}
              label="Filter by Service Group"
            >
              <MenuItem value="">No Filter</MenuItem>
              {serviceGroupOptions.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {serviceGroupFilter && (
          <div className={styles.filterGroup}>
            <FormControl fullWidth style={{ minWidth: 200 }}>
              <InputLabel id="serviceSubtypeFilterLabel">
                Filter by Service Subtype
              </InputLabel>
              <Select
                labelId="serviceSubtypeFilterLabel"
                id="serviceSubtypeFilter"
                value={serviceSubtypeFilter}
                onChange={handleServiceSubtypeFilterChange}
                label="Filter by Service Subtype"
              >
                <MenuItem value="">No Filter</MenuItem>
                {serviceSubtypeOptions[serviceGroupFilter].map((subtype) => (
                  <MenuItem key={subtype} value={subtype}>
                    {subtype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <div className={styles.filterGroup}>
          <FormControl fullWidth style={{ minWidth: 200 }}>
            <InputLabel id="clientTypeFilterLabel">
              Filter by Client Type
            </InputLabel>
            <Select
              labelId="clientTypeFilterLabel"
              id="clientTypeFilter"
              value={clientTypeFilter}
              onChange={handleClientTypeFilterChange}
              label="Filter by Client Type"
            >
              <MenuItem value="">No Filter</MenuItem>
              {clientTypeOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.filterGroup}>
          <FormControl fullWidth style={{ minWidth: 200 }}>
            <InputLabel id="ageRangeFilterLabel">
              Filter by Age Range
            </InputLabel>
            <Select
              labelId="ageRangeFilterLabel"
              id="ageRangeFilter"
              value={ageRangeFilter}
              onChange={handleAgeRangeFilterChange}
              label="Filter by Age Range"
            >
              <MenuItem value="">No Filter</MenuItem>
              {ageOptions.map((age) => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.filterGroup}>
          <FormControl fullWidth style={{ minWidth: 200 }}>
            <InputLabel id="licenseStatusFilterLabel">
              Filter by License Status
            </InputLabel>
            <Select
              labelId="licenseStatusFilterLabel"
              id="licenseStatusFilter"
              value={licenseStatusFilter}
              onChange={handleLicenseStatusFilterChange}
              label="Filter by License Status"
            >
              <MenuItem value="">No Filter</MenuItem>
              {licenseStatusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.filterGroup}>
          <FormControl fullWidth style={{ minWidth: 200 }}>
            <InputLabel id="specialScenarioFilterLabel">
              Filter by Special Scenario
            </InputLabel>
            <Select
              labelId="specialScenarioFilterLabel"
              id="specialScenarioFilter"
              value={specialScenarioFilter}
              onChange={handleSpecialScenarioFilterChange}
              label="Filter by Special Scenario"
            >
              {specialScenarioOptions.map((scenario) => (
                <MenuItem key={scenario} value={scenario}>
                  {scenario}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={resetFilters}
          style={{ marginLeft: '20px' }}
        >
          Reset Filters
        </Button>
      </div>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="document tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottom: '2px solid #E0E0E0',
          background: '#F5F5F5',
          marginBottom: '20px',
          marginTop: '20px',
        }}
      >
        <Tab label="Current Documents" />
        <Tab label="Archived Documents" />
      </Tabs>

      {activeTab === 0 && (
        <HotTable
          data={filteredData}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          colHeaders={[
            'Document Name',
            'Service Group',
            'Service Subtype',
            'Client Type',
            'Age Range',
            'License Status',
            'Special Scenario',
            'Notes',
            'Link', // Rearranged columns, removed dates
          ]}
          rowHeaders={true}
          width="1300"
          height="700"
          colWidths={[350, 150, 150, 150, 150, 150, 150, 150, 250]} // First column is 300, others are 150
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          filters={true}
          dropdownMenu={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          copyPaste={true}
          cells={(row, col, prop) => {
            const cellProperties = {};
            if (col === 8) {
              // Apply the renderer to the last column (index 8)
              cellProperties.renderer = linkRenderer;
            }
            return cellProperties;
          }}
        />
      )}

      {activeTab === 1 && (
        <HotTable
          data={archivedData}
          licenseKey="non-commercial-and-evaluation"
          colHeaders={[
            'Document Name',
            'Service Group',
            'Service Subtype',
            'Special Scenario',
            'Client Type',
            'Age Range',
            'License Status',
            'Notes',
            'Created Date',
            'Updated Date',
            'Link',
          ]}
          rowHeaders={true}
          width="1300"
          height="700"
          colWidths={150}
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          filters={true}
          dropdownMenu={true}
          copyPaste={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          cells={(row, col, prop) => {
            const cellProperties = {};
            cellProperties.className = styles.cellCentered;
            return cellProperties;
          }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: '10px' }}
      >
        Save Changes
      </Button>
    </div>
  );
}

export default memo(MyHandsonTableTestingComponent);
