import React, { useState, useEffect, useMemo } from 'react';
import * as MUIv6 from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { isCareCoordinator } from '../../common/utility';
import { useSelector } from 'react-redux';
import {
  providerService,
  userService,
  careCoordinatorService,
} from '../../services';

export default function CareCoordinatorGeneralTable({
  showActiveCareCoordinators,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [careCoordinators, setCareCoordinators] = useState([]);
  const [locations, setLocations] = useState([]);
  const [, setLocationSet] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const hasEmergencyContactAccess = (currentUser) => {
    return (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
      isCareCoordinator(currentUser)
    );
  };

  function compareCareCoordinatorFullName(a, b) {
    // Safely split the Admin_name, default to empty string if careCoordinator_name is missing
    const lastNameA = a.careCoordinator_name
      ? a.careCoordinator_name.split(' ')[1] || ''
      : '';
    const lastNameB = b.careCoordinator_name
      ? b.careCoordinator_name.split(' ')[1] || ''
      : '';

    return lastNameA.localeCompare(lastNameB);
  }
  useEffect(() => {
    const fetchCareCoordinators = async () => {
      try {
        const status = showActiveCareCoordinators ? 'active' : 'inactive';
        const [coordinatorResponse, userResponse] = await Promise.all([
          careCoordinatorService.getCareCoordinators(status),
          userService.getAllUsers(),
        ]);

        const users = userResponse.data.users ?? [];

        const careCoordinatorsWithImages = coordinatorResponse.data.map(
          (careCoordinator) => {
            const user = users.find((user) => user.id === careCoordinator.id);
            return {
              ...careCoordinator,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          }
        );

        setCareCoordinators(careCoordinatorsWithImages);
        setLocationSet(true);
      } catch (error) {
        enqueueSnackbar('Error fetching care coordinators', {
          variant: 'error',
          timeout: 3000,
        });
        console.error('Error fetching care coordinators:', error);
      }
    };

    fetchCareCoordinators();
  }, [showActiveCareCoordinators, enqueueSnackbar]);

  // Adjust getCareCoordinator Detail to return details instead of setting state
  const getCareCoordinatorDetail = async (id, careCoordinator) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching care coordinator details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  useEffect(() => {
    // Convert each careCoordinator detail fetching into a promise
    const fetchDetailsPromises = careCoordinators.map((careCoordinator) =>
      getCareCoordinatorDetail(careCoordinator.id, careCoordinator)
        .then((detail) => ({ ...careCoordinator, ...detail })) // Merge careCoordinator with its details
        .catch((error) => {
          console.error(
            'Failed to fetch details for care coordinator:',
            careCoordinator.id,
            error
          );
          return careCoordinator; // Return care coordinator as is in case of error
        })
    );

    Promise.all(fetchDetailsPromises).then((completedCareCoordinators) => {
      setLocations(completedCareCoordinators); // Update locations with all fetched details
      setLocationSet(true); // Indicate completion
    });
  }, [careCoordinators]); // Re-run when careCoordinators change

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  const displayedCareCoordinators = useMemo(() => {
    // Sort care coordinators just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareCareCoordinatorFullName);
  }, [locations, page, rowsPerPage]);

  // ...
  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
  };
  const tableCellStyle = {
    borderBottom: '0.1px solid black',
    whiteSpace: 'normal', // Ensures text wraps in the cell
    wordWrap: 'break-word', // Allows long words to be broken and wrapped to the next line
    overflowWrap: 'break-word', // Ensures overflow text is handled properly
    maxWidth: '150px', // Adjust based on your layout needs
    padding: '10px', // Optional, for better cell content spacing
  };

  return (
    <div>
      <MUIv6.TableContainer
        component={MUIv6.Paper}
        id="child"
        style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <MUIv6.Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Providers_table"
        >
          <MUIv6.TableHead>
            <MUIv6.TableRow>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Full Name{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Image{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                PsychCare Email{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Personal Email{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Ext{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Mobile #{' '}
              </MUIv6.TableCell>
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Date of Birth (Age){' '}
              </MUIv6.TableCell>
              {isCareCoordinator(currentUser) && (
                <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                  {' '}
                  SS#{' '}
                </MUIv6.TableCell>
              )}
              <MUIv6.TableCell style={{ ...tableHeaderCellStyle }}>
                {' '}
                Home Address{' '}
              </MUIv6.TableCell>

              {hasEmergencyContactAccess(currentUser) && (
                <>
                  <MUIv6.TableCell
                    style={{
                      ...tableHeaderCellStyle,
                    }}
                  >
                    Emergency Contact Name
                  </MUIv6.TableCell>
                  <MUIv6.TableCell
                    style={{
                      ...tableHeaderCellStyle,
                    }}
                  >
                    Emergency Contact Relationship
                  </MUIv6.TableCell>
                  <MUIv6.TableCell
                    style={{
                      ...tableHeaderCellStyle,
                    }}
                  >
                    Emergency Contact Phone#
                  </MUIv6.TableCell>
                </>
              )}
            </MUIv6.TableRow>
          </MUIv6.TableHead>

          <MUIv6.TableBody>
            {setLocationSet &&
              displayedCareCoordinators?.map((row, index) => {
                return (
                  <MUIv6.TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <MUIv6.TableCell style={tableCellStyle}>
                      {' '}
                      <div style={{ width: '140px' }}>
                        {row?.care_coordinator_name}{' '}
                      </div>{' '}
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      <div
                        style={{
                          width: '100px',
                          height: '100px',
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={
                            row.imageUrl ||
                            'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                          }
                          alt="Care Coordinator"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      {' '}
                      <div style={{ width: '140px' }}>
                        {row?.care_coordinator_email}{' '}
                      </div>{' '}
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      {' '}
                      <div style={{ width: '140px' }}>
                        {row?.personal_email}{' '}
                      </div>{' '}
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      {' '}
                      <div style={{ width: '140px' }}>{row?.ext} </div>{' '}
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      {' '}
                      <div style={{ width: '140px' }}>
                        {row?.mobile_number}{' '}
                      </div>{' '}
                    </MUIv6.TableCell>

                    <MUIv6.TableCell style={tableCellStyle}>
                      {moment.utc(row?.date_of_birth ?? '').format('M/D/YY')}{' '}
                      <br />(
                      {moment().diff(moment(row?.date_of_birth), 'years')} yrs)
                    </MUIv6.TableCell>

                    {isCareCoordinator(currentUser) && (
                      <MUIv6.TableCell style={tableCellStyle}>
                        {' '}
                        <div style={{ width: '140px' }}>
                          {row?.ss_num}{' '}
                        </div>{' '}
                      </MUIv6.TableCell>
                    )}

                    <MUIv6.TableCell style={tableCellStyle}>
                      {row?.address1 ||
                      row?.address2 ||
                      row?.city ||
                      row?.state ||
                      row?.zip ? (
                        <>
                          {`${row?.address1 || ''} ${
                            row?.address2 || ''
                          }`.trim()}{' '}
                          <br />
                          {`${row?.city || ''}, ${row?.state || ''} ${
                            row?.zip || ''
                          }`.trim()}{' '}
                        </>
                      ) : null}
                    </MUIv6.TableCell>
                    {hasEmergencyContactAccess(currentUser) && (
                      <MUIv6.TableCell style={tableCellStyle}>
                        {row.emergencyContactName}
                      </MUIv6.TableCell>
                    )}
                    {hasEmergencyContactAccess(currentUser) && (
                      <MUIv6.TableCell style={tableCellStyle}>
                        {row.emergencyContactRelationship === 'Other'
                          ? row.customRelationship
                          : row.emergencyContactRelationship}
                      </MUIv6.TableCell>
                    )}
                    {hasEmergencyContactAccess(currentUser) && (
                      <MUIv6.TableCell style={tableCellStyle}>
                        {row.emergencyContactPhone}
                      </MUIv6.TableCell>
                    )}
                  </MUIv6.TableRow>
                );
              })}
          </MUIv6.TableBody>
        </MUIv6.Table>
      </MUIv6.TableContainer>

      <MUIv6.TablePagination
        component="div"
        count={careCoordinators.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
