import { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

const CancelSurveyDialog = ({
  openCancelSurvey,
  handleCloseCancelSurvey,
  handleCombinedClick,
  selectedProviderAvailabilityId,
}) => {
  return (
    <Dialog open={openCancelSurvey} onClose={handleCloseCancelSurvey}>
      <DialogTitle>Cancel Survey</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this survey?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCancelSurvey}>No</Button>
        <Button
          onClick={() => handleCombinedClick(selectedProviderAvailabilityId)}
          autoFocus
        >
          Yes, Cancel Survey
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CancelSurveyDialog);
