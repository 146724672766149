import React, { useEffect, useCallback, memo } from 'react';
import {
  Checkbox,
  FormControl,
  Grid2,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import styles from '../../styles/ProviderConfig.module.css';
import { providerService } from '../../services';
import {
  additionalLanguagesSpokenOptions,
  createOrUpdateBirthdayEvent,
} from '../../common/utility';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
const relationshipOptions = ['Parent', 'Spouse', 'Sibling', 'Friend', 'Other'];

const ProviderPersonalInfo = (props) => {
  const [dob, setDob] = React.useState();
  const [additionalLanguagesSpoken, setAdditionalLanguagesSpoken] =
    React.useState([]);
  const [gender, setGender] = React.useState('');
  const [ss_num, setSS_num] = React.useState('');
  const [personalEmail, setPersonalEmail] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [ext, setExt] = React.useState('');
  const [race, setRace] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [zip, setZip] = React.useState('');

  const [emergencyContactName, setEmergencyContactName] = React.useState('');
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    React.useState('');
  const [emergencyContactPhone, setEmergencyContactPhone] = React.useState('');

  const [customRelationship, setCustomRelationship] = React.useState('');

  const [providerName, setProviderName] = React.useState('');

  const isDoctorAdminSupervisor = (user) => {
    return (
      user &&
      user.roles &&
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const date = moment(
      props.selectedUserDetail?.date_of_birth?.split('T')[0] ?? ''
    ).format('YYYY-MM-DD');
    setDob(date);
    setSS_num(props.selectedUserDetail?.ss_num ?? '');

    setAdditionalLanguagesSpoken(
      props?.selectedUserDetail?.additional_Languages_spoken ?? []
    );
    setGender(
      props.selectedUserDetail?.gender === 'm' ||
        props.selectedUserDetail?.gender === 'M'
        ? 'Male'
        : 'Female' ?? ''
    );

    setRace(props.selectedUserDetail?.race ?? '');

    setProviderName(
      props.selectedUserDetail?.provider_name ??
        props.selectedUserDetail?.username ??
        ''
    );

    setPersonalEmail(props.selectedUserDetail?.personal_email ?? '');
    setMobileNumber(props.selectedUserDetail?.mobile_number ?? '');
    setExt(props.selectedUserDetail?.ext ?? '');
    setAddress1(props.selectedUserDetail?.address1 ?? '');
    setAddress2(props.selectedUserDetail?.address2 ?? '');
    setCity(props.selectedUserDetail?.city ?? '');
    setState(props.selectedUserDetail?.state ?? '');
    setZip(props.selectedUserDetail?.zip ?? '');
    setEmergencyContactName(
      props?.selectedUserDetail?.emergencyContactName ?? ''
    );
    setEmergencyContactRelationship(
      props?.selectedUserDetail?.emergencyContactRelationship ?? ''
    );
    setEmergencyContactPhone(props?.selectedUserDetail?.emergencyContactPhone);
  }, [props.selectedUserDetail]);

  const handleLanguagesChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdditionalLanguagesSpoken(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handlePhoneChange = (event) => {
    // Strip out non-numeric input and keep only digits
    const input = event.target.value.replace(/\D/g, '');

    // Initialize a variable to hold the formatted phone number
    let formattedNumber = '';

    // Apply formatting if there are enough digits
    if (input.length > 0) {
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      // Building the phone number with dashes
      formattedNumber += areaCode;
      if (input.length >= 4) {
        formattedNumber += '-' + middle;
      }
      if (input.length >= 7) {
        formattedNumber += '-' + last;
      }
    }

    // Update state with the formatted phone number
    setEmergencyContactPhone(formattedNumber);
  };

  const handleOnSubmit = async (event) => {
    try {
      showLoading();
      event.preventDefault();
      if (props?.selectedUser?.id) {
        let dobUpdated = dob === 'Invalid date' ? '' : dob;

        // Include checks for emergency contact fields
        const isEmergencyContactInfoComplete =
          emergencyContactName &&
          emergencyContactPhone &&
          (emergencyContactRelationship !== 'Other' ||
            (emergencyContactRelationship === 'Other' && customRelationship));

        if (
          props.selectedUserDetail?.provider_email &&
          dobUpdated &&
          gender &&
          race &&
          (personalEmail || mobileNumber || ext || 1) && // explicitly have set to 1 to make these fields optional
          address1 &&
          city &&
          state &&
          providerName.length > 0 &&
          isEmergencyContactInfoComplete // Check if emergency contact info is complete
        ) {
          const response = await providerService.updateProviderDetails(
            props.selectedUser.id,
            {
              email: props.selectedUserDetail?.provider_email,
              inTherapy: props.selectedUserDetail?.in_therapy,
              inTesting: props.selectedUserDetail?.in_testing,
              additionalLanguagesSpoken,
              personalEmail,
              mobileNumber,
              ext,
              dob: dobUpdated,
              ss_num,
              gender,
              race,
              providerName,
              address1,
              address2,
              city,
              state,
              zip,
              // Add emergency contact fields to the submission payload
              emergencyContactName,
              emergencyContactRelationship:
                emergencyContactRelationship !== 'Other'
                  ? emergencyContactRelationship
                  : customRelationship,
              emergencyContactPhone,
              supervisorEmail: props.selectedUserDetail?.supervisor_email,
              supervisorName: props.selectedUserDetail?.supervisor_name,
              degreesAndLicense: props.selectedUserDetail?.degrees_and_license,
              licensedInOtherStates:
                props.selectedUserDetail?.licensed_in_other_states,
              aetna_effective_date:
                props.selectedUserDetail?.aetna_effective_date,
              bcbs_effective_date:
                props.selectedUserDetail?.bcbs_effective_date,
              cigna_effective_date:
                props.selectedUserDetail?.cigna_effective_date,
              hopkins_ehp_effective_date:
                props.selectedUserDetail?.hopkins_ehp_effective_date,
              hopkins_usfhp_effective_date:
                props.selectedUserDetail?.hopkins_usfhp_effective_date,
              medicaid_effective_date:
                props.selectedUserDetail?.medicaid_effective_date,
              tricare_effective_date:
                props.selectedUserDetail?.tricare_effective_date,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Provider details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          }

          /* The below is the code for creating birthday event */
          await createOrUpdateBirthdayEvent(providerName, dobUpdated);

          enqueueSnackbar('Updated Provider ', {
            variant: 'success',
            timeout: 3000,
          });
          props.updateProviderDetail();
        } else {
          // Updated to prompt for emergency contact information as well
          enqueueSnackbar(
            'Please ensure all required fields, including emergency contact information, are filled out.',
            {
              variant: 'error',
              timeout: 3000,
            }
          );
        }
      } else {
        enqueueSnackbar('Please select provider first ', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    } finally {
      hideLoading();
    }
  };

  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the provider configuration is read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography
            variant="body1"
            sx={{
              width: '100%', // ensures the text can be in the center of the page
              textAlign: 'center',
              color: '#666',
              fontSize: '12px', // choose the font size that suits you
              marginTop: '20px', // adjust this value as needed
              marginBottom: '20px',
              padding: '1px', // adds padding to create space around the text inside the box
              backgroundColor: '#f0f8ff', // a light blue color, change to the color you want
            }}
          >
            Parts of the "Personal Info" section of the provider profile is
            read-only. Please reach out to Levi or Victoria if there is any
            information that needs to be updated that you are unable to update.
          </Typography>
        )}

      {/* The below I have given class tabsContentContainer to set margintop 20px  */}
      <div className={styles.tabsContentContainer}>
        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="provider_name"
                label="Provider Name"
                value={providerName}
                onChange={(e) => {
                  setProviderName(e.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {dob && dob !== undefined && (
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <DatePicker
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoOk
                autoComplete="off"
                name="date_of_birth"
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Date of Birth"
                format="M/d/yy"
                defaultValue={parseISO(dob)}
                value={parseISO(dob)}
                disabled={props.isProvider}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => {
                  const updatedDate = moment(date).format('yyyy-MM-DD');
                  setDob(updatedDate);
                }}
              />
            </Grid2>
          )}

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ss_num"
                label="SS #"
                value={ss_num}
                onChange={(event) => {
                  setSS_num(event.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">Gender</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setGender(event.target.value);
                  }}
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">Race</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={race}
                  label="Race"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setRace(event.target.value);
                  }}
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'African American'}>
                    African American
                  </MenuItem>
                  <MenuItem value={'Asian'}>Asian</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="provider_email"
                label="Provider Email"
                value={props.selectedUserDetail?.provider_email ?? ''}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="personal_email"
                label="Personal Email"
                value={personalEmail}
                onChange={(event) => {
                  setPersonalEmail(event.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="mobile_number"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(event) => {
                  setMobileNumber(event.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ext"
                label="Ext"
                value={ext}
                onChange={(event) => {
                  setExt(event.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">
                  Additional Languages Spoken
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={
                    Array.isArray(additionalLanguagesSpoken)
                      ? additionalLanguagesSpoken
                      : []
                  }
                  onChange={handleLanguagesChange}
                  input={<OutlinedInput label="Additional Languages Spoken" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                  disabled={props.isProvider}
                >
                  {additionalLanguagesSpokenOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={additionalLanguagesSpoken.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          }
        </Grid2>

        <div className={styles.addressSection}>
          <label htmlFor="address1">Home Address</label>

          <Grid2 container spacing={3} className={styles.gridItemTextField}>
            <Grid2 size={{ xs: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address1"
                label="Address"
                value={address1}
                onChange={(event) => {
                  setAddress1(event.target.value);
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address2"
                label="e.g Apt"
                value={address2}
                onChange={(event) => {
                  setAddress2(event.target.value);
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="city"
                label="City"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  label="State"
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="zip"
                label="Zip Code"
                value={zip}
                onChange={(event) => setZip(event.target.value)}
              />
            </Grid2>
          </Grid2>
        </div>

        <div>
          <Grid2 container spacing={3} className={styles.gridItemTextField}>
            <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                id="emergencyContactName"
                label="Emergency Contact Name"
                value={emergencyContactName}
                onChange={(e) => setEmergencyContactName(e.target.value)}
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
              <FormControl
                fullWidth
                variant="outlined"
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              >
                <InputLabel>Relationship</InputLabel>
                <Select
                  value={emergencyContactRelationship}
                  onChange={(e) =>
                    setEmergencyContactRelationship(e.target.value)
                  }
                  label="Relationship"
                >
                  {relationshipOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            {emergencyContactRelationship === 'Other' && (
              <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="customRelationship"
                  label="Specify Relationship"
                  value={customRelationship}
                  onChange={(e) => setCustomRelationship(e.target.value)}
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                />
              </Grid2>
            )}

            {/* Emergency Contact Phone Number Field */}
            <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                id="emergencyContactPhone"
                label="Emergency Contact Phone"
                value={emergencyContactPhone}
                onChange={handlePhoneChange} // Attached the updated handler
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </Grid2>
          </Grid2>
        </div>

        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          <Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          ></Grid2>
          <Grid2 container spacing={3} className={styles.gridItemTextField}>
            <Grid2 item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={styles.buttonWithMargin}
                onClick={handleOnSubmit}
                // disabled={props.isProvider} // Uncomment this if needed
              >
                Save
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderPersonalInfo);
