import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// Can get Active or Inactive carecoordinators based on the filter
const getCareCoordinators = (status = 'active') =>
  axios.get(`${API_URL}careCoordinators?status=${status}`, {
    headers: authHeader(),
  });

// Can get all the Carecoordinators
const getAllCareCoordinators = () => {
  return axios.get(`${API_URL}careCoordinators/getAllCareCoordinators`, {
    headers: authHeader(),
  });
};

const getCareCoordinatorDetail = (id, email) =>
  axios.get(`${API_URL}careCoordinator/${id}?email=${email}`, {
    headers: authHeader(),
  });

const updateCareCoordinatorDetails = (id, body) =>
  axios.post(`${API_URL}careCoordinator/${id}`, body, {
    headers: authHeader(),
  });

// eslint-disable-next-line
export default {
  getAllCareCoordinators,
  getCareCoordinatorDetail,
  getCareCoordinators,
  updateCareCoordinatorDetails,
};
