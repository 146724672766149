import React, { useState, useEffect, memo } from 'react';
import {
  Grid2,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { isDoctor } from '../../common/utility';
import {
  userService,
  adminService,
  careCoordinatorService,
  providerService,
} from '../../services';

const DirectoryTable = () => {
  const [directoryEntries, setDirectoryEntries] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [filter, setFilter] = useState('All'); // Filter state
  const { user: currentUser } = useSelector((state) => state.auth);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDirectoryData = async () => {
      try {
        const status = showActive ? 'active' : 'inactive';
        const isActive = showActive; // This will be true if active, false if inactive

        // Fetch providers, admins, and users in parallel
        const [
          providersResponse,
          adminsResponse,
          careCoordinatorsResponse,
          usersResponse,
        ] = await Promise.all([
          providerService.getProviders(status),
          adminService.getAdmins(status),
          careCoordinatorService.getCareCoordinators(status),
          userService.getAllUsers(),
        ]);

        const users = usersResponse.data.users;

        // Enrich providers with images and type
        const providersWithImages = providersResponse.data.providers
          .filter((provider) => provider.provider_name != null)
          .map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              name: provider.provider_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
              type: 'Provider', // Adding type for clarity in rendering
              isActive: isActive,
            };
          });

        // Enrich admins with images and type
        const adminsWithImages = adminsResponse.data.map((admin) => {
          const user = users.find((user) => user.id === admin.id);
          return {
            ...admin,
            name: admin.admin_name || 'No Name',
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            type: 'Admin', // Adding type for clarity in rendering
            isActive: isActive,
          };
        });

        const careCoordinatorsWithImages = careCoordinatorsResponse.data.map(
          (careCoordinator) => {
            const user = users.find((user) => user.id === careCoordinator.id);
            return {
              ...careCoordinator,
              name: careCoordinator.care_coordinator_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg', // Default image or use one specific for care coordinators
              type: 'Care Coordinator', // This type is used for filtering
              isActive: isActive, // This might need adjustment depending on how isActive is determined
            };
          }
        );

        // Combine and sort providers, admins, and care coordinators
        const combinedEntries = [
          ...providersWithImages,
          ...adminsWithImages,
          ...careCoordinatorsWithImages,
        ].sort((a, b) => {
          // Extract the primary name or fallback to an empty string
          const nameA = (a.name || '').toLowerCase();
          const nameB = (b.name || '').toLowerCase();
          return nameA.localeCompare(nameB);
        });

        setDirectoryEntries(combinedEntries);
      } catch (error) {
        console.error('Failed to fetch directory data:', error);
      }
    };

    fetchDirectoryData();
  }, [showActive]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredEntries = directoryEntries.filter((entry) => {
    if (filter === 'All') return true;
    return entry.type === filter;
  });

  return (
    <div>
      {' '}
      <div
        style={{
          display: 'flex', // Establishes a flex container
          justifyContent: isSmallScreen ? 'center' : 'flex-start', // Centers on small screens, aligns to start otherwise
        }}
      >
        <FormControl
          variant="outlined"
          style={{
            marginBottom: '1rem',
            minWidth: 200,
            marginTop: isSmallScreen
              ? isDoctor(currentUser)
                ? '20px'
                : '60px'
              : '20px',
          }}
        >
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={filter}
            onChange={handleFilterChange}
            label="Filter"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Provider">Providers</MenuItem>
            <MenuItem value="Admin">Admins</MenuItem>
            <MenuItem value="Care Coordinator">Care Coordinators</MenuItem>
          </Select>
        </FormControl>
      </div>
      {currentUser?.roles.includes('ROLE_DOCTOR') ? (
        <Typography variant="h6"></Typography>
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={showActive}
              onChange={() => setShowActive(!showActive)}
              name="activeInactiveSwitch"
            />
          }
          label={showActive ? 'Active' : 'Inactive'}
        />
      )}
      {/** The below card for displaying the Users in the Directory */}
      <Grid2 container spacing={2} sx={{ paddingLeft: '1.3rem' }}>
        {filteredEntries.map((entry, index) => (
          <Grid2 size={{ xs: 12, sm: 2, md: 2 }} key={index}>
            <Card
              style={{
                minHeight: '400px', // Fixed height for the card
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                height="100"
                image={entry.imageUrl}
                onError={(e) => {
                  e.target.src =
                    'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                }}
                alt="User Photo"
              />
              <div
                style={{
                  flex: 1,
                  overflow: 'auto', // Enable scrolling if content exceeds height
                }}
              >
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="div"
                    align="center"
                    style={{
                      fontWeight: 'bold',
                      fontSize:
                        entry.name.length > 22
                          ? '0.8rem'
                          : entry.name.length > 20
                          ? '1.0rem'
                          : '1.1rem',
                    }}
                  >
                    {entry.name}
                  </Typography>

                  {entry.isActive && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: 'left', width: '100%' }}
                    >
                      <strong>Ext:</strong> {entry.ext}
                    </Typography>
                  )}

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    <strong>Mobile:</strong>{' '}
                    {entry.phone || entry.mobile_number}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    <strong>Address:</strong> {entry.address1}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    {entry.city}, {entry.state} {entry.zip}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
};

export default memo(DirectoryTable);
