import React, { useState, useEffect, useRef, memo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  FormControl,
  Grid2,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';
import BarCharts, { COLORS } from './UI/BarChart';
import { dashboardService } from '../services';
import moment from 'moment';
import PieCharts from './UI/PieCharts';
import StackBarCharts from './UI/StackBarChat';
import userService from '../services/user.service';
import eventBus from '../common/EventBus';
import InquiriesAndResult from '../Templates/Dashboard/InquiriesAndResultTab';
import NonConversionTab from '../Templates/Dashboard/NonConversionTab';
import CallLogsTab from '../Templates/Dashboard/CallLogsTab';
// add comment just to push and test

const insuranceList = [
  'Aetna',
  'BCBS',
  'CIGNA',
  'Hopkins EHP',
  'Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare',
  'Other',
];

const healthcareProvidersOptions = [
  'Blanche Kattie, NP',
  'Laura Bothe, NP',
  'Dr. Yaniv Berger (Internist)',
  'Dr. Francisca Bruney (Family Medicine)',
  'Dr. Makeida Koyi (Psychiatrist)',
  'Dr. Steven Miller (Internist)',
  'Other',
];

const reasonOptions = [
  'No Availability',
  'In-Office Only Request (COVID)',
  'Not Appropriate for VC',
  'Psychologist Only',
  'Other',
];

const referalOptions = [
  'psychcare therapist',
  'other healthcare provider',
  'insurance list',
  'word of mouth',
  'other please specify',
];

const reasonColor = [
  '#FF5733',
  '#5733FF',
  '#FF33A3',
  '#33A3FF',
  '#FF3366',
  '#3366FF',
  '#6633FF',
  '#33FFA3',
  '#33FF66',
  '#66FF33',
  '#FF6633',
  '#33A3FF',
  '#FF33FF',
  '#33FFFF',
  '#FF33CC',
  '#33CCFF',
  '#CC33FF',
  '#33FF33',
  '#FFCC33',
  '#33FF00',
  '#00FF33',
  '#33FF99',
  '#9933FF',
  '#FF9933',
  '#33FFCC',
  '#CC33FF',
  '#FF99CC',
  '#CC99FF',
  '#FFCC99',
  '#CCFF99',
  '#99CCFF',
  '#99FFCC',
  '#FF99FF',
  '#CCFFFF',
  '#FFCCFF',
  '#FFFF33',
  '#33FFFF',
  '#FFFF66',
  '#66FFFF',
  '#FFFF99',
  '#99FFFF',
  '#FF9933',
  '#33FF33',
  '#33CC33',
];
const child1Tabs = [
  { label: 'Type of Inquiries & Results', value: 'inquiries' },
  { label: 'Non-Conversion Reasons', value: 'non-conversion' },
  { label: 'Referral Sources', value: 'referral_sources' },
  {
    label: 'Non Conversion - Preferred Providers',
    value: 'non_conversion-preferred_providers',
  },
  { label: 'Appt Type & Location', value: 'appointment_type' },
  { label: 'Service Type - Appts', value: 'service_type_appts' },
  { label: 'Provider & New Appts', value: 'provider_new_ppts' },
];

const child2Tabs = [
  { label: 'All Calls', value: 'all_calls' },
  { label: 'New Client Inquirie (Ext 1 + Ext 11)', value: 'new_client' },
  { label: 'Cancellations (Ext 3)', value: 'cancellations' },
  { label: 'Outgoing Calls', value: 'outgoing' },
];

const transformData = (inputData, reasonOptions) => {
  const transformedData = {};
  inputData.forEach((item) => {
    const name = item.name;
    const reasonIndex = reasonOptions.indexOf(item.reason);
    if (reasonIndex >= 0) {
      if (!transformedData[name]) {
        transformedData[name] = {
          name,
        };
        for (let i = 1; i <= reasonOptions.length; i++) {
          transformedData[name][`value${i}`] = '0';
          transformedData[name][`labelForValue${i}`] = reasonOptions[i - 1];
        }
      }
      transformedData[name][`value${reasonIndex + 1}`] = item?.value
        ? item?.value
        : '1';
    }
  });
  const output = Object.values(transformedData);
  return output;
};

const Dashboard = () => {
  const xs9GridRef = useRef(null);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState('1');
  const [childValue, setChildValue] = React.useState('inquiries');
  const [date, setDate] = useState(moment());
  const [view, setView] = useState('appointments');
  const [loading, setLoading] = useState(false);
  const [secondData, setSecondData] = useState([]);
  const [xs9Width, setXs9Width] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();

  useEffect(() => {
    userService.getAllUsers().then(
      (response) => {
        let providers = response?.data?.users ?? [];
        providers = providers
          .filter((provider) => {
            return provider.roleId === 5;
          })
          .sort((provider1, provider2) => {
            const name1 = provider1?.username?.split(' ')[1] ?? '';
            const name2 = provider2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        setProvidersOptions(
          providers?.map((v, k) => v?.username?.toLowerCase())
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch('logout');
        }
      }
    );
  }, []);

  useEffect(() => {
    function handleResize() {
      if (xs9GridRef.current) {
        setXs9Width(xs9GridRef.current.clientWidth - 50);
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial width measurement
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname === '/dashboard/appointments') {
      setView('appointments');
      setChildValue('inquiries'); // Set to a valid value for appointments
    } else if (pathname === '/dashboard/call-logs') {
      setView('callLogs');
      setChildValue('all_calls'); // Set to a valid value for call logs
    }
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    if (view === 'appointments') {
      dashboardService
        .getStats({ type: childValue, date: date })
        .then((res) => {
          setData(res?.data?.stats);
          setLoading(false);
        });
    } else if (view === 'callLogs') {
      dashboardService
        .getCallLogs({ date: date })
        .then((res) => {
          console.log('Full Call Logs Response:', res);
          console.log('Call Logs Data:', res?.data?.stats);
          setData(res?.data?.stats);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching call logs:', error);
          setLoading(false);
        });
    }
  }, [view, childValue, date]);

  useEffect(() => {
    if (value === '1') {
      setLoading(true);

      dashboardService
        .getStats({ type: childValue, date: date })
        .then((res) => {
          setLoading(false);
          setData(res?.data?.stats);
          if (res?.data?.stats1) {
            if (childValue === 'inquiries') {
              setSecondData(res?.data?.stats1);
            } else {
              let testingData = res?.data?.stats1?.filter(
                (v, k) => v.service_group === 'Testing'
              );
              let therapyData = res?.data?.stats1?.filter(
                (v, k) => v.service_group === 'Therapy'
              );

              setSecondData({
                therapy: {
                  psychcareTherapist: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[0]
                    ),
                    providersOptions
                  ),
                  otherHealthCareProvider: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[1]
                    ),
                    healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                  ),
                  insuranceList: transformData(
                    therapyData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[2]
                    ),
                    insuranceList?.map((v, k) => v?.toLowerCase())
                  ),
                  // wordOfMouth:transformData(therapyData,providersOptions),
                  //otherPleaseSpecify:transformData(therapyData,providersOptions)
                },
                testing: {
                  psychcareTherapist: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[0]
                    ),
                    providersOptions
                  ),
                  otherHealthCareProvider: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[1]
                    ),
                    healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                  ),
                  insuranceList: transformData(
                    testingData?.filter(
                      (v, k) => v.aboutUsInfo === referalOptions[2]
                    ),
                    insuranceList?.map((v, k) => v?.toLowerCase())
                  ),
                  // wordOfMouth:transformData(testingData,providersOptions),
                  //otherPleaseSpecify:transformData(testingData,providersOptions)
                },
              });
            }
            //  console.log(transformData(testingData,referalOptions),"hereee")
          }
        });
      // non-conversion-result-for-referal
      // "Referred Out"
      if (childValue === 'non-conversion') {
        dashboardService
          .getStats({
            type: 'non-conversion-result',
            date: date,
          })
          .then((genericRes) => {
            setLoading(false);
            dashboardService
              .getStats({
                type: 'non-conversion-result-for-referal',
                date: date,
              })
              .then((referredOutRes) => {
                setLoading(false);
                setSecondData({
                  generic: genericRes?.data?.stats,
                  referred_out_testing: transformData(
                    referredOutRes?.data?.stats?.filter(
                      (v, k) => v.service_group === 'Testing'
                    ),
                    reasonOptions
                  ),
                  referred_out_threpy: transformData(
                    referredOutRes?.data?.stats?.filter(
                      (v, k) => v.service_group === 'Therapy'
                    ),
                    reasonOptions
                  ),
                });
              });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childValue, date]);

  useEffect(() => {
    if (value === '2') {
      setLoading(true);
      dashboardService.getCallLogs({ date: date }).then((res) => {
        setLoading(false);
        setData(res?.data?.stats);
      });
    }
  }, [value, date]);

  const handleChange = (event, newValue) => {
    setDate(moment());
    setData([]);
    if (newValue === '1') {
      setChildValue('inquiries');
    } else if (newValue === '2') {
      setChildValue('all_calls');
    } else if (newValue === '3') {
      setChildValue('1');
    }
    setValue(newValue);
  };
  const handleChangeChild = (event, newValue) => {
    setChildValue(newValue);
    if (value === '1') {
      setData([]);
    }
  };
  const CustomTooltipForReferredOut = ({
    active,
    payload,
    label,
    reasonOptions,
    type,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className=""
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            backgroundColor: 'black',
            cursor: 'pointer',
            border: '1px solid #e3e1e1',
            borderRadius: '3px',
            color: 'white',
            fontSize: '13px',
          }}
        >
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.name}
          </p>
          {type === 'referral_sources' ? (
            <>
              {' '}
              {reasonOptions?.map((v, k) => (
                <React.Fragment key={k}>
                  {payload[0]?.payload[`value${k + 1}`] !== '0' && (
                    <p
                      className="intro"
                      style={{ marginTop: '5px', textTransform: 'capitalize' }}
                    >
                      {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                      {payload[0]?.payload[`value${k + 1}`]}
                    </p>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              {reasonOptions?.map((v, k) => (
                <p
                  className="intro"
                  style={{ marginTop: '5px', textTransform: 'capitalize' }}
                  key={k}
                >
                  {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                  {payload[0]?.payload[`value${k + 1}`] ||
                    payload[0]?.payload[`customValue${k + 1}`]}
                </p>
              ))}
            </>
          )}
        </div>
      );
    }

    return null;
  };

  function capitalize(string) {
    if (string === null || string === undefined) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onChangeDate = (e) => {
    let startdate = moment();
    startdate = startdate.subtract(e.target.value, 'days');
    setDate(startdate);
  };

  const referalGraph = [
    {
      name: 'PsychCare Therapist Therapy',
      value: 'psychcareTherapist',
      type: 'therapy',
      options: providersOptions,
      childValue: true,
    },
    {
      name: 'Other Healthcare Provider Therapy',
      value: 'otherHealthCareProvider',
      type: 'therapy',
      options: healthcareProvidersOptions,
    },
    {
      name: 'Insurance List Therapy',
      value: 'insuranceList',
      type: 'therapy',
      options: insuranceList,
    },
    {
      name: 'PsychCare Therapist Testing',
      value: 'psychcareTherapist',
      type: 'testing',
      options: providersOptions,
    },
    {
      name: 'Other Healthcare Provider Testing',
      value: 'otherHealthCareProvider',
      type: 'testing',
      options: healthcareProvidersOptions,
      childValue: true,
    },
    {
      name: 'Insurance List Testing',
      value: 'insuranceList',
      type: 'testing',
      options: insuranceList,
    },
  ];

  const MobileMenuAppointments = () => (
    <Select
      value={childValue} // Bind the current child tab value for accurate display
      onChange={(e) => handleChangeChild(null, e.target.value)}
      style={{ width: '100%' }}
      sx={{ mr: 2 }}
    >
      {child1Tabs.map((tab, index) => (
        <MenuItem key={index} value={tab.value}>
          {tab.label}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <div style={{ width: '100%' }}>
        {view === 'appointments' && (
          <>
            {isMobile && (
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  marginTop: '0px',
                  marginBottom: '40px',
                  textAlign: 'center',
                  mt: 2, // Adds a top margin on mobile
                }}
              ></Typography>
            )}
            {isMobile ? (
              <div
                style={{
                  width: `100%`,
                  position: 'sticky',
                  top: 70,
                  background: 'white',
                }}
              >
                <MobileMenuAppointments />
                <div
                  style={{
                    width: `100%`,
                    marginTop: '15px',
                  }}
                >
                  <FormControl
                    style={{
                      width: `100%`,
                    }}
                  >
                    <InputLabel id="select-days-label">Select Days</InputLabel>
                    <Select
                      onChange={(e) => {
                        onChangeDate(e);
                      }}
                      defaultValue={0}
                      className="outline-none"
                      label="Select Days"
                      sx={{ fontSize: '14px' }}
                    >
                      <MenuItem value={0}>Today</MenuItem>
                      <MenuItem value={1}>Yesterday</MenuItem>
                      <MenuItem value={7}>Last 7 Days</MenuItem>
                      <MenuItem value={30}>Past Month</MenuItem>
                      <MenuItem value={180}>Past 6 Months</MenuItem>
                      <MenuItem value={365}>Past 1 Year</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            ) : (
              <Tabs
                className=""
                onChange={handleChange}
                value={value}
                sx={{
                  '& button.Mui-selected': { backgroundColor: '#ebeded' },
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
                aria-label="Tabs where each tab needs to be selected manually"
              ></Tabs>
            )}
            <Typography
              variant="h6"
              gutterBottom
              center
              sx={{ marginTop: '10px' }}
            >
              {value === '1' ? '' : 'All Call Logs'}
            </Typography>
            {value === '1' && (
              <div>
                <div style={{ marginTop: '20px', display: 'flex' }}>
                  <Grid2
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid2 container spacing={2}>
                      {!isMobile && ( // Conditionally render the child tabs only for non-mobile screens
                        <Grid2 size={{ xs: 2, md: 2 }}>
                          <Box sx={{ display: 'flex' }}>
                            <Tabs
                              orientation="vertical"
                              value={childValue}
                              onChange={handleChangeChild}
                              aria-label="Vertical tabs example"
                              sx={{
                                '& button.Mui-selected': {
                                  backgroundColor: '#ebeded',
                                },
                              }}
                            >
                              {child1Tabs?.map((v, k) => (
                                <Tab
                                  sx={{
                                    '&.Mui-selected': {
                                      outline: 'none',
                                    },
                                  }}
                                  label={v?.label}
                                  value={v?.value}
                                  key={v?.label + v?.value + k}
                                />
                              ))}
                            </Tabs>
                          </Box>
                        </Grid2>
                      )}
                      <Grid2
                        size={isMobile ? 12 : 9}
                        ref={xs9GridRef}
                        sx={{
                          mx: isMobile ? 2 : 0,
                          mt: isMobile ? 2 : 0,
                        }}
                      >
                        <div
                          style={{
                            width: isMobile
                              ? xs9Width?.toString()?.concat('px')
                              : '100%', // If it is mobile apply width in pixels otherwise in %
                            overflowX: 'auto',
                          }}
                        >
                          {loading && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '30px',
                                marginTop: '-60px',
                                width: '140px',
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}
                          {!isMobile && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'end',
                                width: `100%`,
                              }}
                            >
                              <FormControl
                                style={{
                                  width: `${isMobile ? '100%' : '150px'}`,
                                }}
                              >
                                <InputLabel id="select-days-label">
                                  Select Days
                                </InputLabel>
                                <Select
                                  onChange={(e) => {
                                    onChangeDate(e);
                                  }}
                                  defaultValue={0}
                                  className="outline-none"
                                  label="Select Days"
                                  sx={{ fontSize: '14px' }}
                                >
                                  <MenuItem value={0}>Today</MenuItem>
                                  <MenuItem value={1}>Yesterday</MenuItem>
                                  <MenuItem value={7}>Last 7 Days</MenuItem>
                                  <MenuItem value={30}>Past Month</MenuItem>
                                  <MenuItem value={180}>Past 6 Months</MenuItem>
                                  <MenuItem value={365}>Past 1 Year</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}

                          {childValue === 'inquiries' && (
                            <div>
                              <InquiriesAndResult
                                secondData={secondData}
                                data={data}
                                isMobile={isMobile}
                                capitalize={capitalize}
                                CustomTooltipForReferredOut={
                                  CustomTooltipForReferredOut
                                }
                                reasonColor={reasonColor}
                              />
                            </div>
                          )}

                          {childValue === 'non-conversion' && (
                            <>
                              <NonConversionTab
                                xs9Width={xs9Width}
                                data={data}
                                secondData={secondData}
                                CustomTooltipForReferredOut={
                                  CustomTooltipForReferredOut
                                }
                                isMobile={isMobile}
                                reasonOptions={reasonOptions}
                                reasonColor={reasonColor}
                                COLORS={COLORS}
                              />
                            </>
                          )}

                          {childValue ===
                            'non_conversion-preferred_providers' && (
                            <Paper>
                              <div
                                style={{ height: isMobile ? '400px' : '500px' }}
                              >
                                {window.innerWidth >= 768 ? (
                                  <BarCharts data={data} />
                                ) : (
                                  <PieCharts
                                    data={
                                      data?.map((v, k) => ({
                                        ...v,
                                        name: capitalize(v?.name),
                                        value: parseInt(v?.value),
                                      })) || []
                                    }
                                  />
                                )}
                              </div>
                            </Paper>
                          )}

                          {(childValue === 'referral_sources' ||
                            childValue === 'service_type_appts' ||
                            childValue === 'appointment_type' ||
                            childValue === 'provider_new_ppts') && (
                            <>
                              <Paper>
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Therapy
                                  </Typography>
                                  {window.innerWidth >= 768 ||
                                  !(
                                    childValue === 'referral_sources' ||
                                    childValue === 'service_type_appts' ||
                                    childValue === 'provider_new_ppts'
                                  ) ? (
                                    <BarCharts
                                      barWidth={150}
                                      data={data
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Therapy'
                                        )
                                        ?.map((v, k) => ({
                                          ...v,
                                          name:
                                            v?.name?.length > 35
                                              ? v?.name?.split(' ')[0]
                                              : capitalize(v?.name),
                                        }))}
                                      isResponsive={
                                        !(
                                          childValue === 'referral_sources' ||
                                          childValue === 'service_type_appts' ||
                                          childValue === 'provider_new_ppts'
                                        )
                                      }
                                    />
                                  ) : (
                                    <div style={{ height: '500px' }}>
                                      <PieCharts
                                        data={
                                          data
                                            ?.filter(
                                              (v, k) =>
                                                v.service_group === 'Therapy'
                                            )
                                            ?.map((v, k) => ({
                                              ...v,
                                              name: capitalize(v?.name),
                                              value: parseInt(v?.value),
                                            })) || []
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </Paper>
                              <Paper
                              // style={{
                              //   width:
                              //     data?.filter(
                              //       (v, k) => v.service_group === "Testing"
                              //     )?.length > 6
                              //       ? (
                              //           (data?.filter(
                              //             (v, k) => v.service_group === "Testing"
                              //           )?.length || 0) * 200
                              //         )
                              //           ?.toString()
                              //           ?.concat("px")
                              //       : "100%",
                              // }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    marginTop: '70px',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Testing
                                  </Typography>
                                  {window.innerWidth >= 768 ||
                                  !(
                                    childValue === 'referral_sources' ||
                                    childValue === 'service_type_appts' ||
                                    childValue === 'provider_new_ppts'
                                  ) ? (
                                    <BarCharts
                                      barWidth={150}
                                      data={data
                                        ?.filter(
                                          (v, k) =>
                                            v.service_group === 'Testing'
                                        )
                                        ?.map((v, k) => ({
                                          ...v,
                                          name:
                                            v?.name?.length > 35
                                              ? v?.name?.split(' ')[0]
                                              : capitalize(v?.name),
                                        }))}
                                      isResponsive={
                                        !(
                                          childValue === 'referral_sources' ||
                                          childValue === 'service_type_appts' ||
                                          childValue === 'provider_new_ppts'
                                        )
                                      }
                                    />
                                  ) : (
                                    <div style={{ height: '400px' }}>
                                      <PieCharts
                                        data={
                                          data
                                            ?.filter(
                                              (v, k) =>
                                                v.service_group === 'Testing'
                                            )
                                            ?.map((v, k) => ({
                                              ...v,
                                              name: capitalize(v?.name),
                                              value: parseInt(v?.value),
                                            })) || []
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </Paper>

                              {childValue === 'referral_sources' && (
                                <>
                                  {referalGraph?.map((v, k) => (
                                    <React.Fragment>
                                      <Paper>
                                        <div
                                          style={{
                                            height: '500px',
                                            overflowX: 'auto',
                                            paddingBottom: '50px',
                                            width: '100%',
                                            overflowY: 'hidden',
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            gutterBottom
                                            center
                                            sx={{
                                              marginTop: '10px',
                                              marginLeft: '5px',
                                            }}
                                          >
                                            {v?.name}
                                          </Typography>
                                          {window.innerWidth >= 768 ? (
                                            <StackBarCharts
                                              CustomTooltip={
                                                <CustomTooltipForReferredOut
                                                  type={
                                                    v?.childValue
                                                      ? childValue
                                                      : false
                                                  }
                                                  reasonOptions={v?.options}
                                                />
                                              }
                                              data={
                                                secondData?.[v?.type]?.[
                                                  v?.value
                                                ]
                                              }
                                            />
                                          ) : (
                                            <div style={{ height: '400px' }}>
                                              <PieCharts
                                                data={
                                                  secondData?.[v?.type]?.[
                                                    v?.value
                                                  ]?.map(
                                                    (graphdata, graphIndex) => {
                                                      let total = 0;
                                                      let name =
                                                        graphdata?.name;
                                                      // eslint-disable-next-line no-unused-vars
                                                      let c = v?.options?.map(
                                                        (
                                                          option,
                                                          optionIndex
                                                        ) => {
                                                          total =
                                                            total +
                                                            (parseInt(
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ]
                                                            ) || 0);
                                                          if (
                                                            parseInt(
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ]
                                                            ) > 0
                                                          ) {
                                                            name =
                                                              name +
                                                              ' ' +
                                                              graphdata?.[
                                                                `labelForValue${optionIndex}`
                                                              ] +
                                                              ' (' +
                                                              graphdata?.[
                                                                `value${optionIndex}`
                                                              ] +
                                                              ') ';
                                                          }
                                                          return null;
                                                        }
                                                      );
                                                      return {
                                                        value: total,
                                                        name,
                                                      };
                                                    }
                                                  ) || []
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Paper>
                                      {v?.value === 'psychcareTherapist' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: !isMobile
                                              ? 'center'
                                              : '',
                                            width: '100%',
                                          }}
                                        >
                                          {providersOptions?.map((v, k) => (
                                            <div
                                              style={{ display: 'flex' }}
                                              key={k}
                                            >
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '7px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {v?.value ===
                                        'otherHealthCareProvider' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                          }}
                                        >
                                          {healthcareProvidersOptions?.map(
                                            (v, k) => (
                                              <div
                                                style={{ display: 'flex' }}
                                                key={k}
                                              >
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {v}
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      reasonColor[k],
                                                    height: '10px',
                                                    marginTop: '7px',
                                                    width: '20px',
                                                    marginLeft: '10px',
                                                    marginRight: '20px',
                                                  }}
                                                ></div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}

                                      {v?.type === 'insuranceList' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                          }}
                                        >
                                          {insuranceList?.map((v, k) => (
                                            <div
                                              style={{ display: 'flex' }}
                                              key={k}
                                            >
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '7px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </div>
              </div>
            )}
          </>
        )}
        {view === 'callLogs' && (
          <CallLogsTab
            data={data}
            isMobile={isMobile}
            childValue={childValue}
            handleChangeChild={handleChangeChild}
            child2Tabs={child2Tabs}
            loading={loading}
            onChangeDate={onChangeDate}
          />
        )}
      </div>
    </>
  );
};

export default memo(Dashboard);
