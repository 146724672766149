import React, { useState, memo } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
} from '@mui/material';
import styles from '../../styles/Clients.module.css';

const insuranceOptions = [
  'Private Pay',
  'Aetna',
  'Aetna EAP',
  'Aetna Medicare Advantage',
  'BCBS',
  'CIGNA',
  'CIGNA EAP',
  'Johns Hopkins EHP',
  'Medical Assistance',
  'Medicare',
];

function QuestionnaireCancelModal({
  isQuestionnaireModalOpen,
  onCloseCancelModal,
  onSubmitCancelModal,
}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedInsurance, setSelectedInsurance] = useState({});

  const handleRadioCancelChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedInsurance(''); // Reset insurance selection when changing options
  };

  const handleInsuranceChange = (event) => {
    setSelectedInsurance(event.target.value);
  };

  const handleCancelSubmit = () => {
    onSubmitCancelModal(selectedOption, selectedInsurance);
    onCloseCancelModal(); // Close modal after submit
  };

  const handleGoBack = () => {
    setSelectedOption(''); // Reset the selected option to show both buttons
  };

  const handleCancel = () => {
    setSelectedOption(''); // Reset the selection
    setSelectedInsurance(''); // Reset the insurance selection
    onCloseCancelModal(); // Close modal
  };

  return (
    <Modal
      open={isQuestionnaireModalOpen}
      onClose={onCloseCancelModal}
      className={styles.modal}
    >
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4,
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select Reason To No Longer List Client In This List
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <RadioGroup value={selectedOption} onChange={handleRadioCancelChange}>
            {selectedOption !== 'option2' && (
              <FormControlLabel
                value="option1"
                control={<Radio />}
                label="Client No Longer Has Tricare or USFHP Insurance - And Instead Has the Following Insurance"
              />
            )}
            {selectedOption !== 'option1' && (
              <FormControlLabel
                value="option2"
                control={<Radio />}
                label="Client is no longer active"
              />
            )}
          </RadioGroup>
          {selectedOption === 'option1' && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel>Select Insurance</FormLabel>
              <Select
                value={selectedInsurance}
                onChange={handleInsuranceChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {insuranceOptions.map((insurance) => (
                  <MenuItem key={insurance} value={insurance}>
                    {insurance}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedOption === 'option2' && (
            <Typography sx={{ mt: 2 }}>
              Make sure to follow our discharge policy.
            </Typography>
          )}
        </Typography>

        <div>
          <ButtonGroup>
            {selectedOption && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
              >
                Go Back
              </Button>
            )}

            <Button variant="contained" color="error" onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={handleCancelSubmit}
              disabled={
                selectedOption === 'option1' && selectedInsurance === ''
              }
            >
              Submit
            </Button>
          </ButtonGroup>
        </div>
      </Box>
    </Modal>
  );
}

export default memo(QuestionnaireCancelModal);
