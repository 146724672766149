import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getAppointment = (userId, appointmentId) =>
  axios.get(`${API_URL}users/${userId}/appointments/${appointmentId}`, {
    headers: authHeader(),
  });

const getAppointments = (id) =>
  axios.get(`${API_URL}appointments`, { headers: authHeader() });

const postAppointment = (userId, doctorId, appointmentDate) =>
  axios.post(
    `${API_URL}users/${userId}/appointments`,
    { doctor_id: doctorId, appointment_date: appointmentDate },
    { headers: authHeader() }
  );

const deleteAppointment = (userId, appointmentId) =>
  axios.delete(
    `${API_URL}api/v1/users/${userId}/appointments/${appointmentId}`,
    { headers: authHeader() }
  );

const createAppointment = (body) =>
  axios.post(`${API_URL}appointments`, body, { headers: authHeader() });

const getAppointmentPreview = (id) =>
  axios.get(`${API_URL}getAppointmentPreview/${id}`, { headers: authHeader() });

const updateAppointment = (body) =>
  axios.post(`${API_URL}updateAppointment`, body, { headers: authHeader() });

const cancelAppointment = (body) =>
  axios.post(`${API_URL}cancelAppointment`, body, { headers: authHeader() });

const getProviderAppts = (
  step03FormValues,
  step05FormValues,
  step06FormValues
) =>
  axios.post(
    `${API_URL}getProviderAppts`,
    { step03FormValues, step05FormValues, step06FormValues },
    { headers: authHeader() }
  );

// eslint-disable-next-line
export default {
  getAppointment,
  getAppointments,
  postAppointment,
  deleteAppointment,
  createAppointment,
  getAppointmentPreview,
  updateAppointment,
  cancelAppointment,
  getProviderAppts,
};
