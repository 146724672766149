import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/definitions/`;

// Function to get all documents
const getAllDefinitions = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

// Function to get a specific document by ID
const getDefinitions = (id) => {
  return axios.get(API_URL + id, { headers: authHeader() });
};

// Function to search documents by name
const searchDefinitions = (name) => {
  return axios.get(API_URL + 'search', {
    params: { name },
    headers: authHeader(),
  });
};

// Function to create a new document
const createDefinition = (definitionData) => {
  return axios.post(API_URL, definitionData, { headers: authHeader() });
};

// Function to update an existing document
const updateDefinition = (id, definitionData) => {
  return axios.put(API_URL + id, definitionData, { headers: authHeader() });
};

// Function to delete a document
const deleteDefinition = (id) => {
  return axios.delete(API_URL + id, { headers: authHeader() });
};

// eslint-disable-next-line
export default {
  getAllDefinitions,
  getDefinitions,
  searchDefinitions,
  createDefinition,
  updateDefinition,
  deleteDefinition,
};
