import { useEffect } from 'react';
import { logout } from '../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MESSAGE } from '../actions/types';
import complianceService from '../services/compliance.service';

const useVerifyUserActivity = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch({
      type: SET_MESSAGE,
      payload:
        'You have been logged out due to inactivity. Please click on the login button to login again!',
    });
    dispatch(logout(currentUser)); // passing the currentUser here if someone removes the user object from localStorage
  };

  useEffect(() => {
    const sendData = async () => {
      const userData = localStorage.getItem('user');
      const lastActivity = localStorage.getItem('lastActivity');

      if (userData && lastActivity) {
        const response = await complianceService.verifyUserActivity({
          userData: userData,
          lastActivity: lastActivity,
        });

        if (response.data.inactive) {
          handleLogout();
        }
      } else if (currentUser?.id) {
        handleLogout();
      }
    };
    sendData();
    const intervalId = setInterval(sendData, 20000);
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useVerifyUserActivity;
