import { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import React from 'react';
import moment from 'moment';

const HistoryDialog = ({
  historyDialogOpen,
  handleCloseHistory,
  historyData,
}) => {
  return (
    <Dialog open={historyDialogOpen} onClose={handleCloseHistory}>
      <DialogTitle>History</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {historyData
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((historyDataObj, index) => {
              return (
                <React.Fragment key={index}>
                  {historyDataObj?.area} -{' '}
                  <b>
                    {' '}
                    {historyDataObj?.isSelectedUserSupervisee
                      ? 'Reviewed by Supervisor on:'
                      : 'Requested Change by supervisee on:'}
                  </b>{' '}
                  {moment(historyDataObj?.date).format('DD/MM/YY')} <br />
                  <b>
                    Previous Value:{' '}
                    {Array.isArray(historyDataObj?.previousValue) ? (
                      <span>[{historyDataObj?.previousValue.join(', ')}]</span>
                    ) : (
                      historyDataObj?.previousValue
                    )}{' '}
                    - Proposed Value:{' '}
                    {Array.isArray(historyDataObj?.currentValue) ? (
                      <span>[{historyDataObj?.currentValue.join(', ')}]</span>
                    ) : (
                      historyDataObj?.currentValue
                    )}
                    -
                  </b>
                  <b> Reason: </b>
                  {historyDataObj?.reason} <br />
                </React.Fragment>
              );
            })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseHistory}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(HistoryDialog);
