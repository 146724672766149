import { Button, Box, Modal, Typography } from '@mui/material';
import { Col, Row } from 'reactstrap';
import styles from '../../styles/UpdateAvailability.module.css';

const ConfirmAvailability = ({
  confirmModalOpen,
  setConfirmModalOpen,
  selectedProvider,
  type,
  note,
  service,
  setting,
  selectedLocation,
  selectedStartDate,
  holdCreationDate,
  selectedFrequency,
  frequencies,
  holdForClient,
  holdExpirationDateTime,
  handleConfirmAddAvailability,
}) => {
  return (
    <Modal
      open={confirmModalOpen}
      onClose={() => setConfirmModalOpen(false)}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
    >
      <Box className={styles.confirmModalBox}>
        <Typography
          id="confirm-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 4 }} // Added bottom margin
        >
          Confirm Details
        </Typography>

        {/* Summary Elements */}
        <Typography variant="subtitle1" gutterBottom>
          Provider: {selectedProvider?.username || 'Not selected'}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Type: {type}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Service: {service}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Setting: {setting}
        </Typography>
        {setting !== 'Remote Only' && (
          <Typography variant="subtitle1" gutterBottom>
            Location: {selectedLocation.join(', ')}
          </Typography>
        )}
        <Typography variant="subtitle1" gutterBottom>
          Starting Date:{' '}
          {selectedStartDate
            ? new Date(selectedStartDate).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })
            : 'Not Set'}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Frequency:{' '}
          {selectedFrequency.map((freq) => frequencies[freq]).join(', ')}
        </Typography>

        {/* Additional details for Hold type */}
        {type === 'Hold' && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Note: {note || 'Not Provided'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Initials of Client: {holdForClient || 'Not Provided'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Hold Creation Date:{' '}
              {holdCreationDate
                ? new Date(holdCreationDate).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 'Not Set'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Hold Expiration Date and Time:{' '}
              {holdExpirationDateTime
                ? new Date(holdExpirationDateTime).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 'Not Set'}
            </Typography>
          </>
        )}

        {/* Confirmation and Go Back Buttons */}
        <Row
          style={{
            margin: '30px 14px 14px',
            display: 'flex',
            justifyContent: 'centre',
            alignItems: 'centre',
            columnGap: 6,
          }}
          xs="12"
        >
          {' '}
          {/* Increased top margin */}
          <Col xs="6">
            <Button
              variant="contained"
              onClick={() => setConfirmModalOpen(false)}
            >
              GO BACK
            </Button>
          </Col>
          <Col xs="6">
            <Button
              variant="contained"
              onClick={() => {
                handleConfirmAddAvailability();
                setConfirmModalOpen(false);
              }}
            >
              CONFIRM
            </Button>
          </Col>
        </Row>
      </Box>
    </Modal>
  );
};
export default ConfirmAvailability;
