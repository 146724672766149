import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const createDockHealthTasks = (taskDetails) => {
  return axios.post(API_URL + 'dockHealth/createTask', taskDetails, {
    headers: authHeader(),
  });
};

const searchDockHealthTasks = (phone) =>
  axios.get(`${API_URL}searchDockHealthTasks/?phone=${phone}`, {
    headers: authHeader(),
  });

const getIncompleteDockHealthTasks = () => {
  const headers = authHeader();
  // Make request to your server-side API, not directly to DockHealth.
  return axios.get(`${API_URL}getIncompleteDockHealthTasks`, {
    headers: headers,
  });
};

// eslint-disable-next-line
export default {
  createDockHealthTasks,
  searchDockHealthTasks,
  getIncompleteDockHealthTasks,
};
