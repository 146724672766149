import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { memo } from 'react';

const AttestationDialog = ({
  confirmContent,
  therapy,
  testing,
  personalInfo,
  handleAttestationConfirm,
  setShow,
  show,
}) => {
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Attestation'}</DialogTitle>
      {/**The below is MUI class not css class */}
      <DialogContent className="DialogContent">
        <Typography variant="body1">
          {confirmContent({ therapy, testing, personalInfo })}
        </Typography>
      </DialogContent>
      <DialogActions className="DialogActions">
        <Button onClick={() => handleAttestationConfirm()}>Ok</Button>
        <Button onClick={() => setShow(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(AttestationDialog);
