import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { memo } from 'react';
import styles from '../../styles/ProviderConfig.module.css';

const getMDLicenseContentStyle = (stepNumber, currentMDLicenseStep) => {
  return {
    marginBottom: '20px',
    display: currentMDLicenseStep === stepNumber ? 'block' : 'none', // Only show content for the active step
  };
};

const getMDLicenseStepStyle = (stepNumber, currentMDLicenseStep) => {
  return {
    fontWeight: 'bold',
    marginBottom: '10px',
    color:
      currentMDLicenseStep === stepNumber
        ? 'rgba(0, 0, 0, 0.87)'
        : 'rgba(0, 0, 0, 0.2)', // Adjust opacity for faded effect
    marginLeft: stepNumber === 3 ? '16px' : '0px', // Indent Step 3 to align with others
  };
};

const RenewMdDialog = ({
  openMDLicModal,
  setOpenMDLicModal,
  setChangesMadeForEvents,
  mdExpirationDate,
  setMdExpirationDate,
  currentMDLicenseStep,
  setCurrentMDLicenseStep,
  changesMadeForEvents,
  expirationMDDateEmpty,
  mdVerificationLink,
  setLinkMDLicClicked,
  linkMDLicClicked,
  handleOnSubmit,
}) => {
  return (
    <Dialog
      open={openMDLicModal}
      onClose={() => setOpenMDLicModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}
      >
        Renew Maryland License
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
          }}
        >
          3 Simple & Quick Steps to Update License
        </Typography>
        {/* Adding the new instructional note with "Note:" in bold and red */}
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '14px',
            marginBottom: '10px',
          }}
        >
          <span style={{ fontWeight: 'bold', color: 'red' }}>Note:</span> If you
          haven't been able to renew your license yet but are in the process,
          please click the 'Cancel' button below and choose a renewal option
          that best suits your situation.
        </Typography>
        <Typography style={getMDLicenseStepStyle(1)}>
          Step 1 - Enter your updated license expiration date
        </Typography>
        <div style={getMDLicenseContentStyle(1, currentMDLicenseStep)}>
          <TextField
            fullWidth
            margin="dense"
            label="Expiration Date"
            type="date"
            value={mdExpirationDate}
            onChange={(e) => setMdExpirationDate(e.target.value)} // Just update the state here
            onBlur={() => {
              // Move to the next step only when the user exits the field
              if (mdExpirationDate.length === 10) {
                setChangesMadeForEvents({
                  ...changesMadeForEvents,
                  Maryland: true,
                });
                setCurrentMDLicenseStep(2);
              }
            }}
            onKeyPress={(e) => {
              // Move to the next step only when the user presses 'Enter'
              if (e.key === 'Enter' && mdExpirationDate.length === 10) {
                setChangesMadeForEvents({
                  ...changesMadeForEvents,
                  Maryland: true,
                });
                setCurrentMDLicenseStep(2);
              }
            }}
            componentsProps={{
              label: {
                shrink: true,
              },
            }}
            error={expirationMDDateEmpty}
            helperText={
              expirationMDDateEmpty ? 'Expiration date is required' : ''
            }
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: expirationMDDateEmpty ? 'red' : 'blue',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: expirationMDDateEmpty ? 'red' : 'blue',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'blue',
                },
              },
            }}
          />
        </div>
        <Typography style={getMDLicenseStepStyle(2)}>
          Step 2 - Verify the updated date you just entered matches the
          licensing website
        </Typography>

        <div style={getMDLicenseContentStyle(2, currentMDLicenseStep)}>
          <Typography>
            Click{' '}
            <a
              rel="noreferrer"
              href={mdVerificationLink || '#'}
              target="_blank"
              style={{ color: 'darkblue', textDecoration: 'underline' }}
              onClick={() => {
                setLinkMDLicClicked(true);
                setCurrentMDLicenseStep(3); // Move to step 3 when link is clicked
              }}
            >
              HERE
            </a>{' '}
            to open the licensing page in another tab so that you can verify the
            updated expiration date and once completed come back here.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography style={getMDLicenseStepStyle(3)}>
          Step 3 - Click the "Update" button below
        </Typography>
        <div style={getMDLicenseContentStyle(3, currentMDLicenseStep)}>
          <Typography style={{ marginLeft: '16px', marginBottom: '20px' }}>
            By Clicking "Update" below, I attest that the link above has my
            updated license information.
          </Typography>
        </div>
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}> */}
        <div className={styles.modal2buttonContainer}>
          <Button
            onClick={() => setOpenMDLicModal(false)}
            className={styles.cancel2button}
          >
            Cancel
          </Button>
          <Button
            // passing event and renew string on submit function
            onClick={(event) => handleOnSubmit(event, 'renew')}
            className={styles.submit2button}
            disabled={!mdExpirationDate || !linkMDLicClicked}
          >
            Update
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default memo(RenewMdDialog);
