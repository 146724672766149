import { memo } from 'react';
import styles from '../../styles/Clients.module.css';
import { Button, ButtonGroup } from '@mui/material';

const AllConfirmRosterModal = ({
  isVisible,
  onSubmit,
  setIsAllConfirmedModalVisible,
}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.confirmationModalBackdrop}>
      <div className={styles.confirmationModal}>
        <p>
          All clients have been accounted for. Click the "Submit" button below
          to finalize and confirm your Active Rosters for this month.
        </p>

        <div>
          <ButtonGroup>
            <Button
              variant="contained"
              color="error"
              onClick={() => setIsAllConfirmedModalVisible(false)}
            >
              Cancel
            </Button>

            <Button variant="contained" color="success" onClick={onSubmit}>
              Submit
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
export default memo(AllConfirmRosterModal);
