import React, { useCallback, useState, useEffect, memo } from 'react';
import { Grid2, TextField, Typography, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { isSelectedUserSupervisee } from '../../common/utility';
import styles from '../../styles/ProviderConfig.module.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { providerService } from '../../services';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ProviderInsuranceInfo = (props) => {
  const [npi_number, setNpiNumber] = React.useState('');
  const [caqh_number, setCaqhNumber] = React.useState('');
  const [aetna_provider_number, setAetnaProviderNumber] = React.useState('');
  const [aetna_effective_date, setAetnaEffectiveDate] = React.useState('');

  const [bcbs_provider_number, setBcbsProviderNumber] = React.useState('');
  const [bcbs_effective_date, setBcbsEffectiveDate] = React.useState('');

  const [cigna_provider_number, setCignaProviderNumber] = React.useState('');
  const [cigna_effective_date, setCignaEffectiveDate] = React.useState('');

  const [hopkins_ehp_provider_number, setHopkinsEhpProviderNumber] =
    React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [hopkins_ehp_effective_date, setHopkinsEhpEffectiveDate] =
    React.useState('');
  const [hopkinsEhpEffectiveDateValue, setHopkinsEhpEffectiveDateValue] =
    useState('');
  const [hopkins_usfhp_provider_number, setHopkinsUsfhpProviderNumber] =
    React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [hopkins_usfhp_effective_date, setHopkinsUsfhpEffectiveDate] =
    React.useState('');
  const [hopkinsUsfhpEffectiveDateValue, setHopkinsUsfhpEffectiveDateValue] =
    useState('');
  const [medicaid_provider_number, setMedicaidProviderNumber] =
    React.useState('');
  const [medicaid_effective_date, setMedicaidEffectiveDate] =
    React.useState('');

  const [medicare_provider_number, setMedicareProviderNumber] =
    React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [medicare_effective_date, setMedicareEffectiveDate] =
    React.useState('');
  const [tricare_provider_number, setTricareProviderNumber] =
    React.useState('');
  const [tricare_effective_date, setTricareEffectiveDate] = React.useState('');

  const [providerName, setProviderName] = React.useState('');
  const [insuranceFlags, setInsuranceFlags] = useState({});

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const isDoctorAdminSupervisor = (user) => {
    return (
      user &&
      user.roles &&
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setNpiNumber(props.selectedUserDetail?.npi_number);
    setCaqhNumber(props.selectedUserDetail?.caqh_number);
    setProviderName(
      props.selectedUserDetail?.provider_name ??
        props.selectedUserDetail?.username ??
        ''
    );
    setAetnaProviderNumber(props.selectedUserDetail?.aetna_provider_number);
    setBcbsProviderNumber(props.selectedUserDetail?.bcbs_provider_number);
    setCignaProviderNumber(props.selectedUserDetail?.cigna_provider_number);
    setHopkinsEhpProviderNumber(
      props.selectedUserDetail?.hopkins_ehp_provider_number
    );
    setHopkinsUsfhpProviderNumber(
      props.selectedUserDetail?.hopkins_usfhp_provider_number
    );
    setMedicaidProviderNumber(
      props.selectedUserDetail?.medicaid_provider_number
    );
    setMedicareProviderNumber(
      props.selectedUserDetail?.medicare_provider_number
    );
    setTricareProviderNumber(props.selectedUserDetail?.tricare_provider_number);
    setAetnaEffectiveDate(
      props.selectedUserDetail?.aetna_effective_date
        ? new Date(props.selectedUserDetail.aetna_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setBcbsEffectiveDate(
      props.selectedUserDetail?.bcbs_effective_date
        ? new Date(props.selectedUserDetail.bcbs_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setCignaEffectiveDate(
      props.selectedUserDetail?.cigna_effective_date
        ? new Date(props.selectedUserDetail.cigna_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setHopkinsEhpEffectiveDate(
      props.selectedUserDetail?.hopkins_ehp_effective_date
        ? new Date(props.selectedUserDetail.hopkins_ehp_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setHopkinsUsfhpEffectiveDate(
      props.selectedUserDetail?.hopkins_usfhp_effective_date
        ? new Date(props.selectedUserDetail.hopkins_usfhp_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setMedicaidEffectiveDate(
      props.selectedUserDetail?.medicaid_effective_date
        ? new Date(props.selectedUserDetail.medicaid_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setMedicareEffectiveDate(
      props.selectedUserDetail?.medicare_effective_date
        ? new Date(props.selectedUserDetail.medicare_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
    setTricareEffectiveDate(
      props.selectedUserDetail?.tricare_effective_date
        ? new Date(props.selectedUserDetail.tricare_effective_date)
            .toISOString()
            .split('T')[0]
        : ''
    );
  }, [props.selectedUserDetail]);

  useEffect(() => {
    const id = props.selectedUser?.id;

    if (id) {
      providerService
        .getTherapyConfig(props.selectedUser.id)
        .then((response) => {
          // Access the insurances from the 'therapy' subcategory
          const therapyData = response.data.therapy || {};
          const insurances = therapyData.insurances || [];
          const insurances_t = therapyData.insurances_t || [];

          // Access the insurances from the 'testingData' object
          const testingData = response.data.testing || {};
          const testingInsurances = testingData.insurances || [];

          // Check if BCBS is listed in any of the insurances and set flags accordingly
          const hasAetna =
            insurances.includes('Aetna') ||
            insurances_t.includes('Aetna') ||
            testingInsurances.includes('Aetna');
          const hasBCBS =
            insurances.includes('BCBS') ||
            insurances_t.includes('BCBS') ||
            testingInsurances.includes('BCBS');
          const hasCigna =
            insurances.includes('CIGNA') ||
            insurances_t.includes('CIGNA') ||
            testingInsurances.includes('CIGNA');
          const hasHopkinsEHP =
            insurances.includes('Johns Hopkins EHP') ||
            insurances_t.includes('Johns Hopkins EHP') ||
            testingInsurances.includes('Johns Hopkins EHP');
          const hasHopkinsUSFHP =
            insurances.includes('Johns Hopkins USFHP') ||
            insurances_t.includes('Johns Hopkins USFHP') ||
            testingInsurances.includes('Johns Hopkins USFHP');
          const hasMedicaid =
            insurances.includes('Medical Assistance') ||
            insurances_t.includes('Medical Assistance') ||
            testingInsurances.includes('Medical Assistance');
          const hasMedicare =
            insurances.includes('Medicare') ||
            insurances_t.includes('Medicare') ||
            testingInsurances.includes('Medicare');
          const hasTricare =
            insurances.includes('Tricare Standard/Indeminity (NOT Prime)') ||
            insurances_t.includes('Tricare Standard/Indeminity (NOT Prime)') ||
            testingInsurances.includes(
              'Tricare Standard/Indeminity (NOT Prime)'
            );

          // ... other insurance flags ...

          const insuranceFlags = {
            hasAetna,
            hasBCBS,
            hasCigna,
            hasHopkinsEHP,
            hasHopkinsUSFHP,
            hasMedicaid,
            hasMedicare,
            hasTricare,
          };

          setInsuranceFlags(insuranceFlags);
        })
        .catch((error) => {
          console.error('Error fetching therapy config:', error);
        });
    }
  }, [props.selectedUser]);

  const handleOnSubmit = async (event) => {
    try {
      showLoading();
      event.preventDefault();
      if (props?.selectedUser?.id) {
        if (
          props.selectedUserDetail?.provider_email &&
          npi_number &&
          providerName.length > 0
        ) {
          const response = await providerService.updateProviderDetails(
            props.selectedUser.id,
            {
              email: props.selectedUserDetail?.provider_email,
              inTesting: props.selectedUserDetail?.in_testing,
              inTherapy: props.selectedUserDetail?.in_therapy,
              supervisorEmail: props.selectedUserDetail?.supervisor_email,
              supervisorName: props.selectedUserDetail?.supervisor_name,
              licensedInOtherStates:
                props.selectedUserDetail?.licensed_in_other_states,
              degreesAndLicense: props.selectedUserDetail?.degrees_and_license,
              npi_number,
              caqh_number,
              aetna_provider_number,
              bcbs_provider_number,
              cigna_provider_number,
              hopkins_ehp_provider_number,
              hopkins_usfhp_provider_number,
              medicaid_provider_number,
              medicare_provider_number,
              tricare_provider_number,
              aetna_effective_date,
              bcbs_effective_date,
              cigna_effective_date,
              hopkins_ehp_effective_date,
              hopkins_usfhp_effective_date,
              medicaid_effective_date,
              medicare_effective_date,
              tricare_effective_date,
              providerName,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Provider details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          }

          enqueueSnackbar('Updated Provider ', {
            variant: 'success',
            timeout: 3000,
          });

          props.updateProviderDetail();
        }
      } else {
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    } finally {
      hideLoading();
    }
  };

  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the provider configuration is read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className={styles.providerNotice1}>
            The "Personal Info" section of the provider profile is read-only.
            Please reach out to Levi or Victoria if there is any information
            that you would like to update.
          </Typography>
        )}

      <div className={styles.tabsContentContainer}>
        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          <Grid2 container spacing={3} className={styles.gridItemTextField}>
            {
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="npi_number"
                  label="NPI #"
                  value={npi_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setNpiNumber(e.target.value);
                  }}
                />
              </Grid2>
            }

            {
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="caqh_number"
                  label="CAQH #"
                  value={caqh_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setCaqhNumber(e.target.value);
                  }}
                />
              </Grid2>
            }

            {insuranceFlags.hasAetna &&
              !isSelectedUserSupervisee(props.selectedUserDetail) && (
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                  <TextField
                    className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                    autoComplete="off"
                    variant="outlined"
                    id="aetna_provider_number"
                    label="Aetna Provider #"
                    value={aetna_provider_number}
                    disabled={props.isProvider}
                    onChange={(e) => {
                      setAetnaProviderNumber(e.target.value);
                    }}
                  />
                </Grid2>
              )}

            {insuranceFlags.hasAetna &&
              !isSelectedUserSupervisee(props.selectedUserDetail) && (
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                  <TextField
                    className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    id="aetna_effective_date"
                    label="Aetna Effective Date"
                    type="date"
                    value={aetna_effective_date}
                    disabled={props.isProvider}
                    onChange={(e) => {
                      const enteredValue = e.target.value;

                      // The value is already in "yyyy-MM-dd" format, no need to format again
                      setAetnaEffectiveDate(enteredValue); // Update the state with the formatted date
                    }}
                    componentsProps={{
                      label: {
                        shrink: true,
                      },
                    }}
                  />
                </Grid2>
              )}

            {insuranceFlags.hasBCBS && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="bcbs_provider_number"
                  label="BCBS Provider #"
                  value={bcbs_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setBcbsProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasBCBS && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="bcbs_effective_date"
                  label="BCBS Effective Date"
                  type="date"
                  value={bcbs_effective_date}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;

                    // The value is already in "yyyy-MM-dd" format, no need to format again
                    setBcbsEffectiveDate(enteredValue); // Update the state with the formatted date
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasCigna && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="cigna_provider_number"
                  label="CIGNA Provider #"
                  value={cigna_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setCignaProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}
            {insuranceFlags.hasCigna && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="cigna_effective_date"
                  label="Cigna Effective Date"
                  type="date"
                  value={cigna_effective_date}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    setCignaEffectiveDate(enteredValue);
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasHopkinsEHP && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="hopkins_ehp_provider_number"
                  label="Hopkins EHP Provider #"
                  value={hopkins_ehp_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setHopkinsEhpProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}
            {insuranceFlags.hasHopkinsEHP && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="hopkins_ehp_effective_date"
                  label="Hopkins EHP Effective Date"
                  type="date"
                  value={hopkinsEhpEffectiveDateValue}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    setHopkinsEhpEffectiveDateValue(enteredValue); // Update the displayed value
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasHopkinsUSFHP && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="hopkins_usfhp_provider_number"
                  label="Hopkins USFHP Provider #"
                  value={hopkins_usfhp_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setHopkinsUsfhpProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}
            {insuranceFlags.hasHopkinsUSFHP && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="hopkins_usfhp_effective_date"
                  label="Hopkins USFHP Effective Date"
                  type="date"
                  value={hopkinsUsfhpEffectiveDateValue}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    setHopkinsUsfhpEffectiveDateValue(enteredValue); // Update the displayed value
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasMedicaid && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="medicaid_usfhp_provider_number"
                  label="Medicaid Provider #"
                  value={medicaid_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setMedicaidProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasMedicaid && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="medicaid_effective_date"
                  label="Medicaid Effective Date"
                  type="date"
                  value={medicaid_effective_date}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;

                    // The value is already in "yyyy-MM-dd" format, no need to format again
                    setMedicaidEffectiveDate(enteredValue); // Update the state with the formatted date
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasMedicare && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="medicare_provider_number"
                  label="Medicare Provider #"
                  value={medicare_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setMedicareProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}
            {insuranceFlags.hasMedicare && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="medicare_effective_date"
                  label="Medicare Effective Date"
                  type="date"
                  value={medicare_effective_date}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    setMedicareEffectiveDate(enteredValue); // Update the displayed value
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}

            {insuranceFlags.hasTricare && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  autoComplete="off"
                  variant="outlined"
                  id="tricare_provider_number"
                  label="Tricare Provider #"
                  value={tricare_provider_number}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    setTricareProviderNumber(e.target.value);
                  }}
                />
              </Grid2>
            )}
            {insuranceFlags.hasTricare && (
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <TextField
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="tricare_effective_date"
                  label="Tricare Effective Date"
                  type="date"
                  value={tricare_effective_date}
                  disabled={props.isProvider}
                  onChange={(e) => {
                    const enteredValue = e.target.value;

                    // The value is already in "yyyy-MM-dd" format, no need to format again
                    setTricareEffectiveDate(enteredValue); // Update the state with the formatted date
                  }}
                  componentsProps={{
                    label: {
                      shrink: true,
                    },
                  }}
                />
              </Grid2>
            )}
          </Grid2>

          <Grid2
            container
            spacing={3}
            className={styles.gridItemTextField}
          ></Grid2>
          <Grid2 container spacing={3} className={styles.gridItemTextField}>
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <Button
                variant="contained"
                color="primary"
                className={styles.buttonWithMargin}
                onClick={handleOnSubmit}
                disabled={props.isProvider}
              >
                Save
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderInsuranceInfo);
