import React, { useState, useEffect, memo } from 'react';
import {
  FormControl,
  Button,
  IconButton,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import styles from '../../styles/ProviderConfig.module.css';
import { SchedulingInfoModal } from '../Modals';
import { careCoordinatorService } from '../../services';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const CareCoordinatorPracticeInfo = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [inHIPAA, setInHIPAA] = React.useState('');
  const [hrsPerWeek, setHrsPerWeek] = React.useState('');
  const [schedule, setSchedule] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [endDate, setEndDate] = React.useState(new Date('2025-12-31'));
  const [careCoordinatorStatus, setCareCoordinatorStatus] =
    React.useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newSchedule) => {
    setSchedule(newSchedule);
    handleCloseModal();
  };

  useEffect(() => {
    setInHIPAA(Boolean(props.selectedUserDetail?.hipaa) ?? '');
    setSchedule(props.selectedUserDetail?.schedule ?? '');
    setHrsPerWeek(props.selectedUserDetail?.hrs_p_week ?? '');
    setEndDate(props.selectedUserDetail?.date_end);
    setCareCoordinatorStatus(
      Boolean(props.selectedUserDetail?.is_active) ?? ''
    );
  }, [props.selectedUserDetail]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      props.showLoading();
      if (props?.selectedUser?.id) {
        if (
          props.selectedUserDetail?.care_coordinator_email &&
          hrsPerWeek &&
          schedule
        ) {
          const formattedSchedule = schedule.map((sch) => {
            const safeStartTime = new Date(sch.startTime);
            const safeEndTime = new Date(sch.endTime);

            return {
              days: sch.days,
              startTime: safeStartTime.toISOString(), // Safely format startTime
              endTime: safeEndTime.toISOString(), // Safely format endTime
              setting: sch.setting,
              collapsed: sch.collapsed,
            };
          });

          const response =
            await careCoordinatorService.updateCareCoordinatorDetails(
              props.selectedUser.id,
              {
                careCoordinatorEmail:
                  props.selectedUserDetail?.care_coordinator_email,
                hipaa: inHIPAA,
                hrs_p_week: hrsPerWeek,
                schedule: formattedSchedule, // Use the formatted schedule
              }
            );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('careCoordinator details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          } else {
            enqueueSnackbar('Updated careCoordinator details ', {
              variant: 'success',
              timeout: 3000,
            });
          }

          props.updateCareCoordinatorDetail();
        } else {
          enqueueSnackbar('Please select required fields.', {
            variant: 'error',
            timeout: 3000,
          });
        }
      } else {
        enqueueSnackbar('Please select care coordinator first ', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
      enqueueSnackbar('Failed to update details due to an error.', {
        variant: 'error',
        timeout: 3000,
      });
    } finally {
      props.hideLoading();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.tabsContentContainer}>
        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          <Grid2 size={{ xs: 3 }}>
            <FormControl
              className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              sx={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                HIPAA Workforce
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inHIPAA}
                onChange={(event) => {
                  setInHIPAA(event.target.value);
                }}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid2>

          {
            <Grid2 size={{ xs: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="hrsPerWeek"
                label="Hours Per Week"
                value={hrsPerWeek}
                onChange={(e) => {
                  setHrsPerWeek(e.target.value);
                }}
              />
            </Grid2>
          }

          {careCoordinatorStatus === false && (
            <Grid2 size={{ xs: 3 }}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="end_date"
                label="End Date"
                value={endDate}
                type="date"
                componentsProps={{
                  label: {
                    shrink: true,
                  },
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Grid2>
          )}

          <Grid2 size={{ xs: 12 }}>
            <Box
              sx={{
                border: '1px solid gray',
                borderRadius: '4px',
                padding: '16px',
                position: 'relative',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  position: 'absolute',
                  top: '-12px',
                  left: '16px',
                  backgroundColor: 'white',
                  padding: '0 8px',
                }}
              >
                Schedule
              </Typography>
              {schedule.length === 0 ? (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ marginBottom: '16px' }}
                >
                  No Saved Schedules
                </Typography>
              ) : (
                Array.isArray(schedule) &&
                schedule.map((sch, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid gray',
                      padding: '10px',
                      marginBottom: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography variant="body2">
                      <strong>Days:</strong> {sch.days.join(', ')}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Time:</strong>{' '}
                      {new Date(sch.startTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}{' '}
                      -{' '}
                      {new Date(sch.endTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Setting:</strong> {sch.setting}
                    </Typography>
                  </Box>
                ))
              )}
              <Box sx={{ textAlign: 'center' }}>
                {schedule.length === 0 ? (
                  <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    sx={{
                      backgroundColor: 'blue',
                      color: 'white',
                      marginTop: '16px',
                    }}
                  >
                    Create Schedule
                  </Button>
                ) : (
                  <IconButton
                    onClick={handleOpenModal}
                    sx={{ marginTop: '16px' }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <SchedulingInfoModal
              open={isModalOpen}
              onClose={handleCloseModal}
              onSave={handleSave}
              schedule={Array.isArray(schedule) ? schedule : []}
            />
          </Grid2>

          <Grid2 size={{ xs: 3 }}>
            <Button
              className={styles.buttonWithMargin}
              variant="contained"
              color="primary"
              onClick={handleOnSubmit}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(CareCoordinatorPracticeInfo);
