import { memo } from 'react';
import styles from '../../styles/Clients.module.css';
import { Button, ButtonGroup } from '@mui/material';

const ConfirmRosterModal = ({
  isVisible,
  onConfirm,
  onCancel,
  modalMessageType,
  getUserInformationStatus,
  selectedOption,
  handleOptionChange,
}) => {
  if (!isVisible) return null;

  // This function determines the message to display based on the modalMessageType
  const getMessage = () => {
    switch (modalMessageType) {
      case 'agreed_termination':
        return (
          <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                marginBottom: '10px',
              }}
            >
              Confirm That:
            </p>
            <ol style={{ marginLeft: '20px', marginBottom: '20px' }}>
              <li style={{ marginBottom: '5px' }}>
                <strong>#1 Termination information</strong> is documented in TA
                progress notes, including (but not limited to):
                <ol type="A" style={{ marginLeft: '20px' }}>
                  <li style={{ marginBottom: '5px' }}>
                    A. Reason(s) for termination
                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    B. Any additional referrals made (if appropriate)
                  </li>
                </ol>
              </li>
              <li style={{ marginBottom: '20px' }}>
                <strong>#2 Client Status</strong> Set to Inactive in TA
              </li>
            </ol>
            {/*The below function is responsible for checking whether user belongs to both therapy and testing*/}
            {getUserInformationStatus() && (
              <div style={{ borderTop: '1px solid #ccc', paddingTop: '15px' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginBottom: '10px',
                  }}
                >
                  Note: Your provider profile is registered for both Therapy and
                  Testing. Which service did you provide for this client? Please
                  select:
                </p>
                <form>
                  <label style={{ marginRight: '30px', fontSize: '14px' }}>
                    <input
                      type="radio"
                      value="Therapy"
                      checked={selectedOption === 'Therapy'}
                      onChange={handleOptionChange}
                      style={{ marginRight: '10px' }}
                    />
                    Therapy
                  </label>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      type="radio"
                      value="Testing"
                      checked={selectedOption === 'Testing'}
                      onChange={handleOptionChange}
                      style={{ marginRight: '10px' }}
                    />
                    Testing
                  </label>
                </form>
              </div>
            )}
          </div>
        );

      case 'awol':
        return (
          <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              Confirm your AWOL Selection
            </p>

            <div style={{ marginTop: '10px', fontWeight: 'normal' }}>
              <p style={{ marginBottom: '5px' }}>
                <i>
                  Upon confirmation, (1) An admin will be notified to mail an
                  AWOL letter to the client. (2) The AWOL letter will be be
                  uploaded to the Document section in TA (3) The client will be
                  placed in the Discharge in Progress (DIP) section for later
                  review.
                </i>
              </p>
            </div>
          </div>
        );

      default:
        return (
          <>
            <p>Please make this client is Inactive in TA.</p>
            <p>Are you sure you want to confirm this selection?</p>
          </>
        );
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        {getMessage()}

        <div>
          <ButtonGroup>
            <Button
              variant="contained"
              color="error" // Use MUI's predefined color options
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button variant="contained" color="success" onClick={onConfirm}>
              Confirm
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
export default memo(ConfirmRosterModal);
