import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ResponsiveTabs = ({
  tabs,
  activeTab,
  onChange,
  useUniqueValues = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    onChange(event, newValue);
  };

  const handleSelectChange = (event) => {
    onChange(event, event.target.value);
  };

  // If the screen size is small, render a dropdown instead of tabs
  // Filter tabs based on their condition
  const visibleTabs = tabs.filter((tab) =>
    tab.condition === undefined ? true : tab.condition
  );
  if (isSmallScreen) {
    return (
      <Box sx={{ minWidth: 120, maxWidth: '100%', marginBottom: 2 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="responsive-tab-select-label">Select Tab</InputLabel>
          <Select
            labelId="responsive-tab-select-label"
            id="responsive-tab-select"
            sx={{
              width: '100%', // Adjust width as necessary
              border: '3px solid #0033cc', // Dark blue border, thicker at 3px
              borderRadius: '4px', // Optional: if you want rounded corners
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Hide the default border
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#0033cc', // Dark blue border on hover, 3px to match normal state
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#0033cc', // Keep dark blue border when focused
                borderWidth: '3px', // Consistent thickness with normal state
              },
            }}
            value={activeTab}
            onChange={handleSelectChange}
            label="Select Tab"
          >
            {visibleTabs.map((tab) => (
              <MenuItem
                key={useUniqueValues ? tab.value : tab.index}
                value={useUniqueValues ? tab.value : tab.index}
              >
                {tab.label || tab.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      aria-label="responsive tabs"
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      {visibleTabs.map((tab) => (
        <Tab
          key={useUniqueValues ? tab.value : tab.index}
          label={tab.label || tab.title}
          value={useUniqueValues ? tab.value : tab.index}
        />
      ))}
    </Tabs>
  );
};

ResponsiveTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired, // Unique identifier for the tab
      title: PropTypes.string.isRequired, // Title for display in Tabs and Select
      label: PropTypes.node, // Optional JSX for more complex labels
      condition: PropTypes.bool, // Optional condition to display the tab
    })
  ).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  useUniqueValues: PropTypes.bool, // Determines whether to use 'value' or 'index' as identifiers
};

ResponsiveTabs.defaultProps = {
  useUniqueValues: false,
};

export default ResponsiveTabs;
