import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../actions/scheduling';

const TopBanner = ({ showBanner, setShowBanner }) => {
  const dispatch = useDispatch();

  return (
    <div style={{ position: 'sticky', top: 38, zIndex: 100 }}>
      {showBanner && (
        <div>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'purple',
              padding: '10px 0',
            }}
          >
            <Toolbar>
              <Typography
                variant="body2"
                style={{
                  flexGrow: 1,
                  textAlign: 'left', // Align text to the left
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                <span>
                  Notice: This Module has fields that have been previously
                  completed.
                </span>
              </Typography>
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'white', // Button background color
                  color: 'purple', // Button text color
                  marginRight: '1em',
                }}
                onClick={() => {
                  setShowBanner(false);
                }}
              >
                Dismiss
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'white',
                  color: 'purple',
                }}
                onClick={async () => {
                  setShowBanner(false);
                  dispatch(clearFormData());
                  await new Promise((resolve) => setTimeout(resolve, 1000)); // added a delay to clear the store and then reload the page
                  window.location.reload();
                }}
              >
                Clear Fields
              </Button>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default TopBanner;
