import React, { useEffect, useState, memo } from 'react';
import {
  riskTherapyAlertMapping,
  riskTestingAlertMapping,
} from '../../common/utility';
import { useSelector, useDispatch } from 'react-redux';
import { saveFormData } from '../../actions/scheduling';
import styles from '../../styles/SchedulingStep03B.module.css';
import {
  Grid2,
  TextField,
  Box,
  Autocomplete,
  FormControl,
  Typography,
} from '@mui/material';

const yesNoOptions = ['', 'No', 'Yes'];

const selfHarmOptions = ['Virtual Only', 'In-Person Only'];
const suicidalThoughtsTypeOptions = [
  'Suicidal Ideation (Passive)',
  'Suicidal Ideation (Active)',
];
const suicidalThoughtsTimeOptions = [
  'More than 6 months ago',
  'Less Than 6 Months Ago',
];
const suicidalThoughtsTherapyOptions = ['Virtual Only', 'In-Person Only'];

const SchedulingStep03B = (props) => {
  const {
    suicidalThoughts,
    selfHarm,
    setSelfHarm,
    psychiatricHospitalizationHistory,
    setPsychiatricHospitalizationHistory,
    serviceGroup,
    handleSuicidalThoughtType,
    clearRiskFilter,
    handleAllQuestions,
    riskQuestionDB,
    setRiskQuestionDB,
  } = props;

  const state = useSelector((state) => state.scheduling);
  const dispatch = useDispatch();
  const [suicidalThoughtsType, setSuicidalThoughtsType] = useState(
    props?.step03BFormValues?.suicidalThoughtsType || ''
  );
  const [suicidalThoughtsTime, setSuicidalThoughtsTime] = useState(
    props?.step03BFormValues?.suicidalThoughtsTime || ''
  );
  const [suicidalThoughtsTherapy, setSuicidalThoughtsTherapy] = useState(
    props?.step03BFormValues?.suicidalThoughtsTherapy || ''
  );
  const [suicidalThoughtsHistory, setSuicidalThoughtsHistory] = useState(
    props?.step03BFormValues?.suicidalThoughtsHistory || ''
  );
  const [selfHarmHistory, setSelfHarmHistory] = useState(
    props?.step03BFormValues?.selfHarmHistory || ''
  );
  const [selfHarmChange, setSelfHarmChange] = useState(
    props?.step03BFormValues?.selfHarm || ''
  );
  const [psycChange, setPsycChange] = useState(
    props?.step03BFormValues?.psycChange || ''
  );

  useEffect(() => {
    setRiskQuestionDB({
      ...riskQuestionDB,
      'History of Suicidal Thoughts': suicidalThoughtsHistory,
      'Suicidal Thoughts Details': suicidalThoughtsType,
      'Time of Last Suicidal Thought': suicidalThoughtsTime,
      'What Type of Therapy Setting Are You Looking For?':
        suicidalThoughtsTherapy,
      'History of Self-Harm Within The Last 6 Months': selfHarmHistory,
      'What Type of Setting for Therapy Are You Looking For?': selfHarm,
      'History of Psychiatric Hospitalization Within Last 6 Months':
        psychiatricHospitalizationHistory,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    suicidalThoughtsHistory,
    suicidalThoughtsType,
    suicidalThoughtsTime,
    suicidalThoughtsTherapy,
    selfHarmHistory,
    selfHarm,
    psychiatricHospitalizationHistory,
  ]);

  useEffect(() => {
    if (
      suicidalThoughtsType &&
      suicidalThoughtsHistory &&
      suicidalThoughtsTime &&
      suicidalThoughtsTherapy &&
      selfHarmHistory &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'No' &&
      selfHarmHistory === 'No' &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'No' &&
      selfHarmHistory === 'Yes' &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType !== 'Suicidal Ideation (Active)' &&
      suicidalThoughtsType != null &&
      suicidalThoughtsType !== '' &&
      selfHarmHistory === 'No' &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType !== 'Suicidal Ideation (Active)' &&
      suicidalThoughtsType != null &&
      suicidalThoughtsType !== '' &&
      selfHarmHistory === 'Yes' &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime === 'More than 6 months ago' &&
      suicidalThoughtsTherapy != null &&
      suicidalThoughtsTherapy !== '' &&
      selfHarmHistory === 'No' &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime === 'More than 6 months ago' &&
      suicidalThoughtsTherapy != null &&
      suicidalThoughtsTherapy !== '' &&
      selfHarmHistory === 'Yes' &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime &&
      suicidalThoughtsTherapy &&
      selfHarmHistory === 'Yes' &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime === 'Less Than 6 Months Ago' &&
      selfHarmHistory === 'Yes' &&
      selfHarmChange &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime &&
      suicidalThoughtsTherapy &&
      selfHarmHistory === 'No' &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else if (
      suicidalThoughtsHistory &&
      suicidalThoughtsHistory === 'Yes' &&
      suicidalThoughtsType === 'Suicidal Ideation (Active)' &&
      suicidalThoughtsTime === 'Less Than 6 Months Ago' &&
      selfHarmHistory === 'No' &&
      psycChange
    ) {
      handleAllQuestions(true);
    } else {
      handleAllQuestions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    suicidalThoughtsType,
    suicidalThoughtsHistory,
    suicidalThoughtsTime,
    suicidalThoughtsTherapy,
    selfHarmHistory,
    selfHarmChange,
    psycChange,
  ]);

  const handleSuicidalThoughtsTypeChange = (event, value) => {
    setSuicidalThoughtsType(value);
    handleSuicidalThoughtType(value);
    checkForAlert(
      serviceGroup,
      value,
      selfHarm,
      psychiatricHospitalizationHistory
    );
    if (value == null) {
      clearRiskFilter([1]); // have to execute clear function
    }
    state.suicidalThoughtsType = value;
    dispatch(saveFormData(state));
  };

  const handleSuicidalThoughtsTimeChange = (event, value) => {
    if (value === 'Less Than 6 Months Ago') {
      alert(
        'Please note that the filter only displays providers comfortable working with this risk element. Please make sure you ONLY offer/select In-Person appointments as the practice policy is not to have virtual sessions when this risk element is present'
      );
      props.handleSettingChange(['Any Setting', 'In-Person Only']);
    } else if (
      (suicidalThoughtsTherapy == null || suicidalThoughtsTherapy === '') &&
      (selfHarmChange == null || selfHarmChange === '') &&
      (psycChange == null || psycChange === '' || psycChange === 'No')
    ) {
      props.handleSettingChange(null);
    }

    handleSuicidalThoughtType(value);
    setSuicidalThoughtsTime(value);

    if (value == null) {
      clearRiskFilter([4]); // have to execute clear function
    }
    state.suicidalThoughtsTime = value;
    dispatch(saveFormData(state));
  };

  const handleSuicidalThoughtsTherapyChange = (event, value) => {
    if (value === 'Virtual Only') {
      props.handleSettingChange(['Any Setting', 'Remote Only']);
    } else if (value === 'In-Person Only') {
      props.handleSettingChange(['Any Setting', 'In-Person Only']);
    } else if (
      value == null &&
      (setSelfHarm !== '' || setSelfHarm != null) &&
      (psycChange !== '' || psycChange != null)
    ) {
      props.handleSettingChange(null);
    }
    if (psycChange === 'Yes' && value !== null) {
      value = 'In-Person Only';
    }
    if (
      value !== selfHarmChange &&
      value !== null &&
      selfHarmChange !== '' &&
      selfHarmChange !== null
    ) {
      alert(
        'You cannot select different therapy type from the below therapy! '
      );
      value = selfHarmChange;
    }
    setSuicidalThoughtsTherapy(value);
    handleSuicidalThoughtType(value);

    // Add your alert condition here.
    if (value === 'Virtual Only' || value === 'In-Person Only') {
      alert(
        'Please note that In Person or Virtual Selection in the Risk Questions do not necessarily display Appointments of In Person Only or Virtual Only. Please double check if using calendar integration the setting of the session'
      );
    }

    if (value == null) {
      clearRiskFilter([3, 2]); // have to execute clear function
    }
    state.suicidalThoughtsTherapy = value;
    dispatch(saveFormData(state));
  };

  const handleSelfHarmChange = (event, value) => {
    if (psycChange === 'Yes' && value !== null) {
      value = 'In-Person Only';
    }
    if (
      value !== suicidalThoughtsTherapy &&
      value !== null &&
      suicidalThoughtsTherapy !== '' &&
      suicidalThoughtsTherapy !== null
    ) {
      alert('You cannot select different therapy type from the previous one! ');
      value = suicidalThoughtsTherapy;
    }
    if (
      suicidalThoughtsTime === 'Less Than 6 Months Ago' &&
      suicidalThoughtsTime !== null &&
      suicidalThoughtsTime !== ''
    ) {
      alert('You cannot select virtual here');
      value = 'In-Person Only';
    }
    setSelfHarmChange(value);
    if (value === 'Virtual Only' || value === 'In-Person Only') {
      alert(
        'Please note that In Person or Virtual Selection in the Risk Questions do not necessarily display Appointments of In Person Only or Virtual Only. Please double check if using calendar integration the setting of the session'
      );
    }

    if (value === 'Virtual Only') {
      handleSuicidalThoughtType('harmvirtual');
    } else if (value === 'In-Person Only') {
      handleSuicidalThoughtType('harminperson');
    }

    if (value === 'Virtual Only') {
      props.handleSettingChange(['Any Setting', 'Remote Only']);
    } else if (value === 'In-Person Only') {
      props.handleSettingChange(['Any Setting', 'In-Person Only']);
    } else if (
      value == null &&
      (suicidalThoughtsTherapy === '' || suicidalThoughtsTherapy == null)
    ) {
      props.handleSettingChange(null);
    }

    setSelfHarm(value);
    checkForAlert(
      serviceGroup,
      suicidalThoughts,
      value,
      psychiatricHospitalizationHistory
    );

    if (value == null) {
      clearRiskFilter([6, 5]);
      // have to execute clear function
    }
    state.selfHarm = value;
    dispatch(saveFormData(state));
  };

  const checkForAlert = (
    serviceGroup,
    suicidalThoughts,
    selfHarm,
    psychiatricHospitalization
  ) => {
    let alertMapping;

    if (serviceGroup === 'Therapy') {
      alertMapping = riskTherapyAlertMapping;
    } else {
      alertMapping = riskTestingAlertMapping;
    }

    checkForQuestionAlert(alertMapping, 'Suicidal Thoughts', suicidalThoughts);
    checkForQuestionAlert(alertMapping, 'Self-harm', selfHarm);
    checkForQuestionAlert(
      alertMapping,
      'Psychiatric Hospitalization',
      psychiatricHospitalization
    );
  };
  const handlePsychiatricChange = (event, value) => {
    setPsycChange(value);
    if (value === 'Yes') {
      handleSuicidalThoughtType('psyhistory');
      alert(
        'Please note that the filter only displays providers comfortable working with this risk element. Please make sure you ONLY offer/select In-Person appointments as the practice policy is not to have virtual sessions when this risk element is present'
      ); // replace with your desired alert message

      if (
        suicidalThoughtsTherapy !== null &&
        suicidalThoughtsTherapy !== '' &&
        selfHarmChange != null &&
        selfHarmChange !== '' &&
        suicidalThoughtsTherapy !== 'In-Person Only' &&
        selfHarmChange !== 'In-Person Only'
      ) {
        setSuicidalThoughtsTherapy('In-Person Only');
        handleSuicidalThoughtType('In-Person Only');

        setSelfHarmChange('In-Person Only'); // In-Person Only
        handleSuicidalThoughtType('harminperson');
        setSelfHarm('In-Person Only');
      }
    }
    setPsychiatricHospitalizationHistory(value);

    if (
      value === 'No' &&
      (suicidalThoughtsTherapy == null || suicidalThoughtsTherapy === '') &&
      (selfHarmChange == null || selfHarmChange === '')
    ) {
      props.handleSettingChange(null);
    } else if (value === 'Yes') {
      props.handleSettingChange(['Any Setting', 'In-Person Only']);
    } else if (
      value == null &&
      (suicidalThoughtsTherapy === '' || suicidalThoughtsTherapy == null)
    ) {
      props.handleSettingChange(null);
    }
    if (value == null) {
      clearRiskFilter([7]); // have to execute clear function
    }
  };

  const checkForQuestionAlert = (alertMapping, question, answer) => {
    const alertObj = alertMapping.find((obj) => obj.question === question);
    if (answer === 'Yes' && alertObj && alertObj.alert !== '') {
      alert(alertObj.alert);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        <Grid2 container spacing={3} style={{ marginTop: '-115px' }}>
          <Grid2 size={{ xs: 12 }}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="suicidal-thoughts-history-select"
                value={suicidalThoughtsHistory}
                onChange={(event, value) => {
                  setSuicidalThoughtsHistory(value);
                  state.suicidalThoughtsHistory = value;
                  dispatch(saveFormData(state));
                }}
                options={yesNoOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="History of Suicidal Thoughts"
                    error={!suicidalThoughtsHistory}
                  />
                )}
              />
            </FormControl>

            {suicidalThoughtsHistory === 'Yes' && (
              <Box mt={2}>
                <FormControl fullWidth>
                  <Typography variant="body2" color="textSecondary">
                    Passive Suicidal Thoughts e.g. Sometimes I wish I didn't
                    wake up in the morning VS Active e.g. I was thinking about
                    killing myself
                  </Typography>
                  <Autocomplete
                    fullWidth
                    value={suicidalThoughtsType}
                    onChange={handleSuicidalThoughtsTypeChange}
                    options={suicidalThoughtsTypeOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Suicidal Thoughts Details"
                        // helperText="Passive Suicidal Thoughts e.g. Sometimes I wish I didn't wake up in the morning VS Active e.g. I was thinking about jumping off the bridge next to my house"
                        error={!suicidalThoughtsType}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}

            {suicidalThoughtsType === 'Suicidal Ideation (Active)' && (
              <Box mt={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    value={suicidalThoughtsTime}
                    onChange={handleSuicidalThoughtsTimeChange}
                    options={suicidalThoughtsTimeOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Time of Last Suicidal Thought"
                        error={!suicidalThoughtsTime}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}

            {suicidalThoughtsTime === 'More than 6 months ago' && (
              <Box mt={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    value={suicidalThoughtsTherapy}
                    onChange={handleSuicidalThoughtsTherapyChange}
                    options={suicidalThoughtsTherapyOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="What Type of Therapy Setting Are You Looking For?"
                        error={!suicidalThoughtsTherapy}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="self-harm-history-select"
                value={selfHarmHistory}
                onChange={(event, value) => {
                  setSelfHarmHistory(value);
                  state.selfHarmHistory = value;
                  dispatch(saveFormData(state));
                }}
                options={yesNoOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="History of Self-Harm Within The Last 6 Months"
                    error={!selfHarmHistory}
                  />
                )}
              />
            </FormControl>

            {selfHarmHistory === 'Yes' && (
              <Box mt={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    id="self-harm-details-select"
                    value={selfHarm}
                    onChange={handleSelfHarmChange}
                    options={selfHarmOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="What Type of Setting for Therapy Are You Looking For?"
                        error={!selfHarm}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="psychiatric-hospitalization-history-select"
                value={psychiatricHospitalizationHistory}
                onChange={(event, value) => {
                  handlePsychiatricChange(event, value);
                  state.psychiatricHospitalizationHistory = value;
                  dispatch(saveFormData(state));
                }}
                options={yesNoOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="History of Psychiatric Hospitalization Within Last 6 Months"
                    error={!psychiatricHospitalizationHistory}
                  />
                )}
              />
            </FormControl>
          </Grid2>

          {/* Insert other Grid2 items here as needed */}
        </Grid2>
      </div>
    </React.Fragment>
  );
};
export default memo(SchedulingStep03B);
