import React, { useEffect, useState, memo } from 'react';
import {
  Tabs,
  Tab,
  Button,
  Box,
  Typography,
  Grid2,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import {
  PublishedWithChanges as PublishedWithChangesIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { driveService } from '../../services';
import FilesMapping from './FilesMapping';
import { clearLoading, setLoading } from '../../actions/loading';

let defaultCols = [
  { key: 'fileName', label: 'File Name', gridSpan: 5 },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    gridSpan: 5,
    getData: (c) => moment.utc(c?.createdAt).format('MM-DD-YYYY'),
  },
];
const parentTabs = [{ label: 'CURRENT', value: '1' }];

function CvComponent({ selectedUser, selectedUserDetail, setTriggerAPICall }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = useState('1');
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childValue, setChildValue] = useState('cv');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [listArr, setListArr] = useState([
    {
      type: 'cv',
      fileStartingName: 'CV',
      cv: true,
      title: 'CV',
      subTitle: 'CV Card',
      folderID: '',
      imagePreview: '',
    },
  ]);

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    driveService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then(async (res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          let { current_cv_id } = res?.data?.drive_details;

          let lArray = [
            {
              type: 'cv',
              fileStartingName: 'CV',
              cv: true,
              title: 'CV',
              subTitle: 'CV Card',
              folderID: current_cv_id || '',
              imagePreview: '',
            },
          ];

          setListArr([...lArray]);
          setCurrentFolderType('cv');
          setChildValue('cv');
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveService
      .moveToExpire({
        type: type,
        file_id: id,
        provider_id: selectedUserDetail?.id,
      })
      .then((res) => {
        dispatch(clearLoading());
        if (res?.data?.msg) {
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Moved Successfully', {
            variant: 'success',
            timeout: 3000,
          });
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      setCols([...defaultCols]);
      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          if (res?.data?.data?.files) {
            setData(res?.data?.data?.files);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentFolderType('cv');
    setChildValue('cv');
  };

  const handleChangeChild = (event, newValue) => {
    setData([]);
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: selectedFileId, type: childValue })
      .then((res) => {
        if (res?.data?.msg) {
          dispatch(clearLoading());
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Delete Successfully', {
            variant: 'success',
            timeout: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = () => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHandler = (event, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };

  const submitHandler = () => {
    for (let i = 0; i < listArr?.length; i++) {
      if (listArr[i]?.folderID && listArr[i]?.imagePreview) {
        let formData = new FormData();
        formData.append('cv', listArr[i]?.cv);
        formData.append('file', listArr[i]?.imagePreview);
        formData.append('folder', listArr[i]?.folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append('folder_name', listArr[i]?.fileStartingName || 'CV');
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        dispatch(setLoading());

        driveService
          .uploadFiles(formData)
          .then((response) => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All File Upload Successfully', {
              variant: 'success',
              timeout: 3000,
            });
            setTimeout(() => {
              getDataFromDriveFolder(listArr[i]?.type);
            }, 200);
            dispatch(clearLoading());
            setTriggerAPICall((prevTriggerCall) => !prevTriggerCall);
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
      let resetListData = listArr?.map((v) => ({ ...v, imagePreview: '' }));
      setListArr([...resetListData]);
      setOpenFileUploadDialog(false);
    }
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={deleteFileHandler}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={openFileUploadDialog}
        onClose={() => setOpenFileUploadDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h6" align="center">
            Document <span style={{ color: '#0ea5e9' }}>Upload</span> Center
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            The following are the file formats that you are able to upload -
            Doc, Docx, Jpg, Jpeg, Png, Pdf
          </DialogContentText>

          {listArr?.map((v, k) => (
            <Box
              key={k}
              sx={{
                border: '1px solid #ccc',
                borderLeft: '2px solid #ccc',
                borderBottom: '2px solid #0ea5e9',
                padding: '12px',
                margin: '20px 0',
              }}
            >
              <Grid2 container alignItems="center">
                {v?.imagePreview ? (
                  <Grid2
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    <Grid2 item xs={8}>
                      <Link
                        href={URL.createObjectURL(v?.imagePreview)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#0ea5e9' }}
                      >
                        {v?.imagePreview?.name || 'uploaded-file.jpg'}
                      </Link>
                    </Grid2>

                    <Grid2 item xs={4} textAlign="right">
                      <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <PublishedWithChangesIcon />
                            <Typography variant="caption">Replace</Typography>
                          </Box>
                          <input
                            type="file"
                            accept=".doc, .docx, .jpeg, .pdf, .jpg, .png"
                            hidden
                            onChange={(e) =>
                              uploadFileAsPerDocumentHandler(e, k)
                            }
                          />
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            let lArray = [...listArr];
                            lArray[k].imagePreview = '';
                            setListArr([...lArray]);
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <ClearIcon />
                            <Typography variant="caption">Clear</Typography>
                          </Box>
                        </Button>
                      </Box>
                    </Grid2>
                  </Grid2>
                ) : (
                  <Typography variant="body2" align="center" width="100%">
                    Click{' '}
                    <Button
                      variant="text"
                      component="label"
                      style={{ color: '#0ea5e9', textDecoration: 'underline' }}
                    >
                      HERE
                      <input
                        type="file"
                        accept=".doc, .docx, .jpeg, .pdf, .jpg, .png"
                        hidden
                        onChange={(e) => uploadFileAsPerDocumentHandler(e, k)}
                      />
                    </Button>{' '}
                    to Upload a File
                  </Typography>
                )}
              </Grid2>
            </Box>
          ))}

          <Box display="flex" justifyContent="center" mt={4}>
            <Button variant="contained" color="primary" onClick={submitHandler}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            textTransform: 'none',
          },
          '& .Mui-selected': {
            backgroundColor: '#ebeded',
          },
        }}
      >
        {parentTabs.map((v) => (
          <Tab key={v.value} label={v.label} value={v.value} />
        ))}
      </Tabs>

      {value === '1' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          showUploadButton={true}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}
    </>
  );
}

export default memo(CvComponent);
