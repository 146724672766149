import React, { useState, useEffect } from 'react';
import { Box, TableContainer, Switch, FormControlLabel } from '@mui/material';

import ResponsiveTabs from '../UI/ResponsiveTabs';
import { useMediaQuery, useTheme } from '@mui/material';
import ProviderGeneralTable from './ProviderGeneralTable';
import ProfessionalUpkeepTable from './ProfessionalUpkeepTable';
import ProviderProfessionalInfoTable from './ProviderProfessionalInfoTable';
import ProviderInsuranceInfoTable from './ProviderInsuranceInfoTable';
import ProviderPracticeInfoTable from './ProviderPracticeInfoTable';
import styles from '../../styles/ProviderMetrics.module.css';

const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>
    <div className={styles.tabDescription}>{description}</div>
  </div>
);

export default function Provider() {
  const [showActiveProviders, setShowActiveProviders] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleShowActiveProviders = () => {
    const newShowActive = !showActiveProviders;

    // Before changing the showActiveProviders state, adjust the activeTab if necessary
    if (!newShowActive && activeTab === 2) {
      // If we are about to hide the "Professional Upkeep" tab and it is active
      setActiveTab(1); // Move to a safe tab, e.g., "Insurance Information"
    } else if (newShowActive && activeTab >= 2) {
      // Adjust to correct for the reintroduction of the "Professional Upkeep" tab
      setActiveTab((prev) => prev + 1);
    }

    setShowActiveProviders(newShowActive);
  };

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');

    setActiveTab(newValue);

    // Assuming the sidebar is only meant to be visible on the first tab (index 0)
    if (newValue !== 0) {
      // Hide the sidebar and remove the class when switching away from the first tab
    }
  };

  const tabs = [
    {
      index: 0,
      title: 'Personal Info',
      label: (
        <TabLabelWithDescription
          title="Personal Info"
          description="Name, Email, DOB, etc"
        />
      ),
      className:
        activeTab === 0 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 1,
      title: 'Insurance Information',
      description:
        'Insurance Credentialing Status, Provider Numbers, Effective Dates, etc',
      label: (
        <TabLabelWithDescription
          title="Insurance Information"
          description="Insurance Credentialing Status, Provider Numbers, Effective Dates, etc"
        />
      ),
      className:
        activeTab === 1 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 2,
      title: 'Professional Upkeep',
      description: 'Attestations, Rosters, Licensure & Malpractice Insurance',
      condition: showActiveProviders,
      label: (
        <TabLabelWithDescription
          title="Professional Upkeep"
          description="Attestations, Rosters, Licensure & Malpractice Insurance"
        />
      ),
      className:
        activeTab === 2 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 3,
      title: 'Education & Supervision',
      description: 'Graduating School & Supervisory Information',
      label: (
        <TabLabelWithDescription
          title="Education & Supervision"
          description="Graduating School & Supervisory Information"
        />
      ),
      className:
        activeTab === 3 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 4,
      title: 'Provider Practice Info',
      description: 'HIPAA Workforce, Doxy/Meet, LLC Info, etc',
      label: (
        <TabLabelWithDescription
          title="Provider Practice Info"
          description="HIPAA Workforce, Doxy/Meet, LLC Info, etc"
        />
      ),
      className:
        activeTab === 4 ? styles.customTabSelected : styles.customTabRoot,
    },
  ];

  // Filter visible tabs based on conditions
  const visibleTabs = tabs.filter((tab) => {
    if (tab.condition === undefined) return true;
    return tab.condition;
  });

  // Apply styles for each tab
  visibleTabs.forEach((tab, idx) => {
    tab.className = `${styles.customTabRoot} ${
      activeTab === idx ? styles.customTabSelected : ''
    }`;
  });

  // Ensure activeTab is within bounds
  useEffect(() => {
    if (activeTab >= visibleTabs.length) {
      setActiveTab(0);
    }
  }, [visibleTabs.length, activeTab]);

  // Function to render the content based on the actual tab index
  const renderActiveTabContent = () => {
    const actualTabIndex = visibleTabs[activeTab]?.index;
    switch (actualTabIndex) {
      case 0:
        return (
          <ProviderGeneralTable
            showActiveProviders={showActiveProviders}
            setShowActiveProviders={setShowActiveProviders}
          />
        );
      case 1:
        return (
          <ProviderInsuranceInfoTable
            showActiveProviders={showActiveProviders}
            setShowActiveProviders={setShowActiveProviders}
          />
        );
      case 2:
        return (
          <ProfessionalUpkeepTable
            showActiveProviders={showActiveProviders}
            setShowActiveProviders={setShowActiveProviders}
          />
        );
      case 3:
        return (
          <ProviderProfessionalInfoTable
            showActiveProviders={showActiveProviders}
            setShowActiveProviders={setShowActiveProviders}
          />
        );
      case 4:
        return (
          <ProviderPracticeInfoTable
            showActiveProviders={showActiveProviders}
            setShowActiveProviders={setShowActiveProviders}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Tabs Component */}
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          width: '100%',
          marginTop: isSmallScreen ? '56px' : '75px', // Adjust margin for mobile
        }}
      >
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            padding: isSmallScreen ? '0 8px' : '0', // Adjust padding for mobile
          }}
        >
          <ResponsiveTabs
            tabs={visibleTabs}
            activeTab={activeTab}
            onChange={handleTabChange}
          />
        </Box>
      </div>

      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {activeTab !== 2 && (
            <FormControlLabel
              control={
                <Switch
                  checked={showActiveProviders}
                  onChange={toggleShowActiveProviders}
                  name="showActiveProviders"
                />
              }
              label={
                showActiveProviders ? 'Active Providers' : 'Inactive Providers'
              }
              style={{ margin: 0 }}
            />
          )}
        </div>
      </TableContainer>

      {renderActiveTabContent()}
    </div>
  );
}
