import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// Can get Active or Inactive providers based on the filter
const getProviders = (status = 'active') =>
  axios.get(`${API_URL}providers?status=${status}`, { headers: authHeader() });

// Can get all the Providers
const getAllProviders = () =>
  axios.get(`${API_URL}providers/getAllProviders`, {
    headers: authHeader(),
  });

const getProviderDetail = (id, email) =>
  axios.get(`${API_URL}provider/${id}?email=${email}`, {
    headers: authHeader(),
  });

const updateProviderDetails = (id, body) =>
  axios.post(`${API_URL}provider/${id}`, body, { headers: authHeader() });

const getProvidersWithServiceType = (serviceType) =>
  axios.get(`${API_URL}providers/${serviceType}`);

const sendApprovalEmail = (body) => {
  return axios.post(`${API_URL}providers/sendApprovalEmail`, body, {
    headers: authHeader(),
  });
};

const getTherapyConfigOptions = () => {
  return axios.get(`${API_URL}therapy_config_options`, {
    headers: authHeader(),
  });
};

const updateTherapyConfig = (id, body) =>
  axios.post(`${API_URL}updateTherapyConfig/${id}`, body, {
    headers: authHeader(),
  });

const getTherapyConfig = (id) =>
  axios.get(`${API_URL}therapyConfig/${id}`, { headers: authHeader() });

const updateTestingConfig = (id, body) =>
  axios.post(`${API_URL}updateTestingConfig/${id}`, body, {
    headers: authHeader(),
  });

// eslint-disable-next-line
export default {
  getProviders,
  getAllProviders,
  getProviderDetail,
  getProvidersWithServiceType,
  updateProviderDetails,
  sendApprovalEmail,
  getTherapyConfigOptions,
  updateTherapyConfig,
  getTherapyConfig,
  updateTestingConfig,
};
