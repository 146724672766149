import React, { useEffect, useState, memo } from 'react';
import { Tabs, Tab, Button, Box, Grid2, Typography, Link } from '@mui/material';
import {
  PersonSearch as PersonSearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { userService, driveService } from '../../services';
import FilesMapping from './FilesMapping';
import { DialogComponent } from '../Dialogs';
import { clearLoading, setLoading } from '../../actions/loading';
import Input from '../Input/Input';
import Select from '../Select';
import TextArea from '../TextArea';

let defaultCols = [
  { key: 'fileName', label: 'File Name', xs: 6 },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    xs: 6,
    getData: (c) => {
      return moment(c?.createdAt).format('MM-DD-YYYY');
    },
  },
];

function FilesOnDrive({ selectedUser, selectedUserDetail }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = useState('1');
  const [childTabForCurrent, setChildTabForCurrent] = useState([]);
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childTabForExpired, setChildTabForExpired] = useState([]);
  const [childValue, setChildValue] = useState('1');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [listArr, setListArr] = useState([
    {
      type: 'diploma',
      fileStartingName: 'DIPLOMA',
      diploma: true,
      title: 'Diploma Proof',
      subTitle: 'Diploma Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { graduating_school: '', graduating_date: '', other: '' },
    },
    // Other items...
  ]);

  const [parentTabs, setParentTab] = useState();

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    userService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then((res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          let lArray = [...listArr];
          // Assign data as per the response
          setListArr([...lArray]);
          setParentTab(res?.data?.ParentTab);
          setChildTabForCurrent(res?.data?.SubTab?.current);
          setChildTabForExpired(res?.data?.SubTab?.expired);
          setCurrentFolderType(res?.data?.SubTab?.current[0]?.value);
          setChildValue(res?.data?.SubTab?.current[0]?.value);
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveService
      .moveToExpire({ type, file_id: id, provider_id: selectedUserDetail?.id })
      .then((res) => {
        dispatch(clearLoading());
        enqueueSnackbar('File Moved Successfully', {
          variant: 'success',
          timeout: 3000,
        });
        getDataFromDriveFolder(currentFolderType);
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const onChangeHandler = (e, index) => {
    let lArray = [...listArr];
    listArr[index].data = {
      ...listArr[index].data,
      [e.target.name]: e.target.value,
    };
    setListArr([...lArray]);
  };

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      setCols([
        { key: 'fileName', label: 'File Name', xs: 6 },
        {
          key: 'graduating_school',
          label: 'Graduating School',
          xs: 3,
        },
        {
          key: 'graduating_date',
          label: 'Date Of Graduation',
          xs: 3,
          getData: (c) => moment(c?.graduating_date).format('MM-DD-YYYY'),
        },
        {
          key: 'createdAt',
          label: 'Uploaded Date',
          xs: 2,
          getData: (c) => moment(c?.createdAt).format('MM-DD-YYYY'),
        },
      ]);
      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          setData(res?.data?.data?.files || []);
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentFolderType(childTabForCurrent[0]?.value);
  };

  const handleChangeChild = (event, newValue) => {
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: selectedFileId, type: childValue })
      .then(() => {
        enqueueSnackbar('File Deleted Successfully', { variant: 'success' });
        getDataFromDriveFolder(currentFolderType);
        setConfirmDelete(false);
        dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = () => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHandler = (event, data, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };

  const submitHandler = () => {
    for (let i = 0; i < listArr?.length; i++) {
      if (listArr[i]?.folderID && listArr[i]?.imagePreview) {
        let formData = new FormData();
        formData.append('liability', listArr[i]?.liability);
        for (var key in listArr[i]?.data) {
          formData.append(key, listArr[i]?.data[key]);
        }
        formData.append('cv', listArr[i]?.cv);
        formData.append('license', listArr[i]?.license);
        formData.append('diploma', listArr[i]?.diploma);
        formData.append('1099', listArr[i]?.['1099']);
        formData.append('file', listArr[i]?.imagePreview);
        formData.append('folder', listArr[i]?.folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append(
          'folder_name',
          listArr[i]?.fileStartingName || 'DIPLOMA'
        );
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        dispatch(setLoading());

        driveService
          .uploadFiles(formData)
          .then(() => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All Files Uploaded Successfully', {
              variant: 'success',
              timeout: 2000,
            });
            getDataFromDriveFolder(listArr[i]?.type);
            dispatch(clearLoading());
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
    }
    let resetListData = listArr.map((v) => ({ ...v, imagePreview: '' }));
    setListArr([...resetListData]);
    setOpenFileUploadDialog(false);
  };

  return (
    <>
      {confirmDelete && (
        <DialogComponent
          maxWidth="xs"
          open={confirmDelete}
          setOpen={setConfirmDelete}
          content={
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold">
                Are You Sure You Want to Delete - This Action Cannot Be Undone
              </Typography>
              <Box mt={3} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  onClick={() => setConfirmDelete(false)}
                  sx={{ marginRight: 2 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={deleteFileHandler}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          }
        />
      )}
      {openFileUploadDialog && (
        <DialogComponent
          maxWidth="md"
          open={openFileUploadDialog}
          setOpen={setOpenFileUploadDialog}
          content={
            <Box p={3}>
              <Typography
                variant="h6"
                fontWeight="bold"
                align="center"
                color="text.primary"
              >
                Document <span style={{ color: 'skyblue' }}>Upload</span> Center
              </Typography>
              <Typography align="center" mt={1}>
                The following are the file formats that you are able to upload -
                PNG, DOC, DOCX, JPEG, PDF
              </Typography>

              {listArr?.map(
                (v, k) =>
                  v?.type === childValue && (
                    <Grid2 container spacing={2} mt={3} key={k}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {v?.title}
                      </Typography>
                      <Grid2 item xs={12}>
                        <Grid2 container spacing={2}>
                          <Grid2 item xs={6}>
                            <Select
                              name="graduating_school"
                              label="Graduating School"
                              value={v?.data?.graduating_school}
                              onChange={(e) => onChangeHandler(e, k)}
                              data={[
                                {
                                  value: 'Andrews University',
                                  name: 'Andrews University',
                                },
                                { value: 'Other', name: 'Other' },
                              ]}
                            />
                          </Grid2>
                          <Grid2 item xs={6}>
                            <Input
                              type="date"
                              name="graduating_date"
                              label="Graduating Date"
                              value={v?.data?.graduating_date}
                              onChange={(e) => onChangeHandler(e, k)}
                            />
                          </Grid2>
                          {v?.data?.graduating_school === 'Other' && (
                            <Grid2 item xs={12}>
                              <TextArea
                                name="other"
                                label="Other"
                                value={v?.data?.other}
                                onChange={(e) => onChangeHandler(e, k)}
                              />
                            </Grid2>
                          )}
                        </Grid2>
                      </Grid2>

                      <Grid2 item xs={12}>
                        <Box
                          p={2}
                          border={1}
                          borderColor="gray.300"
                          borderRadius={2}
                          sx={{
                            borderBottomColor: v?.imagePreview
                              ? 'green'
                              : 'blue',
                            borderBottomWidth: 2,
                          }}
                        >
                          {v?.imagePreview ? (
                            <Box display="flex" justifyContent="space-between">
                              <Link
                                href={URL.createObjectURL(v?.imagePreview)}
                                target="_blank"
                                underline="hover"
                              >
                                {v?.imagePreview?.name || 'uploaded file'}
                              </Link>
                              <Box display="flex">
                                <Button
                                  variant="contained"
                                  size="small"
                                  component="label"
                                >
                                  Replace
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      uploadFileAsPerDocumentHandler(e, v, k)
                                    }
                                  />
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    let lArray = [...listArr];
                                    lArray[k].imagePreview = '';
                                    setListArr([...lArray]);
                                  }}
                                  sx={{ ml: 2 }}
                                >
                                  <ClearIcon />
                                  Clear
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              component="label"
                            >
                              Upload File
                              <input
                                type="file"
                                hidden
                                onChange={(e) =>
                                  uploadFileAsPerDocumentHandler(e, v, k)
                                }
                              />
                            </Button>
                          )}
                        </Box>
                      </Grid2>
                    </Grid2>
                  )
              )}

              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitHandler}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        {parentTabs?.map((v) => (
          <Tab key={v.value} label={v.label} value={v.value} />
        ))}
      </Tabs>

      {value === '1' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          showUploadButton={true}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          childTab={childTabForCurrent}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}

      {value === '2' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          childTab={childTabForExpired}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}
    </>
  );
}

export default memo(FilesOnDrive);
