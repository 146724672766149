import React, { useState, useEffect, memo, useCallback } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tabs, Tab, Button } from '@mui/material';
import { cptInsuranceService } from '../../services';
import styles from '../../styles/MyHandsOnTableComponent.module.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';

function MyHandsontableComponent() {
  const [changes, setChanges] = useState([]); // Store changes locally
  const [idMap, setIdMap] = useState({});
  const { enqueueSnackbar } = useSnackbar(); // This hook provides the enqueueSnackbar method
  const [activeTab, setActiveTab] = useState(0); // 0 for Current, 1 for Archived
  const [currentData, setCurrentData] = useState([]);
  const [archivedData, setArchivedData] = useState([]);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const handleCopy = (event) => {
      console.log('Copy event triggered');
      const clipboardData = event.clipboardData || window.clipboardData;
      console.log('Clipboard items:', clipboardData.items);
      const data = clipboardData.getData('Text');
      console.log('Copying data:', data);
    };

    const handlePaste = (event) => {
      console.log('Paste event triggered');
      console.log('Pasting data:', event.clipboardData.getData('Text'));
    };
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    showLoading(); // Showing the loading here.
    cptInsuranceService
      .getAllBillingRates()
      .then((response) => {
        // Create a map for IDs
        const newIdMap = response.data.reduce((acc, rate, index) => {
          acc[index] = rate.id; // Map the row index to the ID
          return acc;
        }, {});

        setIdMap(newIdMap); // Set the ID map

        const current = response.data
          .filter((rate) => !rate.end_date)
          .map((rate) => [
            rate.cpt_code, // Start with cpt_code, omitting the ID
            rate.service_group,
            rate.service_type,
            rate.service_type_subtype,
            rate.insurance,
            rate.setting_location_dependent,
            rate.office_location,
            rate.masters_amount ? rate.masters_amount.toString() : '',
            rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
            rate.time_bound,
            rate.min_time ? rate.min_time.toString() : '',
            rate.max_time ? rate.max_time.toString() : '',
            rate.amount_range,
            rate.masters_low_range ? rate.masters_low_range.toString() : '',
            rate.masters_high_range ? rate.masters_high_range.toString() : '',
            rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
            rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
            rate.notes ? rate.notes.toString() : '',
            rate.effective_date,
            rate.end_date,
            rate.updated_date,
          ]);

        const archived = response.data
          .filter((rate) => rate.end_date)
          .map((rate) => [
            rate.cpt_code, // Start with cpt_code, omitting the ID
            rate.service_group,
            rate.service_type,
            rate.service_type_subtype,
            rate.insurance,
            rate.setting_location_dependent,
            rate.office_location,
            rate.masters_amount ? rate.masters_amount.toString() : '',
            rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
            rate.time_bound,
            rate.min_time ? rate.min_time.toString() : '',
            rate.max_time ? rate.max_time.toString() : '',
            rate.amount_range,
            rate.masters_low_range ? rate.masters_low_range.toString() : '',
            rate.masters_high_range ? rate.masters_high_range.toString() : '',
            rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
            rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
            rate.notes ? rate.notes.toString() : '',
            rate.effective_date,
            rate.end_date,
            rate.updated_date,
          ]);

        setCurrentData(current);
        setArchivedData(archived);
        hideLoading(); // hiding the loading
      })
      .catch((error) => {
        console.log('error.message', error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAfterChange = (change, source) => {
    if (source !== 'loadData' && change) {
      setChanges((currentChanges) => [...currentChanges, ...change]);
    }
  };

  const handleSave = async () => {
    try {
      showLoading(); // showing the loading here

      let successfulUpdates = 0;
      let successfulCreations = 0;
      let skippedEntries = 0;
      let errors = 0;

      // Use the correct dataset based on the active tab
      const activeData = activeTab === 0 ? currentData : archivedData;

      // Define a Set of indices that represent the rows which have been edited
      const editedRowIndices = new Set(changes.map((change) => change[0]));

      // Iterate over only the edited rows based on the indices in editedRowIndices
      for (const index of editedRowIndices) {
        const newEntry = activeData[index];
        const isValidNewEntry = newEntry
          .slice(0, 3)
          .every((val) => val !== null && val !== '');

        if (!isValidNewEntry) {
          console.log('Skipping creation of new entry due to incomplete data.');
          skippedEntries++;
          continue; // Skip creating new entry if data is incomplete
        }
        const rateId = idMap[index]; // Get the ID from the map
        const updated_date = moment(newEntry[20]).isValid()
          ? moment(newEntry[20]).format('YYYY-MM-DD')
          : null;
        const newRateData = {
          cpt_code: newEntry[0], // Updated column name
          service_group: newEntry[1],
          service_type: newEntry[2],
          service_type_subtype: newEntry[3],
          insurance: newEntry[4],
          setting_location_dependent: newEntry[5],
          office_location: newEntry[6],
          masters_amount: newEntry[7] ? parseFloat(newEntry[7]) : null,
          doctoral_amount: newEntry[8] ? parseFloat(newEntry[8]) : null,
          time_bound: newEntry[9],
          min_time: newEntry[10] ? parseInt(newEntry[10], 10) : null,
          max_time: newEntry[11] ? parseInt(newEntry[11], 10) : null,
          amount_range: newEntry[12],
          masters_low_range: newEntry[13] ? parseFloat(newEntry[13]) : null,
          masters_high_range: newEntry[14] ? parseFloat(newEntry[14]) : null,
          doctoral_low_range: newEntry[15] ? parseFloat(newEntry[15]) : null,
          doctoral_high_range: newEntry[16] ? parseFloat(newEntry[16]) : null,
          notes: newEntry[17] ? newEntry[17] : null,
          effective_date: newEntry[18]
            ? moment(newEntry[18]).format('YYYY-MM-DD')
            : null, // Parsing to YYYY-MM-DD format
          end_date: newEntry[19]
            ? moment(newEntry[19]).format('YYYY-MM-DD')
            : null, // Parsing to YYYY-MM-DD format
          updated_date: updated_date,
        };

        console.log('Creating new rate with data:', newRateData);

        if (rateId) {
          try {
            // If the rate has an ID, then it's an update.
            await cptInsuranceService.updateBillingRate(rateId, newRateData);
            console.log('Update successful');
            successfulUpdates++;
          } catch (error) {
            console.error(
              'There was an error updating the billing rate:',
              error
            );
            errors++;
          }
        } else {
          // Create a new rate
          try {
            await cptInsuranceService.createBillingRate(newRateData);
            console.log('Creation successful');
            successfulCreations++;
          } catch (error) {
            console.error(
              'There was an error creating a new billing rate:',
              error
            );
            errors++;
          }
        }
      }

      if (errors > 0) {
        enqueueSnackbar(`There were errors processing some entries.`, {
          variant: 'error',
          timeout: 3000,
        });
      } else if (successfulUpdates > 0 || successfulCreations > 0) {
        let message = `Processed ${successfulUpdates} updates and ${successfulCreations} creations successfully.`;
        if (skippedEntries > 0) {
          message += ` Skipped ${skippedEntries} incomplete entries.`;
        }
        enqueueSnackbar(message, { variant: 'success', timeout: 3000 });
      } else if (skippedEntries > 0) {
        enqueueSnackbar(
          `Skipped ${skippedEntries} incomplete entries. No changes were made.`,
          { variant: 'info', timeout: 3000 }
        );
      }

      setChanges([]); // Reset changes after save
    } catch (error) {
      console.log('error.message: ', error.message);
    } finally {
      hideLoading();
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="billing rate tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottom: '2px solid #E0E0E0',
          background: '#F5F5F5',
          marginBottom: '20px',
        }}
      >
        <Tab label="Current" />
        <Tab label="Archived" />
      </Tabs>

      {activeTab === 0 && (
        <HotTable
          data={currentData}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          colHeaders={[
            'CPT Code',
            'Service Group',
            'Service Type',
            'Service Type Subtype',
            'Insurance',
            'Setting/Location Dependent',
            'Location',
            'Master’s Amount',
            'Doctoral Amount',
            'Time Bound',
            'Min Time',
            'Max Time',
            'Amount Range',
            'Master’s Low Range',
            'Master’s High Range',
            'Doctoral Low Range',
            'Doctoral High Range',
            'Notes',
            'Effective Date',
            'End Date',
            'Updated Date',
          ]}
          rowHeaders={true}
          width="1300"
          height="600"
          colWidths={150}
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          filters={true}
          dropdownMenu={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          copyPaste={true}
          cells={(row, col, prop) => {
            const cellProperties = {};
            cellProperties.className = styles.cellCentered;
            return cellProperties;
          }}
        />
      )}

      {activeTab === 1 && (
        <HotTable
          data={archivedData}
          licenseKey="non-commercial-and-evaluation"
          colHeaders={[
            'CPT Code',
            'Service Group',
            'Service Type',
            'Service Type Subtype',
            'Insurance',
            'Setting/Location Dependent',
            'Location',
            'Master’s Amount',
            'Doctoral Amount',
            'Time Bound',
            'Min Time',
            'Max Time',
            'Amount Range',
            'Master’s Low Range',
            'Master’s High Range',
            'Doctoral Low Range',
            'Doctoral High Range',
            'Notes',
            'Effective Date',
            'End Date',
            'Updated Date',
          ]}
          rowHeaders={true}
          width="1300"
          height="600"
          colWidths={150}
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          filters={true}
          dropdownMenu={true}
          copyPaste={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          cells={(row, col, prop) => {
            const cellProperties = {};
            cellProperties.className = styles.cellCentered;
            return cellProperties;
          }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ marginTop: '20px' }} // Add margin directly with sx to ensure it applies
      >
        Save Changes
      </Button>
    </div>
  );
}

export default memo(MyHandsontableComponent);
