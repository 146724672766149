import React, { useState, useEffect, memo, useCallback } from 'react';
import { HotTable } from '@handsontable/react';
import { Tabs, Tab, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { definitionService } from '../../services';

const MyHandsonTableAppropDefinitions = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    showLoading(); // showing loading here

    definitionService
      .getAllDefinitions()
      .then((response) => {
        const fetchedData = response.data;
        setData(fetchedData);

        // Get unique categories for the tabs
        const categories = [
          ...new Set(fetchedData.map((item) => item.category)),
        ];
        setTabs(categories);
      })
      .catch((error) => {
        console.error('Failed to fetch data:', error);
        enqueueSnackbar('Failed to load data.', { variant: 'error' });
      })
      .finally(() => {
        hideLoading(); // hiding Loading here
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setChanges([]); // Clear changes when switching tabs
  };

  const handleAfterChange = (change, source) => {
    if (source !== 'loadData' && change) {
      setChanges((currentChanges) => [
        ...currentChanges,
        ...change.map(([row, col, oldValue, newValue]) => {
          // Map filtered data row index back to the original data index
          const originalRowIndex = data.findIndex(
            (item) =>
              item ===
              data.filter((item) => item.category === tabs[activeTab])[row]
          );
          return [originalRowIndex, col, oldValue, newValue];
        }),
      ]);
    }
  };

  const handleSave = async () => {
    let successfulUpdates = 0;
    let successfulCreations = 0;
    let errors = 0;

    // Using a Set to track edited rows
    const editedRows = new Set(changes.map((change) => change[0])); // Unique row indices based on the original data

    for (const rowIndex of editedRows) {
      const entry = data[rowIndex];
      const isCompleteEntry = entry.term && entry.category; // Example validation

      if (!isCompleteEntry) {
        console.log('Skipping due to incomplete data:', entry);
        continue; // Skip this iteration if data is incomplete
      }

      if (entry.id) {
        try {
          showLoading();
          const updated = await definitionService.updateDefinition(
            entry.id,
            entry
          );
          console.log('Update successful:', updated);
          successfulUpdates++;
        } catch (error) {
          console.error('Error updating entry:', error);
          enqueueSnackbar('Error updating entries.', { variant: 'error' });
          errors++;
        } finally {
          hideLoading();
        }
      } else {
        try {
          showLoading();
          const created = await definitionService.createDefinition(entry);
          console.log('Creation successful:', created);
          successfulCreations++;
        } catch (error) {
          console.error('Error creating entry:', error);
          enqueueSnackbar('Error creating new entries.', { variant: 'error' });
          errors++;
        } finally {
          hideLoading();
        }
      }
    }

    if (errors) {
      enqueueSnackbar('Errors occurred during save operations.', {
        variant: 'error',
      });
    } else if (successfulCreations || successfulUpdates) {
      const message = `Processed ${successfulUpdates} updates and ${successfulCreations} creations successfully.`;
      enqueueSnackbar(message, { variant: 'success' });
    }

    setChanges([]); // Reset changes after save
  };

  return (
    <div className="handsontable-container">
      <div style={{ marginTop: '20px' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Category Tabs"
        >
          {tabs.map((tab, index) => (
            <Tab label={tab} key={index} />
          ))}
        </Tabs>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '90%' }}>
            <HotTable
              data={data.filter((item) => item.category === tabs[activeTab])}
              colHeaders={[
                'Term',
                'Category',
                'Definition',
                'Example',
                'Notes',
              ]}
              rowHeaders={true}
              columns={[
                { data: 'term', type: 'text', width: 180, wordWrap: true },
                { data: 'category', type: 'text', width: 150, wordWrap: true },
                {
                  data: 'definition',
                  type: 'text',
                  width: 300,
                  wordWrap: true,
                },
                { data: 'example', type: 'text', width: 250, wordWrap: true },
                { data: 'notes', type: 'text', width: 200, wordWrap: true },
              ]}
              licenseKey="non-commercial-and-evaluation"
              width="1300"
              height="600"
              manualRowResize={true}
              manualColumnResize={true}
              columnSorting={true}
              contextMenu={true}
              afterChange={handleAfterChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              style={{ marginTop: '10px' }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MyHandsonTableAppropDefinitions);
