import React from 'react';
import { Badge } from '@mui/material';
import { blue, pink, red, orange } from '@mui/material/colors';
import {
  PanTool as PanToolIcon,
  WatchLater as WatchLaterIcon,
} from '@mui/icons-material';

function PanToolWithTimer({ daysUntilExpiration }) {
  const getExpirationStage = (days) => {
    if (days > 7) {
      return 'far';
    } else if (days <= 7 && days > 3) {
      return 'near';
    } else if (days <= 3 && days > 1) {
      return 'closer';
    } else {
      return 'expiring'; // 1 day or less
    }
  };
  const expirationStage = getExpirationStage(daysUntilExpiration);

  const colorMap = {
    far: blue[500], // Using Material-UI color shades
    near: pink[100],
    closer: orange[500],
    expiring: red[500], // Changed from 'expired' to 'expiring'
  };

  return (
    <Badge
      overlap="circular"
      badgeContent={
        <WatchLaterIcon
          style={{ fontSize: '1rem', color: colorMap[expirationStage] }}
        />
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiBadge-badge': {
          top: 0,
          right: 0,
          transform: 'scale(1) translate(50%, -50%)',
        },
      }}
    >
      <PanToolIcon style={{ fontSize: 30, color: 'red' }} />
    </Badge>
  );
}

export default PanToolWithTimer;
