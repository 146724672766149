import React, { useEffect, useState, memo } from 'react';
import {
  Tabs,
  Tab,
  Button,
  Box,
  Grid2 as Grid, // Adjusting to use Grid2 correctly from MUI
  Typography,
  Link,
  TextField,
  MenuItem,
} from '@mui/material';
import {
  PersonSearch as PersonSearchIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { driveService } from '../../services';
import FilesMapping from './FilesMapping';
import { DialogComponent } from '../Dialogs';
import { clearLoading, setLoading } from '../../actions/loading';

const defaultCols = [
  { key: 'fileName', label: 'File Name', Grid2Span: 5 },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    Grid2Span: 5,
    getData: (c) => moment.utc(c?.createdAt).format('MM-DD-YYYY'),
  },
];

const parentTabs = [{ label: 'CURRENT', value: '1' }];

// Adding the missing schoolsData array
const schoolsData = [
  { value: 'Andrews University', name: 'Andrews University' },
  { value: 'Argosy University', name: 'Argosy University' },
  {
    value: 'California Institute of Integral Studies',
    name: 'California Institute of Integral Studies',
  },
  { value: 'Chatham University', name: 'Chatham University' },
  { value: 'Catholic University', name: 'Catholic University' },
  { value: 'Clark University', name: 'Clark University' },
  { value: 'Columbia University', name: 'Columbia University' },
  { value: 'Fuller Theological Seminary', name: 'Fuller Theological Seminary' },
  {
    value: 'George Washington University',
    name: 'George Washington University',
  },
  { value: 'Howard University', name: 'Howard University' },
  { value: 'Johns Hopkins University', name: 'Johns Hopkins University' },
  { value: 'Louisiana State University', name: 'Louisiana State University' },
  { value: 'Loyola University', name: 'Loyola University' },
  {
    value: 'NOVA Southeastern University',
    name: 'NOVA Southeastern University',
  },
  { value: 'Ohio State University', name: 'Ohio State University' },
  { value: 'Salisbury University', name: 'Salisbury University' },
  { value: 'Smith College', name: 'Smith College' },
  { value: 'Towson University', name: 'Towson University' },
  { value: 'University of Cincinnati', name: 'University of Cincinnati' },
  { value: 'University of Maryland', name: 'University of Maryland' },
  {
    value: 'University of Maryland at Baltimore School of Social Work',
    name: 'University of Maryland at Baltimore School of Social Work',
  },
  { value: 'University of Michigan', name: 'University of Michigan' },
  {
    value: 'Virginia Commonwealth University',
    name: 'Virginia Commonwealth University',
  },
  { value: 'Yeshiva University', name: 'Yeshiva University' },
  { value: 'Other', name: 'Other' },
];

function DiplomaComponent({
  selectedUser,
  selectedUserDetail,
  setTriggerAPICall,
}) {
  /**
   * The below is the responsiveness added for Clear display on mobile
   */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = useState('1');
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childValue, setChildValue] = useState('diploma');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [listArr, setListArr] = useState([
    {
      type: 'diploma',
      fileStartingName: 'DIPLOMA',
      diploma: true,
      title: 'Diploma Proof',
      subTitle: 'Diploma Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { graduating_school: '', graduating_date: '', other: '' },
    },
  ]);

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    driveService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then((res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          const { current_diploma, expired_diploma } = res.data.drive_details;
          const lArray = [
            {
              type: 'diploma',
              fileStartingName: 'DIPLOMA',
              diploma: true,
              title: 'Diploma Proof',
              subTitle: 'Diploma Card',
              folderID: current_diploma || expired_diploma || '',
              imagePreview: '',
              data: { graduating_school: '', graduating_date: '', other: '' },
            },
          ];
          setListArr([...lArray]);
          setCurrentFolderType('diploma');
          setChildValue('diploma');
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveService
      .moveToExpire({
        type: type,
        file_id: id,
        provider_id: selectedUserDetail?.id,
      })
      .then((res) => {
        dispatch(clearLoading());
        if (res?.data?.msg) {
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Moved Successfully', {
            variant: 'success',
            timeout: 3000,
          });
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      setCols([
        { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
        {
          key: 'graduating_school',
          label: 'Graduating School',
          colsSpan: 'col-span-2',
        },
        {
          key: 'graduating_date',
          label: 'Date Of Graduation',
          colsSpan: 'col-span-2',
          getData: (c) => moment.utc(c?.graduating_date).format('MM-DD-YYYY'),
        },
        {
          key: 'createdAt',
          label: 'Uploaded Date',
          colsSpan: 'col-span-2',
          getData: (c) => moment.utc(c?.createdAt).format('MM-DD-YYYY'),
        },
      ]);

      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          if (res?.data?.data?.files) {
            setData(res?.data?.data?.files);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentFolderType('diploma');
    setChildValue('diploma');
  };

  const handleChangeChild = (event, newValue) => {
    setData([]);
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: selectedFileId, type: childValue })
      .then((res) => {
        if (res?.data?.msg) {
          dispatch(clearLoading());
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Delete Successfully', {
            variant: 'success',
            timeout: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = () => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHandler = (event, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };

  const onChangeHandler = (e, index) => {
    let lArray = [...listArr];
    listArr[index].data = {
      ...listArr[index].data,
      [e.target.name]: e.target.value,
    };
    setListArr([...lArray]);
  };

  const submitHandler = () => {
    for (let i = 0; i < listArr?.length; i++) {
      if (listArr[i]?.folderID && listArr[i]?.imagePreview) {
        const formData = new FormData();
        formData.append('diploma', listArr[i]?.diploma);
        formData.append('file', listArr[i]?.imagePreview);
        formData.append('folder', listArr[i]?.folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append(
          'folder_name',
          listArr[i]?.fileStartingName || 'DIPLOMA'
        );
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        for (let key in listArr[i]?.data) {
          formData.append(key, listArr[i]?.data[key]);
        }
        dispatch(setLoading());

        driveService
          .uploadFiles(formData)
          .then(() => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All File Upload Successfully', {
              variant: 'success',
              timeout: 3000,
            });
            setTimeout(() => {
              getDataFromDriveFolder(listArr[i]?.type);
            }, 200);
            dispatch(clearLoading());
            setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
      const resetListData = listArr.map((v) => ({ ...v, imagePreview: '' }));
      setListArr([...resetListData]);
      setOpenFileUploadDialog(false);
    }
  };

  return (
    <>
      {confirmDelete && (
        <DialogComponent
          maxWidth={'xs'}
          open={confirmDelete}
          setOpen={setConfirmDelete}
          content={
            <Box>
              <Typography
                align="center"
                fontWeight="fontWeightBold"
                variant="h6"
              >
                Are You Sure You Want to Delete - This Action Can Not Be Undone
              </Typography>
              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  onClick={() => setConfirmDelete(false)}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={deleteFileHandler}
                  variant="contained"
                  sx={{
                    marginLeft: '24px',
                    background: 'linear-gradient(to right, #06b6d4, #3b82f6)',
                    color: 'white',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    borderRadius: '8px',
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          }
        />
      )}
      {openFileUploadDialog && (
        <DialogComponent
          maxWidth={'md'}
          open={openFileUploadDialog}
          setOpen={setOpenFileUploadDialog}
          content={
            <Box p={2}>
              <Typography
                align="center"
                fontWeight="fontWeightBold"
                variant="h6"
              >
                Document <span style={{ color: 'skyblue' }}> upload </span>{' '}
                Center
              </Typography>
              <Typography align="center" variant="body2" mt={1}>
                The following are the file formats that you are able to upload -
                Png, Doc, Docx, Jpeg, Pdf
              </Typography>
              {listArr?.map(
                (v, k) =>
                  v?.type === childValue && (
                    <Grid
                      container
                      spacing={2}
                      key={k}
                      mt={3}
                      direction={'column'}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="fontWeightBold"
                      >
                        {v?.title}
                      </Typography>
                      <Grid item xs={12} justifyContent={'space-between'}>
                        <Grid
                          container
                          spacing={2}
                          p={2}
                          border={1}
                          borderColor="gray.300"
                        >
                          <Grid
                            item
                            xs={6}
                            sx={{ width: isMobile ? '100%' : '46%' }}
                          >
                            <TextField
                              select
                              name="graduating_school"
                              label="Name of Graduating School"
                              value={v?.data?.graduating_school}
                              onChange={(e) => onChangeHandler(e, k)}
                              fullWidth
                            >
                              {schoolsData.map((school) => (
                                <MenuItem
                                  key={school.value}
                                  value={school.value}
                                >
                                  {school.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ width: isMobile ? '100%' : '46%' }}
                          >
                            <TextField
                              name="graduating_date"
                              label="Date of Graduation"
                              type="date"
                              value={v?.data?.graduating_date}
                              onChange={(e) => onChangeHandler(e, k)}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          {v?.data?.graduating_school === 'Other' && (
                            <Grid item xs={12}>
                              <TextField
                                name="other"
                                label="Other"
                                value={v?.data?.other}
                                onChange={(e) => onChangeHandler(e, k)}
                                multiline
                                fullWidth
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          p={2}
                          border={1}
                          borderColor="gray.300"
                          borderRadius={2}
                          sx={{
                            borderBottomColor: v?.imagePreview
                              ? 'green'
                              : 'blue',
                            borderBottomWidth: 2,
                          }}
                        >
                          {v?.imagePreview ? (
                            <Box display="flex" justifyContent="space-between">
                              <Link
                                href={URL.createObjectURL(v?.imagePreview)}
                                target="_blank"
                                underline="hover"
                              >
                                {v?.imagePreview?.name || 'testing.jpg'}
                              </Link>
                              <Box display="flex">
                                <Button
                                  variant="contained"
                                  component="label"
                                  size="small"
                                  sx={{
                                    color: 'skyblue',
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                  }}
                                >
                                  <input
                                    type="file"
                                    accept=".pdf, .png, .jpeg"
                                    hidden
                                    onChange={(e) =>
                                      uploadFileAsPerDocumentHandler(e, k)
                                    }
                                  />
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                  >
                                    <PublishedWithChangesIcon className="text-green-600" />
                                    <Typography variant="caption">
                                      Replace
                                    </Typography>
                                  </Box>
                                </Button>
                                <Button
                                  variant="text"
                                  color="error"
                                  onClick={() => {
                                    let lArray = [...listArr];
                                    lArray[k].imagePreview = '';
                                    setListArr([...lArray]);
                                  }}
                                  sx={{ ml: 2 }}
                                >
                                  <ClearIcon />
                                  <Typography variant="caption">
                                    Clear
                                  </Typography>
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            // <Button
                            //   variant="contained"
                            //   component="label"
                            //   size="small"
                            // >
                            //   Click here to upload
                            //   <input
                            //     type="file"
                            //     accept=".pdf, .png, .jpeg"
                            //     hidden
                            //     onChange={(e) =>
                            //       uploadFileAsPerDocumentHandler(e, k)
                            //     }
                            //   />
                            // </Button>

                            <Typography
                              variant="body2"
                              align="center"
                              width="100%"
                            >
                              Click{' '}
                              <Button
                                variant="text"
                                component="label"
                                style={{
                                  color: '#0ea5e9',
                                  textDecoration: 'underline',
                                }}
                              >
                                HERE
                                <input
                                  type="file"
                                  accept=".doc, .docx, .jpeg, .pdf, .jpg, .png"
                                  hidden
                                  onChange={(e) =>
                                    uploadFileAsPerDocumentHandler(e, k)
                                  }
                                />
                              </Button>{' '}
                              to Upload a File
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )
              )}
              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'blue',
                    color: 'white',
                    padding: '10px 20px',
                  }}
                  onClick={submitHandler}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          }
        />
      )}
      <Tabs
        onChange={handleChange}
        value={value}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#ebeded' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {parentTabs.map((v) => (
          <Tab
            key={v.value}
            sx={{
              '&.Mui-selected': {
                outline: 'none',
              },
            }}
            label={v?.label}
            value={v?.value}
          />
        ))}
      </Tabs>
      {value === '1' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          showUploadButton={true}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}
    </>
  );
}

export default memo(DiplomaComponent);
