import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { memo } from 'react';

const ScheduleVirtualDialog = ({ alertOpen, setAlertOpen }) => {
  return (
    <Dialog
      open={alertOpen}
      onClose={() => setAlertOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Important Notice</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This client cannot be scheduled for a virtual session. Please try to
          find an in-person session or refer them out to a provider who can see
          clients in-person.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAlertOpen(false)} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ScheduleVirtualDialog);
