import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { complianceService, providerService } from '../../services';

function compareProviderFullName(a, b) {
  // Safely split the provider_name, default to empty string if provider_name is missing
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';

  return lastNameA.localeCompare(lastNameB);
}

export default function ProfessionalUpkeepTable({
  showActiveProviders,
  setShowActiveProviders,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';

    showLoading();

    providerService
      .getProviders(status)
      .then((response) => {
        // Map through each provider to prepare for additional data fetching
        const providersInit = response.data.providers.map((provider) => ({
          ...provider,
          malpracticeDetails: 'Loading...', // Initial placeholder for malpractice details
          last_attestation: 'Loading...', // Placeholder
          last_roster_submission: 'Loading...', // Placeholder
        }));

        // Fetch additional details for each provider
        const detailsPromises = providersInit.map((provider) =>
          Promise.all([
            providerService.getTherapyConfig(provider.id), // Fetch therapy config
            complianceService.getMalpracticeInsuranceDetails(provider.id), // Fetch malpractice details
          ])
            .then(([therapyResponse, malpracticeResponse]) => ({
              ...provider,
              last_attestation: therapyResponse.data.last_attestation || 'N/A',
              last_roster_submission:
                therapyResponse.data.last_roster_submission || 'N/A',
              malpracticeDetails:
                malpracticeResponse.data.malpracticeInsurance ||
                'Not available',
            }))
            .catch((error) => {
              console.error(
                'Error fetching details for provider:',
                provider.id,
                error
              );
              return {
                ...provider,
                malpracticeDetails: 'Error fetching details',
              }; // Fallback
            })
        );

        Promise.all(detailsPromises).then((fullDetails) => {
          fullDetails.sort(compareProviderFullName);
          setProviders(fullDetails); // Update state with all details
        });
      })
      .catch((error) => {
        console.error('Failed to fetch providers:', error);
        enqueueSnackbar.error(
          'Failed to fetch providers: ' +
            (error.response?.data?.message || error.message)
        );
      })
      .finally(() => {
        hideLoading();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveProviders, enqueueSnackbar]);

  const getMalpracticeDateStyle = (malpracticeDetails) => {
    const dateMatch = malpracticeDetails.match(
      /\((\d{1,2}\/\d{1,2}\/\d{2,4})\)/
    );
    if (dateMatch && dateMatch[1]) {
      const expiryDate = moment(dateMatch[1], 'MM/DD/YY');
      const today = moment();
      if (expiryDate.isBefore(today)) {
        return { color: 'red' }; // Past expiration
      } else if (expiryDate.isBefore(today.add(30, 'days'))) {
        return { color: 'orange' }; // Expiring within 30 days
      }
    }
    return {}; // Default style if no conditions met
  };

  const getExpiryDateColor = (expiryDate) => {
    const today = new Date();
    const expiration = new Date(expiryDate);
    const oneMonthFromNow = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );

    if (expiration < today) {
      return 'red'; // past expiration
    } else if (expiration <= oneMonthFromNow) {
      return 'orange'; // expires within one month
    }
    return 'black'; // default color
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // ...
  const displayedProviders = useMemo(() => {
    return providers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [providers, page, rowsPerPage]);

  return (
    <div>
      <TableContainer
        component={Paper}
        id="child"
        style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Providers_table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '0px',
                  zIndex: 100,
                  backgroundColor: '#F5F5F5',
                }}
              >
                Full Name
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '0px',
                  zIndex: 100,
                  backgroundColor: '#F5F5F5',
                }}
              >
                Last Attestation
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '0px',
                  zIndex: 100,
                  backgroundColor: '#F5F5F5',
                }}
              >
                Last Roster Submission
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '0px',
                  zIndex: 100,
                  backgroundColor: '#F5F5F5',
                }}
              >
                Malpractice Insurance <br /> Expiration
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: '0.5px solid black',
                  fontWeight: '900',
                  position: 'sticky',
                  top: '0px',
                  zIndex: 100,
                  backgroundColor: '#F5F5F5',
                }}
              >
                Professional License State
                <br />
                (Expiration)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedProviders?.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      borderBottom: '0.1px solid black',
                      padding: '8px 4px', // Reduced padding uniformly
                      whiteSpace: 'nowrap', // Prevents wrapping of text within cells
                    }}
                  >
                    <div
                      style={{
                        width: '140px',
                      }}
                    >
                      {row?.provider_name}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: '0.1px solid black',
                      padding: '8px 4px', // Reduced padding uniformly
                    }}
                  >
                    <span
                      style={{
                        color:
                          moment().diff(moment(row?.last_attestation), 'days') >
                          90
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {row?.last_attestation}
                    </span>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: '0.1px solid black',
                      padding: '8px 4px', // Reduced padding uniformly
                    }}
                  >
                    <span
                      style={{
                        color:
                          moment().diff(
                            moment(row?.last_roster_submission),
                            'days'
                          ) > 37
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {row?.last_roster_submission}
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: '0.1px solid black',
                      padding: '8px 1px', // Reduced padding uniformly
                    }}
                  >
                    {row.malpracticeDetails ? (
                      <div
                        style={getMalpracticeDateStyle(row.malpracticeDetails)}
                      >
                        {row.malpracticeDetails
                          .split('(')
                          .map((part, index) => (
                            <React.Fragment key={index}>
                              {index === 1 ? '(' : ''}
                              {part}
                              {index === 0 ? <br /> : ''}
                            </React.Fragment>
                          ))}
                      </div>
                    ) : (
                      'Not available'
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: '0.1px solid black',
                      padding: '8px 1px', // Reduced padding uniformly
                    }}
                  >
                    {row.marylandLicenseDetails && (
                      <div>
                        <strong>MD:</strong> Expires{' '}
                        <span
                          style={{
                            color: getExpiryDateColor(
                              row.marylandLicenseDetails.expirationDate
                            ),
                          }}
                        >
                          {row.marylandLicenseDetails.expirationDate
                            ? moment
                                .utc(row.marylandLicenseDetails.expirationDate)
                                .format('M/D/YY')
                            : ''}
                        </span>
                      </div>
                    )}
                    {row.psyPactDetails &&
                      row.psyPactDetails.isPsyPactMember === 'yes' && (
                        <div>
                          <strong>PsyPact Member:</strong> Expires{' '}
                          <span
                            style={{
                              color: getExpiryDateColor(
                                row.psyPactDetails.expiryDate
                              ),
                            }}
                          >
                            {row.psyPactDetails.expiryDate
                              ? moment
                                  .utc(row.psyPactDetails.expiryDate)
                                  .format('M/D/YY')
                              : ''}
                          </span>
                        </div>
                      )}
                    {row.licenseInOtherStateDetails &&
                      Object.entries(row.licenseInOtherStateDetails).map(
                        ([state, details]) =>
                          details.expirationDate && (
                            <div key={state}>
                              <strong>{state}:</strong> Expires{' '}
                              <span
                                style={{
                                  color: getExpiryDateColor(
                                    details.expirationDate
                                  ),
                                }}
                              >
                                {details.expirationDate
                                  ? moment
                                      .utc(details.expirationDate)
                                      .format('M/D/YY')
                                  : ''}
                              </span>
                            </div>
                          )
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
