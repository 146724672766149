import React from 'react';
import { createRoot } from 'react-dom/client';
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import App from './App';
import { store, persistor } from './store';
import muiTheme from './themes/muiTheme';
import * as serviceWorker from './serviceWorker';

// Create a root.
const root = createRoot(document.getElementById('root'));

const MainLayout = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              TransitionComponent={(props) => (
                <Slide {...props} direction="up" />
              )}
            >
              <Router>
                <CssBaseline />
                <Container maxWidth={false}>
                  <App />
                </Container>
              </Router>
            </SnackbarProvider>
          </ConfirmProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// Initial render using the root.
root.render(<MainLayout />);

serviceWorker.unregister();
