import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { memo } from 'react';
import styles from '../../styles/ProviderConfig.module.css';

const PsyPactDialog = ({
  currentPsyPactStep,
  openPsyPactModal,
  setOpenPsyPactModal,
  memberExpiryDate,
  setMemberExpiryDate,
  expirationPsyPactDateEmpty,
  setChangesMadeForEvents,
  changesMadeForEvents,
  handleOnSubmit,
  linkPsyPactLicClicked,
  setCurrentPsyPactStep,
  setLinkPsyPactLicClicked,
}) => {
  const getPsyPactStepStyle = (stepNumber, currentPsyPactStep) => {
    return {
      fontWeight: 'bold',
      marginBottom: '10px',
      color:
        currentPsyPactStep === stepNumber
          ? 'rgba(0, 0, 0, 0.87)'
          : 'rgba(0, 0, 0, 0.2)', // Adjust opacity for faded effect
      marginLeft: stepNumber === 3 ? '16px' : '0px', // Indent Step 3 to align with others
    };
  };

  const getPsyPactContentStyle = (stepNumber, currentPsyPactStep) => {
    return {
      marginBottom: '20px',
      display: currentPsyPactStep === stepNumber ? 'block' : 'none', // Only show content for the active step
    };
  };

  return (
    <Dialog
      open={openPsyPactModal}
      onClose={() => setOpenPsyPactModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}
      >
        Renew PsyPact License
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
          }}
        >
          3 Simple & Quick Steps to Update PsyPact License
        </Typography>
        {/* Adding the new instructional note with "Note:" in bold and red */}
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '14px',
            marginBottom: '10px',
          }}
        >
          <span style={{ fontWeight: 'bold', color: 'red' }}>Note:</span> If you
          haven't been able to renew your license yet but are in the process,
          please click the 'Cancel' button below and choose a renewal option
          that best suits your situation.
        </Typography>
        <Typography style={getPsyPactStepStyle(1, currentPsyPactStep)}>
          Step 1 - Enter your updated license expiration date
        </Typography>
        <div style={getPsyPactContentStyle(1, currentPsyPactStep)}>
          <TextField
            fullWidth
            margin="dense"
            label="Expiration Date"
            type="date"
            value={memberExpiryDate}
            onChange={(e) => setMemberExpiryDate(e.target.value)} // Just update the state here
            onBlur={() => {
              // Move to the next step only when the user exits the field
              if (memberExpiryDate.length === 10) {
                setChangesMadeForEvents({
                  ...changesMadeForEvents,
                  PsyPact: true,
                });
                setCurrentPsyPactStep(2);
              }
            }}
            onKeyDown={(e) => {
              // Move to the next step when the user presses 'Enter'
              if (e.key === 'Enter' && memberExpiryDate.length === 10) {
                e.preventDefault(); // Prevent form submission if within a form
                setChangesMadeForEvents({
                  ...changesMadeForEvents,
                  PsyPact: true,
                });
                setCurrentPsyPactStep(2);
              }
            }}
            componentsProps={{
              label: {
                shrink: true,
              },
            }}
            error={expirationPsyPactDateEmpty}
            helperText={
              expirationPsyPactDateEmpty ? 'Expiration date is required' : ''
            }
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: expirationPsyPactDateEmpty ? 'red' : 'blue',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: expirationPsyPactDateEmpty ? 'red' : 'blue',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'blue',
                },
              },
            }}
          />
        </div>
        <Typography style={getPsyPactStepStyle(2, currentPsyPactStep)}>
          Step 2 - Verify the updated date you just entered matches the
          licensing website
        </Typography>

        <div style={getPsyPactContentStyle(2, currentPsyPactStep)}>
          <Typography>
            Click{' '}
            <a
              href="https://www.verifypsypact.org/PsypactDirectory"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'darkblue', textDecoration: 'underline' }}
              onClick={() => {
                setLinkPsyPactLicClicked(true);
                setCurrentPsyPactStep(3); // Move to step 3 when link is clicked
              }}
            >
              HERE
            </a>{' '}
            to open the licensing page in another tab so that you can verify the
            updated expiration date and once completed come back here.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography style={getPsyPactStepStyle(3, currentPsyPactStep)}>
          Step 3 - Click the "Update" button below
        </Typography>
        <div style={getPsyPactContentStyle(3, currentPsyPactStep)}>
          <Typography style={{ marginLeft: '16px', marginBottom: '20px' }}>
            By Clicking "Update" below, I attest that the link above has my
            updated PsyPact license information.
          </Typography>
        </div>
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}> */}
        <div className={styles.modal2buttonContainer}>
          <Button
            onClick={() => setOpenPsyPactModal(false)}
            className={styles.cancel2button}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOnSubmit}
            className={styles.submit2button}
            disabled={!memberExpiryDate || !linkPsyPactLicClicked}
          >
            Update
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PsyPactDialog);
