import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { memo, useRef } from 'react';
import React from 'react';

const ReasonDialog = ({
  reasonDialogOpen,
  setReasonDialogOpen,
  supervisorReasons,
  areaOfChange,
  setSupervisorReasons,
  setPreviousValue,
  setAreaOfChange,
  setCurrentValue,
  previousValue,
  currentValue,
  isSelectedUserSupervisee,
}) => {
  const inputRef = useRef(); // This Ref has been created for performance and to not allowing re-rendering

  return (
    <Dialog
      open={reasonDialogOpen}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        setReasonDialogOpen(false);
      }}
    >
      <DialogTitle>Change Reason</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide a reason for this change.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Change Reason"
          type="text"
          fullWidth
          ref={inputRef}
          required
          onChange={(event) => (inputRef.current.text = event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            // Here, you would handle the submission of the reason.
            // This could involve setting state, calling an API, etc.
            if (
              inputRef?.current?.text &&
              inputRef?.current?.text?.length > 0
            ) {
              setReasonDialogOpen(false);
              // The below state is for setting the area and the reason for setting it
              const updatedSupervisorReasons = supervisorReasons.map(
                (reason) => {
                  if (reason.area === areaOfChange) {
                    // If an object with the same area exists, update the reason
                    return { ...reason, reason: inputRef.current.text };
                  }
                  return reason;
                }
              );

              // If the area doesn't exist, add a new object
              if (
                !updatedSupervisorReasons.some(
                  (reason) => reason.area === areaOfChange
                )
              ) {
                updatedSupervisorReasons.push({
                  area: areaOfChange,
                  reason: inputRef.current.text,
                  previousValue: previousValue,
                  currentValue: currentValue,
                  date: new Date().toISOString(),
                  isSupervisee: isSelectedUserSupervisee, // This flag checks if supervisee is making the change
                });
              }

              setSupervisorReasons(updatedSupervisorReasons);

              setAreaOfChange(''); // setting the area of change back
              setPreviousValue();
              setCurrentValue();
            }
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ReasonDialog);
