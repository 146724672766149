import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getMalpracticeInsuranceDetails = (providerId) => {
  return axios.get(`${API_URL}provider/${providerId}/malpractice-insurance`, {
    headers: authHeader(),
  });
};

const getMarylandLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getMarylandLicenseExpiration/${providerId}`, {
    headers: authHeader(), // This function should return the necessary authorization headers, e.g., with a JWT token
  });
};

const getOutofStateLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getOutofStateLicenseExpiration/${providerId}`, {
    headers: authHeader(),
  });
};

const getPsyPactLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getPsyPactLicenseExpiration/${providerId}`, {
    headers: authHeader(),
  });
};

const updateCredentialingTaskCreatedForLiability = (body) => {
  return axios.post(
    `${API_URL}providers/updateCredentialingTaskCreatedForLiability`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const getCurrentLiabilityInsuranceExpiration = (providerId) => {
  return axios.get(
    `${API_URL}getCurrentLiabilityInsuranceExpiration/${providerId}`,
    {
      headers: authHeader(),
    }
  );
};

const getLastRosterSubmission = (providerId) => {
  return axios.get(`${API_URL}getLastRosterSubmission/${providerId}`, {
    headers: authHeader(),
  });
};

const updateLastRosterSubmission = (id, body) =>
  axios.post(`${API_URL}updateLastRosterSubmission/${id}`, body, {
    headers: authHeader(),
  });

const updateLastAttestation = (id) =>
  axios.post(
    `${API_URL}updateLastAttestation/${id}`,
    {},
    { headers: authHeader() }
  );

const cancelSurvey = (providerAvailabilityId) => {
  return axios.post(
    API_URL + 'cancelSurvey',
    { providerAvailabilityId },
    { headers: authHeader() }
  );
};

const verifyUserActivity = (body) => {
  return axios.post(`${API_URL}users/getUserActivityStatus`, body, {
    headers: authHeader(),
  });
};

// eslint-disable-next-line
export default {
  getMalpracticeInsuranceDetails,
  getMarylandLicenseExpiration,
  getOutofStateLicenseExpiration,
  getPsyPactLicenseExpiration,
  updateCredentialingTaskCreatedForLiability,
  getCurrentLiabilityInsuranceExpiration,
  getLastRosterSubmission,
  updateLastRosterSubmission,
  updateLastAttestation,
  cancelSurvey,
  verifyUserActivity,
};
