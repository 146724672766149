import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveFormData } from '../../actions/scheduling';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  useTheme,
  Modal,
  Box,
  Grid2,
  Tooltip,
  IconButton,
} from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import styles from '../../styles/SchedulingStep03C.module.css';

// Style for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SchedulingStep03C = (props) => {
  const state = useSelector((state) => state.scheduling);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [formState, setFormState] = useState({
    developmentalCondition: state.developmentalCondition || '',
    behaviorIssues: {
      tantrums: false,
      aggression: false,
      disruptiveBehaviors: false,
      toiletTrainingIssues: false,
      mildSelfInjury: false,
      elopement: false,
      sleepIssues: false,
      refusalToFollowInstructions: false,
      none: false,
    },
    exclusions: {
      complexMedicalConditions: false,
      significantSensoryImpairments: false,
      schoolIssuesFocus: false,
      depressionOrAnxietyFocus: false,
      none: false,
    },
  });

  // Define a function to check if any checkbox is selected within a group
  const isAnyBehaviorIssueSelected = () =>
    Object.values(formState.behaviorIssues).some((value) => value);

  // Check if a developmental condition is selected
  const isDevelopmentalConditionSelected =
    formState.developmentalCondition !== '';

  // Enable the checkboxes for exclusions only if the other questions are answered
  const canSelectExclusions =
    isAnyBehaviorIssueSelected() && isDevelopmentalConditionSelected;

  const handleCheckboxChange = (group, name) => (event) => {
    // Ensure "None of the above" is exclusive
    if (name === 'none') {
      const resetState = Object.keys(formState[group]).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      setFormState((prevState) => ({
        ...prevState,
        [group]: { ...resetState, none: event.target.checked },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [group]: {
          ...prevState[group],
          [name]: event.target.checked,
          none: false,
        },
      }));
    }

    // Check for 'Complex Medical Conditions' and show popup
    if (name === 'complexMedicalConditions' && event.target.checked) {
      setShowPopup(true); // This will trigger the popup to show
    }

    // The below is the condition for Redux Scheduling
    if (group === 'behaviorIssues') {
      if (name === 'none' && event.target.checked) {
        // The below if condition checks if behaviorIssues object has already any value
        if (
          typeof state.behaviorIssues !== 'undefined' &&
          state.behaviorIssues !== null
        ) {
          // If "None" is checked, set all other checkboxes to false
          const resetBehaviorIssues = Object.keys(state.behaviorIssues).reduce(
            (acc, key) => {
              acc[key] = false;
              return acc;
            },
            {}
          );
          state.behaviorIssues = {
            ...resetBehaviorIssues,
            none: true, // "None of the Above" should remain selected
          };
        } else {
          // The below means that behaviorIssues none has been selected firsttime
          state.behaviorIssues = {
            none: true, // "None of the Above" should remain selected
          };
        }
      } else {
        // If any other checkbox is checked, just update its value
        state.behaviorIssues = {
          ...state.behaviorIssues,
          [name]: event.target.checked,
          none: false,
        };
      }
    } else {
      if (name === 'none' && event.target.checked) {
        // The below if condition checks if exclusions object has already any value
        if (
          typeof state.exclusions !== 'undefined' &&
          state.exclusions !== null
        ) {
          // If "None" is checked, set all other checkboxes to false
          const resetExclusions = Object.keys(state.exclusions).reduce(
            (acc, key) => {
              acc[key] = false;
              return acc;
            },
            {}
          );
          state.exclusions = {
            ...resetExclusions,
            none: true, // "None of the Above" should remain selected
          };
        } else {
          // The below means that exclusions none has been selected firsttime
          state.exclusions = {
            none: true, // "None of the Above" should remain selected
          };
        }
      } else {
        // If any other checkbox is checked, just update its value
        state.exclusions = {
          ...state.exclusions,
          [name]: event.target.checked,
          none: false,
        };
      }
    }
    dispatch(saveFormData(state));
    props.setMcLeanConditions((mcLeanCondition) => !mcLeanCondition);
  };

  const handleRadioChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      developmentalCondition: event.target.value,
    }));
    state.developmentalCondition = event.target.value;
    dispatch(saveFormData(state));
    props.setMcLeanConditions((mcLeanCondition) => !mcLeanCondition);
  };

  return (
    <React.Fragment>
      <Modal
        open={showPopup}
        onClose={() => setShowPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Important Notification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have selected an option that Dr. McLean would like to have a
            15-minute phone consult with this client. Please{' '}
            <a
              href="https://form.jotform.com/240985954520161"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>{' '}
            to complete the 15-minute phone consultation request.
          </Typography>
          <Button
            onClick={() => setShowPopup(false)}
            style={{ marginTop: '10px' }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      <div className={styles.mainContainer}>
        <Grid2 container spacing={3} style={{ marginTop: '-115px' }}>
          <Grid2 size={{ xs: 12 }}>
            <Typography
              variant="h6"
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(2), // Add spacing below the title
              }}
            ></Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4 }}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                <React.Fragment>
                  Confirmed or Suspected Developmental Condition
                  <Tooltip
                    title="This can include Autism, Intellectual Disability, developmentally delayed, Down Syndrome."
                    sx={{
                      fontSize: '1.25rem', // Adjust the font size as needed
                    }}
                  >
                    <IconButton
                      aria-label="help"
                      style={{
                        marginLeft: '5px',
                        padding: '0px',
                        verticalAlign: 'middle',
                      }}
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              </FormLabel>
              <RadioGroup
                row
                aria-label="developmentalCondition"
                name="developmentalCondition"
                value={formState.developmentalCondition}
                onChange={handleRadioChange} // Make sure to define this function
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      style={{
                        color: state.developmentalCondition ? 'black' : 'red',
                      }}
                    />
                  }
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      style={{
                        color: state.developmentalCondition ? 'black' : 'red',
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4 }}>
            <Typography variant="subtitle1">
              Behavior Issues within the Home
            </Typography>
            <FormGroup>
              {Object.keys(formState.behaviorIssues).map((issue) => (
                <FormControlLabel
                  key={issue}
                  control={
                    <Checkbox
                      checked={
                        typeof state.behaviorIssues !== 'undefined' &&
                        state.behaviorIssues !== null
                          ? state.behaviorIssues[issue]
                            ? true
                            : false
                          : formState.behaviorIssues[issue]
                          ? true
                          : false
                      }
                      onChange={handleCheckboxChange('behaviorIssues', issue)} // Make sure to define this function
                      style={{
                        color:
                          typeof state.behaviorIssues !== 'undefined' &&
                          state.behaviorIssues !== null &&
                          Object.values(state.behaviorIssues).some(
                            (value) => value === true
                          )
                            ? 'black'
                            : 'red',
                      }}
                      name={issue}
                    />
                  }
                  label={
                    issue === 'elopement' ? (
                      <React.Fragment>
                        Elopement
                        <Tooltip
                          title="Behavior where the individual leaves or runs away from a safe environment without permission or awareness from caregivers or supervisors. This can include leaving a classroom, home, or any designated safe area without notifying anyone. Elopement is a concern because it poses significant safety risks, as the individual may not be aware of dangers or how to safely navigate unfamiliar environments."
                          sx={{
                            fontSize: '1.25rem', // Adjust the font size as needed
                          }}
                        >
                          <IconButton
                            aria-label="help"
                            style={{
                              marginLeft: '5px',
                              padding: '0px',
                              verticalAlign: 'bottom',
                            }}
                          >
                            <HelpOutlineIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : issue === 'none' ? (
                      'None of the Above'
                    ) : (
                      issue.replace(/([A-Z])/g, ' $1').trim()
                    )
                  }
                />
              ))}
            </FormGroup>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 4 }}>
            <Typography variant="subtitle1">
              Does the client have any of the following:
            </Typography>

            <FormGroup>
              {Object.keys(formState.exclusions).map((exclusion) => (
                <FormControlLabel
                  key={exclusion}
                  //  onClick={() => alert('Button was clicked!')}

                  onClick={() => {
                    // Function to check if all behaviorIssues values are false
                    const areAllBehaviorIssuesFalse = (issues) => {
                      return Object.values(issues).every(
                        (value) => value === false
                      );
                    };

                    // Check if behaviorIssues are all false and developmentalCondition is not set
                    if (
                      areAllBehaviorIssuesFalse(formState.behaviorIssues) ||
                      formState.developmentalCondition === ''
                    ) {
                      alert(
                        'These buttons have been disabled until both questions have been answered.'
                      );
                    }
                  }}
                  control={
                    <Checkbox
                      checked={
                        state.exclusions
                          ? state.exclusions[exclusion]
                          : formState.exclusions[exclusion]
                      }
                      onChange={handleCheckboxChange('exclusions', exclusion)}
                      disabled={!canSelectExclusions} // Disabling/enabling based on other questions being answered
                      style={{
                        color: Object.values(
                          state.exclusions || formState.exclusions
                        ).some(Boolean)
                          ? 'black'
                          : 'red',
                      }}
                      name={exclusion}
                    />
                  }
                  label={
                    exclusion === 'complexMedicalConditions' ? (
                      <React.Fragment>
                        Complex Medical Conditions
                        <Tooltip
                          title="(e.g., requires breathing assistance, cerebral palsy)."
                          sx={{
                            fontSize: '1.25rem', // Adjust the font size as needed
                          }}
                        >
                          <IconButton
                            aria-label="help"
                            style={{
                              marginLeft: '5px',
                              padding: '0px',
                              verticalAlign: 'bottom',
                            }}
                          >
                            <HelpOutlineIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : exclusion === 'significantSensoryImpairments' ? (
                      <React.Fragment>
                        Significant Sensory Impairments
                        <Tooltip
                          title="(e.g., Blindness, Deafness)."
                          sx={{
                            fontSize: '1.25rem', // Adjust the font size as needed
                          }}
                        >
                          <IconButton
                            aria-label="help"
                            style={{
                              marginLeft: '5px',
                              padding: '0px',
                              verticalAlign: 'bottom',
                            }}
                          >
                            <HelpOutlineIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : exclusion === 'none' ? (
                      'None of the Above'
                    ) : (
                      exclusion.replace(/([A-Z])/g, ' $1').trim()
                    )
                  }
                />
              ))}
            </FormGroup>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default SchedulingStep03C;
